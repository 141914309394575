import { useState } from 'react'
import { Paper, Typography } from '@mui/material'
import { Plugin } from '@devexpress/dx-react-core'
import {
    SearchState,
    IntegratedFiltering,
    RowDetailState,
    SortingState,
    IntegratedPaging,
    PagingState,
    EditingState,
    IntegratedSorting,
} from '@devexpress/dx-react-grid'
import {
    Grid,
    VirtualTable,
    Toolbar,
    SearchPanel,
    TableHeaderRow,
    TableRowDetail,
    PagingPanel,
    TableEditColumn,
    TableEditRow,
} from '@devexpress/dx-react-grid-material-ui'
import { Command, EditingPopup } from './tableEditPopupPlugin'

const Cell = (props) => {
    // TODO: find a more permanent/elegant fix
    let val = typeof props.value == 'boolean' ? String(props.value) : props.value
    val = val ?? ''
    return <VirtualTable.Cell {...props} value={val} />
}

const EditCell = (props) => {
    return <TableEditRow.Cell {...props} />
}

// TODO: make table configurable for filtering, editing, etc
export const GenericTable = (props) => {
    const [rows, setRows] = useState([])
    const [addedRows, setAddedRows] = useState([])
    const [searchValue, setSearchState] = useState('')
    const [, setSorting] = useState([{ columnName: 'customerID', direction: 'desc' }])
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(0)
    const [pageSizes] = useState([5, 10, 0])
    const [sorting, getSorting] = useState([])
    const [rowChanges, setRowChanges] = useState({})
    // TODO: figure out if we need getSorting
    const [editingRowIds, getEditingRowIds] = useState([])

    // TODO: find a better pattern for setting default rows if possible (investigate)
    const changeAddedRows = (value) =>
        setAddedRows(value.map((row) => (Object.keys(row).length ? row : props.defaultRow)))

    const commitChanges = ({ added, changed, deleted }) => {
        let changedRows
        if (added) {
            const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0
            changedRows = [
                ...rows,
                ...added.map((row, index) => ({
                    id: startingAddedId + index,
                    ...row,
                })),
            ]
        }
        if (changed) {
            changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row))
        }
        if (deleted) {
            const deletedSet = new Set(deleted)
            changedRows = rows.filter((row) => !deletedSet.has(row.id))
        }
        setRows(changedRows)
    }

    const addGridStatePlugins = () => {
        // NB: editing state needs onAddedRowsChange AND addedRows, etc to work properly
        // This is some mixed control that's happening here, most likely, since specifying
        // a custom onAddedRowsChange signals that we are controlling addedRows ourselves
        // so we need to tell the grid which addedRows to use
        return (
            <Plugin>
                <SortingState sorting={sorting} onSortingChange={setSorting} />
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <EditingState
                    addedRows={addedRows}
                    onCommitChanges={commitChanges}
                    onAddedRowsChange={changeAddedRows}
                />
                {props.toggleRow ? <RowDetailState /> : null}
                <SearchState value={searchValue} onValueChange={setSearchState} />
            </Plugin>
        )
    }

    const addGridFunctionalityPlugins = () => {
        return (
            <Plugin>
                <IntegratedSorting />
                <IntegratedPaging />
                <IntegratedFiltering />
            </Plugin>
        )
    }

    const addGridVisualizationPlugins = () => {
        return (
            <Plugin>
                <VirtualTable cellComponent={Cell} columnExtensions={props.columnExtensions} />
                <TableHeaderRow showSortingControls />
                <TableEditRow cellComponent={EditCell} />
                <TableEditColumn
                    width={170}
                    showAddCommand={!addedRows.length}
                    showEditCommand
                    showDeleteCommand
                    commandComponent={Command}
                />
                <PagingPanel pageSizes={pageSizes} />
                {props.toggleRow ? (
                    <TableRowDetail
                        contentComponent={props.toggleRow}
                        toggleCellComponent={props.toggleCell}
                    />
                ) : null}
                <Toolbar />
                <SearchPanel />
                <EditingPopup popup={props.popup} {...props.popupProps} />
            </Plugin>
        )
    }

    // NB: DevExtreme React Grid organizes its plugins in 3 catgeories:
    //     1. Grid state plugins (sorting state, paging state, editing state, etc)
    //     2. Grid local functionality plugins (logic of sorting, paging, etc)
    //     3. Grid visualization plugins (table, table headers, paging panel, etc)
    // The order of these plugins matters, and state plugins are needed before visualization or
    // functionality plugins requiring that "state".
    // Read for more details:
    // https://community.devexpress.com/blogs/oliver/archive/2017/07/04/devextreme-react-grid-standard-plugins.aspx
    // API reference (to see dependencies of different plugins):
    // https://devexpress.github.io/devextreme-reactive/docs/
    return (
        <Paper sx={{ bgcolor: 'aliceblue', mt: '2%', width: '80vw' }}>
            <Typography sx={{ fontSize: '4vh', color: 'navy', textAlign: 'center', mt: '1.5%' }}>
                {props.title}
            </Typography>
            <Grid rows={props.rows} columns={props.columns} getRowId={props.getRowId}>
                {addGridStatePlugins()}
                {addGridFunctionalityPlugins()}
                {addGridVisualizationPlugins()}
            </Grid>
        </Paper>
    )
}
