import { FormGroup, TextField } from '@mui/material'
import MuiGrid from '@mui/material/Grid'
import { STAGES } from '../../../Util/constants'
import BookingPopup from './bookingForm'
import {
    BookingDetails,
    BookingFinance,
    BookingMisc,
    BookingOverview,
    BookingStatus,
    FormGroupHeader,
    ProductDetails,
    SelectForm,
    TportDatePicker,
} from './reusableForms'

/* // Form labels and categories
    Form group 1 (Basic active shipment info): [bk_id, bl_no, customer, agent]
    Fot group 2 (cutoff info): [cutoff_date, days_to_cutoff]
    Form group  (Misc): [special instructions]
 */

export default function ActiveAttentionForm(props) {
    return (
        <MuiGrid container spacing={3}>
            <MuiGrid item xs={12}>
                <BookingOverview row={props.row} onChange={props.onChange} customers={props.customers} />
                <BookingStatus row={props.row} onChange={props.onChange} />
            </MuiGrid>

            <MuiGrid item xs={6}>
                <TportDatePicker
                    name={'cutoff_date'}
                    label={'Cutoff'}
                    value={props.row.cutoff_date}
                    onChange={props.onChange}
                />
            </MuiGrid>

            <MuiGrid item xs={6}>
                <TextField
                    fullWidth
                    name="days_to_cutoff"
                    label="DaysToCutoff"
                    value={props.row.days_to_cutoff || ''}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    name="special_instructions"
                    label="SpecialInstructions"
                    value={props.row.special_instructions || ''}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={12}>
                <BookingDetails onChange={props.onChange} row={props.row} />
                <ProductDetails onChange={props.onChange} row={props.row} />
                <BookingFinance onChange={props.onChange} row={props.row} />
                <BookingMisc onChange={props.onChange} row={props.row} />
            </MuiGrid>
        </MuiGrid>
    )
}
