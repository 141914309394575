import { FormGroup, Button } from '@mui/material'
import MuiGrid from '@mui/material/Grid'
import { TportDatePicker } from './reusableForms'

export default function RateSheetForm(row, onChange, onSelect) {
    return (
        <MuiGrid container spacing={3}>
            <MuiGrid item xs={12}>
                <FormGroup>
                    <Button variant="contained" component="label">
                        Upload File
                        <input
                            type="file"
                            name="rate_sheet"
                            value={row.rate_sheet}
                            onChange={onSelect}
                            hidden
                        />
                    </Button>
                    <TportDatePicker
                        name={'upload_date'}
                        label={'Upload Date'}
                        value={row.upload_date}
                        onChange={onChange}
                    />
                </FormGroup>
            </MuiGrid>
        </MuiGrid>
    )
}
