import { RATE_SHEET_TABLE_COLUMNS } from '../../adminComponents/adminConstants'
import { RateSheetPopup } from '../../adminComponents/adminPopups/rateSheetPopup'
import { GenericTable } from './genericTable'


export default function RateSheetTable(props) {
    const getRowId = (row) => row.rate_sheet

    return (
        <GenericTable
            columns={RATE_SHEET_TABLE_COLUMNS}
            getRowId={getRowId}
            title={'RATE SHEETS'}
            popup={RateSheetPopup}
            rows={props.rows ?? []}
        />
    )
}
