import { useState, useEffect } from 'react'
import { CircularProgress, Box, Paper, Link, Typography, IconButton, styled } from '@mui/material'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import {
    RowDetailState,
    SearchState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
} from '@devexpress/dx-react-grid'
import {
    Grid,
    PagingPanel,
    Table,
    TableHeaderRow,
    TableRowDetail,
    Toolbar,
    SearchPanel,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import {
    loadBookings,
    getMetadataForDocs,
    listAllDocsUserBookings,
    getDocDownloadURL,
} from '../Util/adminFirebase'
import { useAppContext } from '../Components/store'

interface docDetails {
    docType: string
    uploadDate: string
    size: string
    downloadLink: string
}

interface basicBookingInfo {
    bookNo: string
    origin: string
    dest: string
    start: string
    end: string
    shipmentType: string
}

interface bookingsTableProps {
    bookings: string[]
}

export default function BookingsTable(props: bookingsTableProps) {
    const [loading, setLoading] = useState<boolean>(true)
    const [pageSizes] = useState([5, 10, 15, 0])
    const [searchValue, setSearchState] = useState('')
    const [rows, setRows] = useState<basicBookingInfo[]>([])
    const [activeShipmentsDocsMetaData, setActiveShipmentsDocsMetaData] = useState<Map>()
    const [columns] = useState([
        { name: 'bookNo', title: 'Booking #' },
        { name: 'shipmentType', title: 'Shipment Type' },
        { name: 'origin', title: 'Origin' },
        { name: 'dest', title: 'Destination' },
        { name: 'start', title: 'Depart Date' },
        { name: 'end', title: 'Arrival Date' },
    ])
    const TableHeaderComponent = (props) => (
        <VirtualTable.TableHead {...props} style={{ backgroundColor: 'aliceblue' }} />
    )
    const ContainerComponent = (props) => <VirtualTable.Table {...props} style={{ height: 'min-content' }} />
    const HeaderContent = (props) => (
        <TableHeaderRow.Row {...props} style={{ fontWeight: 'bold', fontSize: '2vh' }} />
    )

    const appState = useAppContext()
    // Equivalent to component did mount
    useEffect(() => {
        const filterBook = async (bookings: string[]) => {
            setLoading(true)
            if (bookings) {
                const newRows = []
                const docSnaps = await loadBookings(bookings)
                docSnaps.forEach((item, idx) => {
                    const itemData = item.data()
                    newRows.push({
                        bookNo: bookings[idx],
                        origin: itemData.origin,
                        dest: itemData.dest,
                        start: itemData.depart_date,
                        end: itemData.arrival_date,
                        shipmentType: itemData.booking_type,
                    })
                })
                const uuid = appState[0].uuid
                const docsListAll = await listAllDocsUserBookings(uuid, bookings)
                const bookingsDocsDict = new Map()
                const promises = []
                docsListAll.forEach((booking) => {
                    let bookingNum = ''
                    let uuid = ''
                    if (!booking.items.length) {
                        return
                    }
                    booking.items.forEach((docRef) => {
                        bookingNum = bookingNum ? bookingNum : docRef.parent.name
                        uuid = uuid ? uuid : docRef.parent.parent.name
                        if (!bookingsDocsDict[bookingNum]) {
                            bookingsDocsDict[bookingNum] = []
                        }
                        const docPath = `${uuid}/${bookingNum}/${docRef.name}`
                        bookingsDocsDict[bookingNum].push(docPath)
                    })
                    promises.push(getMetadataForDocs(bookingsDocsDict[bookingNum]))
                })
                const allDocsMetaData = await Promise.all(promises)
                // TODO: robustify
                setActiveShipmentsDocsMetaData(
                    new Map(bookings.map((bookingID, idx) => [bookingID, allDocsMetaData[idx]])),
                )
                setRows(newRows)
            }
            setLoading(false)
        }
        filterBook(props.bookings)
    }, [props])

    const downloadFile = (docPath: string) => {
        getDocDownloadURL(docPath)
        // `url` is the download URL for 'images/stars.jpg'
        // // This can be downloaded directly:
        // const xhr = new XMLHttpRequest();
        // xhr.responseType = 'blob';
        // xhr.onload = (event) => {
        //   const blob = xhr.response;
        // };
        // xhr.open('GET', url);
        // xhr.send();

        // // Or inserted into an <img> element
        // const img = document.getElementById('myimg');
        // img.setAttribute('src', url);
    }

    const expandRow = (rowData) => {
        const DOCNAME = 'docType'
        const UPLOAD_DATE = 'uploadDate'
        const DOWNLOAD_LINK = 'downloadLink'
        const FILE_SIZE = 'size'
        const bookingNo = rowData.bookNo
        const docGridHeaders = [
            { name: DOCNAME, title: 'Name' },
            { name: UPLOAD_DATE, title: 'Date uploaded' },
            { name: FILE_SIZE, title: 'File size' },
            { name: DOWNLOAD_LINK, title: 'Download Link' },
        ]
        const genDocRows = (booking: string) => {
            const docRows: docDetails[] = []
            if (activeShipmentsDocsMetaData.get(booking)) {
                activeShipmentsDocsMetaData.get(booking).forEach((doc) => {
                    docRows.push({
                        [DOCNAME]: doc.name.replaceAll('_', ' '),
                        [UPLOAD_DATE]: doc.updated.split('T')[0],
                        [FILE_SIZE]: `${(doc.size / 1024 ** 2).toFixed(3)} MB`,
                        [DOWNLOAD_LINK]: doc.fullPath,
                    })
                })
            }
            return docRows
        }

        const getLink = (row, column) => {
            if (column == DOWNLOAD_LINK) {
                return (
                    <IconButton
                        color="primary"
                        component={Link}
                        onClick={() => {
                            console.info('I am clicked')
                        }}
                    >
                        <FileDownloadOutlinedIcon />
                    </IconButton>
                )
            } else {
                return row[column]
            }
        }
        return (
            <Grid
                rows={genDocRows(bookingNo)}
                columns={docGridHeaders}
                getCellValue={(row, column) => getLink(row, column)}
            >
                <Table />
                <TableHeaderRow />
            </Grid>
        )
    }

    const formatBookingText = (row, column) => {
        if (column == 'bookNo' && row.bookNo == 'bk_no_19') {
            return (
                <Box sx={{ display: 'inline' }}>
                    <Typography sx={{ fontSize: '2vh' }}>{row.bookNo} </Typography>
                    <Typography sx={{ fontSize: '2vh', color: 'red', fontWeight: 'bold' }}>
                        {'(pending)'}
                    </Typography>
                </Box>
            )
        } else {
            return row[column]
        }
    }

    const renderTable = () => {
        if (loading) {
            return (
                <Box
                    sx={{
                        width: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        ml: '30vw',
                        mt: '10%',
                    }}
                >
                    <CircularProgress />
                    <Typography sx={{ ml: '-0.6vw', mt: '1%' }}>Loading</Typography>
                </Box>
            )
        } else {
            return (
                <Paper sx={{ bgcolor: '#F5F5F5', mr: '5vw', color: 'aliceblue' }}>
                    <Grid
                        getCellValue={(row, column) => formatBookingText(row, column)}
                        rows={rows}
                        columns={columns}
                    >
                        <PagingState defaultCurrentPage={0} defaultPageSize={5} />
                        <IntegratedPaging />
                        <SearchState value={searchValue} onValueChange={setSearchState} />
                        <IntegratedFiltering />
                        <RowDetailState />
                        <VirtualTable
                            headComponent={TableHeaderComponent}
                            containerComponent={ContainerComponent}
                        />
                        <TableHeaderRow contentComponent={HeaderContent} />
                        <TableRowDetail contentComponent={({ row }) => expandRow(row)} />
                        <Toolbar />
                        <SearchPanel />
                        <PagingPanel pageSizes={pageSizes} />
                    </Grid>
                </Paper>
            )
        }
    }
    return renderTable()
}
