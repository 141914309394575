import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Grid, Table } from '@devexpress/dx-react-grid-material-ui'
import MuiGrid from '@mui/material/Grid'

interface Charges {
    chargesArr: [string, number][]
    oceanCharges: [string, number][]
    destCharges: [string, number][]
    originCharges: [string, number][]
}
interface Row {
    desc?: string
    amount?: string
}

export default function PriceBreakdownAccordion(charges: Charges) {
    const columns = [
        { name: 'desc', title: 'Charge' },
        { name: 'amount', title: 'Amount' },
    ]

    const oceanRows: Row[] = []
    const originRows: Row[] = []
    const destRows: Row[] = []

    charges.oceanCharges.forEach((charge) => {
        const objInst: Row = {}
        objInst.desc = charge[0]
        objInst.amount = charge[1].toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        })
        oceanRows.push(objInst)
    })

    charges.originCharges.forEach((charge) => {
        const objInst: Row = {}
        objInst.desc = charge[0]
        objInst.amount = charge[1].toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        })
        originRows.push(objInst)
    })

    charges.destCharges.forEach((charge) => {
        const objInst: Row = {}
        objInst.desc = charge[0]
        objInst.amount = charge[1].toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        })
        destRows.push(objInst)
    })

    return (
        <Accordion
            elevation={0}
            sx={{ width: '100%', bgcolor: 'white', '&:hover': { bgcolor: 'white' } }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ pl: '80%' }}
            >
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }} noWrap>
                    Price Breakdown
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ bgcolor: 'aliceblue' }}>
                <MuiGrid container>
                    <MuiGrid item xs={2} sx={{ textAlign: 'center' }}>
                        <Typography>Ocean</Typography>
                    </MuiGrid>
                    <MuiGrid item xs={10}>
                        <Grid rows={oceanRows} columns={columns}>
                            <Table />
                        </Grid>
                    </MuiGrid>
                </MuiGrid>
                {charges.originCharges.length > 0 && (
                    <MuiGrid container>
                        <MuiGrid item xs={2} sx={{ textAlign: 'center' }}>
                            <Typography>Origin</Typography>
                        </MuiGrid>
                        <MuiGrid item xs={10}>
                            <Grid rows={originRows} columns={columns}>
                                <Table />
                            </Grid>
                        </MuiGrid>
                    </MuiGrid>
                )}
                {charges.destCharges.length > 0 && (
                    <MuiGrid container>
                        <MuiGrid item xs={2} sx={{ textAlign: 'center' }}>
                            <Typography>Destination</Typography>
                        </MuiGrid>
                        <MuiGrid item xs={10}>
                            <Grid rows={destRows} columns={columns}>
                                <Table />
                            </Grid>
                        </MuiGrid>
                    </MuiGrid>
                )}
                <Typography variant="subtitle2" sx={{ ml: '70%', mt: 5 }}>
                    * Destination fees to be paid at clearance.
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}
