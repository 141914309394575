import { useState, useEffect } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Drawer from '../Components/permanentDrawer'
import UploadDocs from '../Components/docUploadDrawer'
import { BookingsTableTabs } from '../Components/tabPanel'
import { loadUserBookingIDs, useAuth } from '../Util/adminFirebase'
import { useAppContext } from '../Components/store'
import { APP_HEADER_HEIGHT_PX, APP_SIDE_DRAWER_WIDTH_PX } from '../Util/constants'

interface uploadedDocT {
    docType: string
    uploadDate: string
    size: string
    downloadLink: string
    bookingNum: string
}

export default function AccountBookings() {
    const [loading, setLoading] = useState<boolean>(true)
    const [activeShipments, setActiveShipments] = useState<string[]>([])
    const [completedShipments, setCompletedShipments] = useState<string[]>([])
    const [delayedShipments, setDelayedShipments] = useState<string[]>([])
    const [selectedTab, setSelectedTab] = useState<number>(0)
    // TODO: HACKY, FIX IT
    const [uploadedDocInfo, setUploadedDocInfo] = useState<uploadedDocT>(null)

    const appState = useAppContext()
    useAuth(appState)

    // TODO: remove hardcoding
    const uploadBookingsData = () => {
        switch (selectedTab) {
            case 0:
                return activeShipments
            case 1:
                return completedShipments
            case 2:
                return delayedShipments
            case 3:
                return activeShipments.concat(completedShipments)
            default:
                return []
        }
    }
    useEffect(() => {
        const loadingUserBookings = async () => {
            setLoading(true)
            let uuid = ''
            if (appState) {
                uuid = appState[0].uuid
            }
            if (uuid) {
                const userBookingData = (await loadUserBookingIDs(uuid)).data()
                console.log(userBookingData)
                setActiveShipments(
                    userBookingData.active_shipments.concat(userBookingData.pending_shipments),
                )
                setCompletedShipments(userBookingData.completed_shipments)
                setDelayedShipments(userBookingData.delayed_shipments)
            }
            setLoading(false)
        }
        loadingUserBookings()
    }, [appState])

    const renderTableTab = () => {
        if (loading) {
            return (
                <Box
                    sx={{
                        width: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        ml: '30vw',
                        mt: '10%',
                    }}
                >
                    <CircularProgress />
                    <Typography sx={{ ml: '-0.6vw', mt: '1%' }}>Loading</Typography>
                </Box>
            )
        } else {
            return (
                <BookingsTableTabs
                    uploadedDoc={uploadedDocInfo}
                    activeShipments={activeShipments}
                    completedShipments={completedShipments}
                    delayedShipments={delayedShipments}
                    setTab={setSelectedTab}
                    tab={selectedTab}
                    type="booking"
                />
            )
        }
    }
    return (
        <Grid container>
            <Drawer />
            <Grid
                container
                sx={{
                    pt: `${APP_HEADER_HEIGHT_PX}px`,
                    pl: { sm: `${APP_SIDE_DRAWER_WIDTH_PX - 100}px`, xs: 10 },
                    justifyContent: 'center',
                }}
            >
                <Grid item xs={8} sm={10}>
                    <Typography
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            color: 'navy',
                            fontSize: '4vh',
                        }}
                    >
                        Bookings
                    </Typography>
                    {renderTableTab()}
                </Grid>
                <Grid item xs={4} sm={2} sx={{ mt: '25vh' }}>
                    <UploadDocs
                        setUploadedDoc={setUploadedDocInfo}
                        uuid={appState[0].uuid}
                        bookings={uploadBookingsData()}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
