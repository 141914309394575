import { useEffect, useMemo, useState } from 'react'
import { Box, Paper, Divider, Button, Typography, Stack } from '@mui/material'
import RouteTimeline from './routeTimeline'
import ShipmentDetails from './shipInfo'
import {
    ResultReturnType,
    UseResultReturnType,
    useResultContext,
    useCurrentBookingContext,
    useAppContext,
} from './store'
import LogoParser from '../Components/logoParser'
import PriceBreakdown from '../Components/priceBreakdown'

const TRANSSHIPMENT = 'Trans'
const DIRECT_SHIPMENT = 'Direct'
interface handleNextT {
    handleNext: () => void
}

export default function ResultBox(handleNext: handleNextT) {
    //export default function ResultBox(selectedIndex: selectedIndexT) {
    const [stops, setStops] = useState<string[]>([null])
    const [carrierName, setCarrierName] = useState<string>('')
    const [origin, setOrigin] = useState<string>('')
    const [dest, setDest] = useState<string>('')
    const [sailDate, setSailDate] = useState<string>('')
    const [transitTime, setTransitTime] = useState<string>('')
    const [arrivalDate, setArrivalDate] = useState<string>('')
    const [totalAmount, setTotalAmount] = useState<number>(0)
    // const expiryDate = useState<string>('') // TODO: add support for use
    const [charges, setCharges] = useState<[string, number][]>(null)
    const [oceanCharges, setOceanCharges] = useState<[string, number][]>(null)
    const [originCharges, setOriginCharges] = useState<[string, number][]>(null)
    const [destCharges, setDestCharges] = useState<[string, number][]>(null)

    const [routeType, setRouteType] = useState<string>('')

    const [appState] = useAppContext()
    // TODO: should each resBox have a ref to current context?
    const [bkContext, bkContextSet] = useCurrentBookingContext()
    const res: UseResultReturnType | null = useResultContext()
    let voyageDetails: ResultReturnType | null = null
    if (res) {
        voyageDetails = res[0]
    }

    const parseOffers = (voyageDetails: ResultReturnType) => {
        setCarrierName(voyageDetails.productOffer.carrierName)
        setOrigin(voyageDetails.productOffer.originPort)
        setDest(voyageDetails.productOffer.destinationPort)
        setSailDate(voyageDetails.productPrice.routeSchedule[0].sailingDate.split(' ')[0])

        let extraFees = []
        let basicFreight = 0
        const newOceanCharges = []
        const newOriginCharges = []
        const newDestCharges = []
        for (let j = 0; j < voyageDetails.productPrice.charges.length; j++) {
            if (voyageDetails.productPrice.charges[j].description.toLowerCase().match('basic')) {
                basicFreight = voyageDetails.productPrice.charges[j].amountUsd
                newOceanCharges.push([
                    voyageDetails.productPrice.charges[j].description,
                    voyageDetails.productPrice.charges[j].amountUsd,
                ])
            } else if (
                voyageDetails.productPrice.charges[j].description == 'Environmental Fuel Fee' ||
                voyageDetails.productPrice.charges[j].description == 'One Bunker Surcharge' ||
                voyageDetails.productPrice.charges[j].description == 'Bunker Recovery Adjustment Fac' ||
                voyageDetails.productPrice.charges[j].description == 'Emergency Risk Surcharge' ||
                voyageDetails.productPrice.charges[j].description == 'Low Sulphur Adjustment' ||
                voyageDetails.productPrice.charges[j].description == 'Peak Season Surcharge' ||
                voyageDetails.productPrice.charges[j].description == 'Security Surcharge' ||
                voyageDetails.productPrice.charges[j].description == 'Currency Adjustment Factor' ||
                voyageDetails.productPrice.charges[j].description ==
                    'Advanced Manifest Security Charge' ||
                voyageDetails.productPrice.charges[j].description == 'Low Sulphur Surcharge'
            ) {
                extraFees.push(voyageDetails.productPrice.charges[j].amountUsd)
                newOceanCharges.push([
                    voyageDetails.productPrice.charges[j].description,
                    voyageDetails.productPrice.charges[j].amountUsd,
                ])
            } else if (
                voyageDetails.productPrice.charges[j].description.toLowerCase().match('destination') ||
                voyageDetails.productPrice.charges[j].description.toLowerCase().match('import') ||
                voyageDetails.productPrice.charges[j].description.toLowerCase().match('discharging')
            ) {
                newDestCharges.push([
                    voyageDetails.productPrice.charges[j].description,
                    voyageDetails.productPrice.charges[j].amountUsd,
                ])
            } else
                newOriginCharges.push([
                    voyageDetails.productPrice.charges[j].description,
                    voyageDetails.productPrice.charges[j].amountUsd,
                ])
        }
        setOriginCharges(newOriginCharges)
        setDestCharges(newDestCharges)
        setOceanCharges(newOceanCharges)
        const lastLegIndex = voyageDetails.productPrice.routeSchedule[0].scheduleDetails.length - 1
        setArrivalDate(
            voyageDetails.productPrice.routeSchedule[0].scheduleDetails[
                lastLegIndex
            ].toLocation.arrival.split(' ')[0],
        )
        setTransitTime(voyageDetails.productPrice.routeSchedule[0].transitTime)

        const newStops = []
        const shipmentLegs = voyageDetails.productPrice.routeSchedule[0].scheduleDetails.length
        if (shipmentLegs) {
            setRouteType(TRANSSHIPMENT)
            for (let j = 0; j <= shipmentLegs - 1; j++) {
                newStops.push(
                    voyageDetails.productPrice.routeSchedule[0].scheduleDetails[j].fromLocation.unLocCode,
                )
            }
            newStops.push(
                voyageDetails.productPrice.routeSchedule[0].scheduleDetails[shipmentLegs - 1].toLocation
                    .unLocCode,
            )
        } else {
            setRouteType(DIRECT_SHIPMENT)
            newStops.push(origin)
            newStops.push(dest)
        }
        setStops(newStops)
        const newCharges = []
        voyageDetails.productPrice.charges.forEach((charge) => {
            newCharges.push([charge.description, charge.amountUsd])
        })
        setCharges(newCharges)
        const totalExtra = extraFees.reduce((partialSum, a) => partialSum + a, 0)
        setTotalAmount(basicFreight + totalExtra)
        console.log(oceanCharges, originCharges, destCharges)
    }
    // NB: Component Did Mount
    useEffect(() => {
        if (voyageDetails) {
            parseOffers(voyageDetails)
        }
    }, [])

    const selectVoyage = () => {
        bkContextSet({
            ...bkContext,
            uuid: appState.uuid,
            invoice_num: 'random',
            origin: origin,
            dest: dest,
            depart_date: sailDate,
            arrival_date: arrivalDate,
            shipping_line: carrierName,
            is_delayed: false,
            is_transshipment: routeType == 'Trans-shipment',
            stops: [...stops],
            eta_stops: [],
            etd_stops: [],
            total_price_usd: totalAmount,
            transit_time: transitTime,
            customs_only: false,
        })
        handleNext.handleNext()
    }

    if (voyageDetails) {
        return (
            <Paper
                sx={{
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    bgcolor: '#F5F5F5',
                    borderRadius: 1,
                    color: 'navy',
                    mb: '2.5vh',
                }}
            >
                <Stack
                    sx={{
                        height: 'fit-content',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                mr: '2%',
                                ml: '2%',
                            }}
                        >
                            <LogoParser carrierName={carrierName} />
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
                            <RouteTimeline stops={stops} />
                            <Divider sx={{ width: '100%' }} flexItem />
                            <ShipmentDetails
                                transitTime={transitTime}
                                sailDate={sailDate.split('T')[0]}
                                freeDays={bkContext.free_days}
                            />
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Button
                            variant="contained"
                            onClick={selectVoyage}
                            sx={{ ml: '2.5%', mt: '5%', mb: '5%', mr: '2.5%', height: '5%' }}
                        >
                            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                                {`Book At
                                ${totalAmount.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}`}
                            </Typography>
                        </Button>
                    </Box>
                    {charges && originCharges && destCharges && oceanCharges ? (
                        <PriceBreakdown
                            chargesArr={charges}
                            originCharges={originCharges}
                            destCharges={destCharges}
                            oceanCharges={oceanCharges}
                        />
                    ) : null}
                </Stack>
            </Paper>
        )
    }
}
