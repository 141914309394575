import { Box, List, ListItemText, ListItemButton, Button, Tooltip } from '@mui/material'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import AssignmentIcon from '@mui/icons-material/Assignment'
import FactorySharpIcon from '@mui/icons-material/FactorySharp'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'
import SearchIcon from '@mui/icons-material/Search'
import Logo from '../../Assets/transiport-logo2.png'
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import Switch from '@mui/material/Switch'
import { FIREBASE_DEV, FIREBASE_PROD, toggleFirebaseConfig } from '../../Util/adminFirebase'
import { useAppContext } from '../store'
import { useState } from 'react'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
    backgroundColor: 'aliceblue',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    //backgroundColor: '#04012b',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

export default function AdminDrawer() {
    const appState = useAppContext()
    const navigate = useNavigate()
    const pathname = useLocation()
    const theme = useTheme()
    const [open, setOpen] = useState(false)

    const handleDrawerOpen = () => {
        setOpen(true)
    }
    const handleSignout = () => {
        navigate('/')
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const toggleDB = (e) => {
        const [oldAppState, setAppState] = appState
        // TODO: verify if correct for all cases
        if (e.target.checked) {
            toggleFirebaseConfig(FIREBASE_PROD)
            setAppState({ ...oldAppState, appMode: FIREBASE_PROD })
        } else {
            toggleFirebaseConfig(FIREBASE_DEV)
            setAppState({ ...oldAppState, appMode: FIREBASE_DEV })
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar sx={{ bgcolor: 'white' }}>
                    <Tooltip title="Expand Menu" placement="right">
                        <IconButton
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                                color: 'grey',
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Tooltip>
                    <Button onClick={() => navigate('/')}>
                        <Typography variant="h6" noWrap component="div">
                            <img src={Logo} alt="Logo" height={60} />
                        </Typography>
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} sx={{ bgcolor: '#04012b' }}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <Typography sx={{ paddingTop: 2 }}>Prod DB</Typography>
                        <Switch onChange={toggleDB} checked={appState[0].appMode == FIREBASE_PROD} />
                    </ListItem>
                    <ListItem
                        selected={pathname.pathname === '/search'}
                        disablePadding
                        sx={{ display: 'block' }}
                    >
                        <Tooltip title="Search" placement="right">
                            <ListItemButton
                                onClick={() => {
                                    navigate('/' + 'Search'.toLowerCase())
                                }}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <SearchIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Search'} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                    {/* <ListItem
                        selected={pathname.pathname === '/tasks'}
                        disablePadding
                        sx={{ display: 'block' }}
                    >
                        <Tooltip title="Active Shipments" placement="right">
                            <ListItemButton
                                onClick={() => {
                                    navigate('/' + 'Tasks'.toLowerCase())
                                }}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <AssignmentIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={'Active Shipments'}
                                    sx={{ opacity: open ? 1 : 0 }}
                                />
                            </ListItemButton>
                        </Tooltip>
                    </ListItem> */}
                    <ListItem
                        selected={pathname.pathname === '/customers'}
                        disablePadding
                        sx={{ display: 'block' }}
                    >
                        <Tooltip title="Customers" placement="right">
                            <ListItemButton
                                onClick={() => {
                                    navigate('/' + 'Customers'.toLowerCase())
                                }}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <FactorySharpIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Customers'} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                    <ListItem
                        selected={pathname.pathname === '/adminbookings'}
                        disablePadding
                        sx={{ display: 'block' }}
                    >
                        <Tooltip title="All Bookings" placement="right">
                            <ListItemButton
                                onClick={() => {
                                    navigate('/' + 'AdminBookings'.toLowerCase())
                                }}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <LibraryBooksOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={'All Bookings'} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                    {/* <ListItem
                        selected={pathname.pathname === '/partners'}
                        disablePadding
                        sx={{ display: 'block' }}
                    >
                        <Tooltip title="Partners" placement="right">
                            <ListItemButton
                                onClick={() => {
                                    navigate('/' + 'Partners'.toLowerCase())
                                }}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <HandshakeOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Partners'} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                </List>
                <List>
                    <ListItem
                        selected={pathname.pathname === '/truckers'}
                        disablePadding
                        sx={{ display: 'block' }}
                    >
                        <Tooltip title="Truckers" placement="right">
                            <ListItemButton
                                onClick={() => {
                                    navigate('/' + 'truckers'.toLowerCase())
                                }}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <LocalShippingIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Truckers'} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </Tooltip>
                    </ListItem> */}
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            onClick={handleSignout}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: 'navy',
                                }}
                            >
                                <LogoutOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Logout'} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </Box>
    )
}
