import { useEffect, useState } from 'react'
import { Box, Typography, Stack, Grid, Container } from '@mui/material'
import ResBox from '../Components/displayRouteOption'
import FreightifyResT, { Offer } from '../Components/resultInterface'
import { ResultProvider, useAppContext } from '../Components/store'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../Util/adminFirebase'
import DropDown from '../Components/landingComponents/dropdownMenu'

interface handleNextT {
    handleNext: () => void
}

export default function ResDisplay(handleNext: handleNextT) {
    // TODO: error checking for results and conditional rendering if no res
    const { state: passedState } = useLocation()
    //const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const navigate = useNavigate()
    const appState = useAppContext()
    useAuth(appState)

    // TODO: clean up state
    // 1: price, 2: numStops, 3: ETD, 4: transitTime, 5: ETA
    const [sortType, setSortType] = useState<number>(1)

    useEffect(() => {
        if (appState && !appState[0].loading && !appState[0].uuid) {
            navigate('/')
        }
    }, [appState, navigate])

    const sortingMenu = () => {
        return <DropDown menuOption={'Sort by'} optionClickFunc={setSortType} />
    }

    const sortRoutes = (searchRes: FreightifyResT) => {
        const sortedRoutes = searchRes['offers']
        switch (sortType) {
            case 1: // sort by price
                sortedRoutes.sort((route1, route2) => {
                    if (route1.productPrice.totalUSDAmount < route2.productPrice.totalUSDAmount) {
                        return -1
                    } else if (route1.productPrice.totalUSDAmount > route2.productPrice.totalUSDAmount) {
                        return 1
                    } else {
                        return 0
                    }
                })
                break
            case 2: // sort by num stops
                sortedRoutes.sort((route1, route2) => {
                    if (
                        route1.productPrice.routeSchedule[0].scheduleDetails.length <
                        route2.productPrice.routeSchedule[0].scheduleDetails.length
                    ) {
                        return -1
                    } else if (
                        route1.productPrice.routeSchedule[0].scheduleDetails.length >
                        route2.productPrice.routeSchedule[0].scheduleDetails.length
                    ) {
                        return 1
                    } else {
                        return 0
                    }
                })
                break
            case 3: // sort by etd
                sortedRoutes.sort((route1, route2) => {
                    if (
                        Date(route1.productPrice.routeSchedule[0].sailingDate) >
                        Date(route2.productPrice.routeSchedule[0].sailingDate)
                    ) {
                        return -1
                    } else if (
                        Date(route1.productPrice.routeSchedule[0].sailingDate) <
                        Date(route2.productPrice.routeSchedule[0].sailingDate)
                    ) {
                        return 1
                    } else {
                        return 0
                    }
                })
                break
            case 4: // sort by transit time
                sortedRoutes.sort((route1, route2) => {
                    const route1Time = parseInt(
                        route1.productPrice.routeSchedule[0].transitTime.split(' ')[0],
                    )
                    const route2Time = parseInt(
                        route2.productPrice.routeSchedule[0].transitTime.split(' ')[0],
                    )
                    if (route1Time < route2Time) {
                        return -1
                    } else if (route1Time > route2Time) {
                        return 1
                    } else {
                        return 0
                    }
                })
                break
            default:
                break
        }
        return sortedRoutes
    }

    const renderResCards = (routes: Offer[]) => {
        console.log(routes)
        return routes.map((routeData, idx) => {
            console.log(idx, sortType, routeData.productPrice.totalUSDAmount)
            const resBoxItem = <ResBox handleNext={handleNext.handleNext} />
            return ResultProvider({ children: resBoxItem }, routeData, idx)
        })
        this.forceUpdate
    }

    const renderResults = () => {
        console.log(passedState.searchRes)
        if (passedState.searchRes && passedState.searchRes.offers) {
            return (
                <Grid container maxWidth="xl" rowSpacing={5}>
                    <Grid item xs={12} sx={{ mt: '5vh' }}>
                        <Grid sx={{ justifyContent: 'flex-end', display: 'flex' }}>{sortingMenu()}</Grid>

                        {renderResCards(sortRoutes(passedState.searchRes))}
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <Grid item>
                    <Typography sx={{ fontFamily: 'raleway' }} variant="h5">
                        No voyages found for the specified details.
                    </Typography>
                </Grid>
            )
        }
    }

    return <Container maxWidth="lg">{renderResults()}</Container>
}
