import Paper from '@mui/material/Paper'
import { ArgumentAxis, ValueAxis, BarSeries, Chart, Title } from '@devexpress/dx-react-chart-material-ui'
import { ValueScale, Animation } from '@devexpress/dx-react-chart'
import { useState } from 'react'
import { allMonthInfoT, months } from '../Util/constants.d'

interface monthCarrierNumT {
    month: string
    shipments: number
}

interface numCarrierShipmentsPropsT {
    monthInfo: allMonthInfoT
}

export default function ShipmentTypePie(props: numCarrierShipmentsPropsT) {
    const [chartData] = useState<monthCarrierNumT[]>([
        {
            month: 'NOVEMBER',
            shipments: props.monthInfo[months.NOVEMBER].numShipments,
        },
        {
            month: 'DECEMBER',
            shipments: props.monthInfo[months.DECEMBER].numShipments,
        },
        {
            month: 'JANUARY',
            shipments: props.monthInfo[months.JANUARY].numShipments,
        },
        {
            month: 'FEBRUARY',
            shipments: props.monthInfo[months.FEBRUARY].numShipments,
        },
        {
            month: 'MARCH',
            shipments: props.monthInfo[months.MARCH].numShipments,
        },
    ])
    console.log(chartData)
    return (
        <Paper sx={{ bgcolor: 'aliceblue', width: '60%' }}>
            <Chart data={chartData}>
                <ValueScale name="month" />

                <ArgumentAxis />
                <ValueAxis showGrid={false} showLine={true} showTicks={true} />

                <BarSeries name="Total Shipments" valueField="shipments" argumentField="month" />
                <Title text={'Number of Shipments'} />
                <Animation />
            </Chart>
        </Paper>
    )
}
