import { FormGroup, TextField } from '@mui/material'
import MuiGrid from '@mui/material/Grid'

export const PartnersForm = ({ row, onChange }) => {
    return (
        <MuiGrid container spacing={3}>
            <MuiGrid item xs={12}>
                <FormGroup>
                    <TextField
                        margin="normal"
                        name="partner_name"
                        label="Name"
                        value={row.partner_name || ''}
                        onChange={onChange}
                    />
                    <TextField
                        margin="normal"
                        name="country"
                        label="Country"
                        value={row.country || ''}
                        onChange={onChange}
                    />
                </FormGroup>
            </MuiGrid>
        </MuiGrid>
    )
}
