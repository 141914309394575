import './styles.css'
import SearchPage from './Pages/newSearch'
import ResultPage from './Pages/bookingFlowStepper'
import ReviewBooking from './Pages/invoicingDetails'
import SubmitBooking from './Pages/reviewBooking'
import Receipt from './Pages/bookingReceipt'
import Tracking from './Pages/trackingPortal'
import Analytics from './Pages/clientAnalytics'
import MyBookings from './Pages/myBookingPage'
import Help from './Pages/helpPage'
import Home from './Pages/mobileFirstPages/mobileFirstLanding'
import Login from './Pages/loginPage'
import FinDash from './Pages/financialDashboard'
import Register from './Pages/register'
import Settings from './Pages/accountSettings'
import WaitVerification from './Pages/waitForVerification'
import ContactPage from './Pages/mobileFirstPages/mobileFirstContactUs'
import { Route, Routes } from 'react-router-dom'
import { BasicAppStateT, appContextProvider, currentBookingContextProvider } from './Components/store'
import { EMPTY_CURRENT_BOOKING } from './Util/constants'
import LandingAnalytics from './Pages/mobileFirstPages/mobileFirstLandingAnalytics'
import LandingSearch from './Pages/mobileFirstPages/mobileFirstLandingSearch'
import LandingBook from './Pages/mobileFirstPages/mobileFirstLandingBook'
import LandingOrderManagement from './Pages/mobileFirstPages/mobileFirstLandingOrderManagement'
import LandingTrucking from './Pages/mobileFirstPages/mobileFirstTrucking'
import LandingForwarding from './Pages/mobileFirstPages/mobileFirstLandingForwarding'
import LandingCustoms from './Pages/mobileFirstPages/mobileFirstCustoms'
import AdminDash from './Pages/adminPortal/adminDash'
import Customers from './Pages/adminPortal/customers'
import Carriers from './Pages/adminPortal/carriers'
import AdminBookings from './Pages/adminPortal/adminBookings'
import Truckers from './Pages/adminPortal/truckers'
import Partners from './Pages/adminPortal/partners'
import AdminTasks from './Pages/adminPortal/adminTasks'
import CustomsBooking from './Pages/customsResult'
import AdminLogin from './Pages/adminPortal/adminLogin'
//import AboutUs from './Pages/mobileFirstPages/aboutUs'
import { FIREBASE_DEV } from './Util/adminFirebase'

export const appBarWidth = 90
export const drawerWidth = 256

function App() {
    const generalState: BasicAppStateT = { uuid: '', loading: false, appMode: FIREBASE_DEV }

    // TODO: seems a bit convoluted doing it this way. See if we can simplify
    const wrapAppInContext = () => {
        const routes = (
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/Search" element={<SearchPage />} />
                <Route path="/Bookings" element={<MyBookings />} />
                <Route path="/Analytics" element={<Analytics />} />
                <Route path="/Track" element={<Tracking />} />
                <Route path="/Financials" element={<FinDash />} />
                <Route path="/register" element={<Register />} />
                <Route path="/help" element={<Help />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/verification" element={<WaitVerification />} />
                <Route path="/results" element={<ResultPage />} />
                <Route path="/reviewBooking" element={<ReviewBooking />} />
                <Route path="/submitBooking" element={<SubmitBooking />} />
                <Route path="/displayReceipt" element={<Receipt />} />
                <Route path="*" element={<Home />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/landingAnalytics" element={<LandingAnalytics />} />
                <Route path="/landingSearch" element={<LandingSearch />} />
                <Route path="/landingBook" element={<LandingBook />} />
                <Route path="/landingOrderManagement" element={<LandingOrderManagement />} />
                <Route path="/Forwarding" element={<LandingForwarding />} />
                <Route path="/Customs" element={<LandingCustoms />} />
                <Route path="/Trucking" element={<LandingTrucking />} />
                <Route path="/administration" element={<AdminBookings />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/adminbookings" element={<AdminBookings />} />
                <Route path="/truckers" element={<Truckers />} />
                <Route path="/partners" element={<Partners />} />
                <Route path="/tasks" element={<AdminTasks />} />
                <Route path="/custResults" element={<CustomsBooking />} />
                <Route path="/adminLogin" element={<AdminLogin />} />

                {/*<Route path="/aboutUs" element={<AboutUs />} />*/}
            </Routes>
        )
        return appContextProvider(
            { children: currentBookingContextProvider({ children: routes }, EMPTY_CURRENT_BOOKING) },
            generalState,
        )
    }
    return wrapAppInContext()
}

export default App
