import { Grid } from '@mui/material'
import AdminDrawer from '../../Components/adminComponents/adminDrawer'
import BookingsTable from '../../Components/adminComponents/adminTables/bookingsTable'
import { APP_HEADER_HEIGHT_PX, APP_SIDE_DRAWER_WIDTH_PX } from '../../Util/constants'
import { adminLoadBookingsFinanceData, getAllBookings, loadCustomers } from '../../Util/adminFirebase'
import { useEffect, useState } from 'react'
import { LoadingWheel } from '../../Components/loadingWheel'
import { sanitizeFlatBookingData } from '../../Util/adminUtil'

export default function AdminBookings() {
    const [loading, setLoading] = useState(false)
    const [customers, setCustomers] = useState({})
    const [allBookings, setAllBookings] = useState({})

    // load data
    // TODO: check for data in local storage, and refresh as needed
    useEffect(() => {
        const loadAllData = async () => {
            const promises = []
            setLoading(true)
            // TODO: pull less data
            promises.push(
                // load all customers
                loadCustomers().then((customersData) => {
                    const customerIDs = {}
                    customersData.forEach((doc) => {
                        // create data rows
                        const customerInfo = doc.data()
                        customerIDs[customerInfo['name']] = doc.id // uuid
                    })
                    setCustomers(customerIDs)
                    //console.log(customers)
                }),
            )
            // load all bookings
            promises.push(
                getAllBookings().then(async (bookingsInfo) => {
                    const bkIDs = []
                    const AllBksData = {}

                    // TODO: make this an adminUtil??
                    const formatBkFinData = (bkFinDataRaw) => {
                        // bk1DataRaw: [userFinDataRaw, tportFinDataRaw]
                        bkFinDataRaw.forEach((splitBkDataArr, bkIdx) => {
                            // combine data
                            const combinedData = {}
                            splitBkDataArr.forEach((splitBkData) => {
                                Object.assign(combinedData, splitBkData.data())
                            })
                            // append to existing userData
                            Object.assign(AllBksData[bkIDs.at(bkIdx)], combinedData)
                        })
                    }

                    // Step 1: for each booking prepare data and set to load finance data
                    const bkFinDataPromises = []
                    bookingsInfo.forEach((doc) => {
                        const bkData = doc.data()
                        const bkID = bkData.bk_id
                        if (bkID) {
                            AllBksData[bkID] = Object.assign({}, bkData)
                            bkIDs.push(bkID)
                        }
                        bkFinDataPromises.push(adminLoadBookingsFinanceData(bkIDs).then(formatBkFinData))
                    })

                    // Step 2: wait to load all bookings fin data, then format
                    // data format: [bk1DataRaw,...]
                    // bk1DataRaw: [userFinDataRaw, tportFinDataRaw]
                    await Promise.all(bkFinDataPromises)

                    // Step 3: Sanitize data
                    // TODO: do the below properly by setting up interfaces
                    // and specifying data for popup, table, shared
                    // booking from db: d={f1,f2,f3}
                    // new schema: dNew={...d,f4}
                    // after load d, we need to sanitize d to create dNew
                    const allBksDataArr = Object.values(AllBksData)
                    allBksDataArr.forEach((bkData) => sanitizeFlatBookingData(bkData))
                    setAllBookings(allBksDataArr)
                    setLoading(false)
                }),
            )
            await Promise.all(promises)
        }
        loadAllData()
    }, [])

    return (
        <Grid container maxWidth="xl">
            <AdminDrawer />
            <Grid
                container
                sx={{
                    mt: `${APP_HEADER_HEIGHT_PX}px`,
                    ml: { sm: `${APP_SIDE_DRAWER_WIDTH_PX - 60}px`, xs: 10 },
                    pr: { sm: '10vw' },
                    justifyContent: 'center',
                }}
            >
                {loading ? <LoadingWheel /> : <BookingsTable rows={allBookings} customers={customers} />}
            </Grid>
        </Grid>
    )
}
