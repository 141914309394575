import { MenuList, MenuItem, Typography, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function FooterMenu(props) {
    const navigate = useNavigate()
    return (
        <Box width="fit-content">
            <Typography fontWeight="bold" paddingLeft="1vw">
                {props.header}
            </Typography>
            <MenuList>
                {props.options.map((option, idx) => (
                    <MenuItem key={idx} onClick={() => navigate(`${option}`)}>
                        {option === '/landingSearch' && 'Search'}
                        {option === '/landingBook' && 'Book'}
                        {option === '/landingOrderManagement' && 'Order Management'}
                        {option === '/landingAnalytics' && 'Analytics'}
                        {props.header === 'SERVICES' && option}
                    </MenuItem>
                ))}
            </MenuList>
        </Box>
    )
}
