import { Box, Typography, Grid, CssBaseline, Button, Slide } from '@mui/material'
import BackgroundGif from '../../Assets/landingbackgrounCompressed.mp4'
import SearchVid from '../../Assets/finalSearch.mp4'
import BookingsVid from '../../Assets/finalBookings.mp4'
import ResultsVid from '../../Assets/finalResults.mp4'
import { useNavigate } from 'react-router-dom'
import FreightPic from '../../Assets/freightImage.jpg'
import TruckPic from '../../Assets/truck.jpg'
import CustomsPic from '../../Assets/customsImage.jpg'
import LandingAppBar from '../../Components/landingComponents/landingAppBar'
import { APP_HEADER_HEIGHT_PX } from '../../Util/constants'
import Footer from '../../Components/footerComponents/footer'
import { useState, useRef } from 'react'
import { useScrollToTop } from '../../Util/util'

export default function Landing() {
    useScrollToTop()
    const navigate = useNavigate()
    const [cReveal, setCReveal] = useState<boolean>(false)
    const [fReveal, setFReveal] = useState<boolean>(false)
    const [tReveal, setTReveal] = useState<boolean>(false)

    const containerRef = useRef(null)

    const handleCChange = () => {
        setCReveal((prev) => !prev)
    }
    const handleFChange = () => {
        setFReveal((prev) => !prev)
    }
    const handleTChange = () => {
        setTReveal((prev) => !prev)
    }

    return (
        <Grid container component="main" sx={{ overflowX: 'hidden' }}>
            <CssBaseline />
            <LandingAppBar />
            <Grid
                container
                sx={{
                    px: { xs: 2 },
                    height: {
                        xs: `calc(70vh - calc(${APP_HEADER_HEIGHT_PX}px)
             )`,
                        sm: '100vh',
                    },
                    justifyContent: 'center',
                }}
            >
                <video
                    autoPlay
                    loop
                    muted
                    style={{
                        position: 'absolute',
                        width: '100%',
                        left: '50%',
                        top: '50%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'bottom',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '-1',
                        filter: 'brightness(50%)',
                    }}
                >
                    <source src={BackgroundGif} type="video/mp4" />
                </video>
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'white',
                        textAlign: { xs: 'left', sm: 'center' },
                        //justifyContent: { xl: 'center' },
                        alignSelf: { xs: 'flex-end', sm: 'center' },
                    }}
                >
                    <Typography sx={{ fontSize: { xs: '4vh', sm: '8vh' } }}>
                        Global Logistics At Your Fingertips
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '2vh', sm: '4vh' } }}>
                        All your supply chain needs under one umbrella
                    </Typography>
                    <Button
                        variant="contained"
                        href="/register"
                        sx={{ my: { xs: 2 }, alignSelf: 'center' }}
                    >
                        Sign Up
                    </Button>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    bgcolor: '#04012b',
                    color: 'white',
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ px: { xs: 2, sm: '2%' }, py: { xs: 2 }, alignSelf: 'center' }}
                >
                    <Typography
                        gutterBottom
                        sx={{ fontSize: { xs: '3vh', sm: '6vh' } }}
                        component="div"
                        textAlign="justify"
                    >
                        INSTANT QUOTES
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '2vh', sm: '4vh' } }} textAlign="justify">
                        Experience bookings hassle-free! Enter your search requirements and get quotes.
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        height: { xs: 'auto', sm: '80vh' },
                        px: { xs: 2, sm: 0 },
                        py: { xs: '4%', sm: 0 },
                    }}
                >
                    <video
                        autoPlay
                        loop
                        muted
                        height={'100%'}
                        width={'100%'}
                        style={{
                            position: 'static',
                            alignContent: 'left',
                            paddingTop: '2.5%',
                            paddingBottom: '5%',
                            paddingRight: '5%',
                            objectFit: 'contain',
                            //boxShadow: '-20px 10px 15px #000640',
                        }}
                    >
                        <source src={SearchVid} type="video/mp4" />
                    </video>
                </Grid>
            </Grid>
            <Grid container sx={{ bgcolor: 'whitesmoke', color: 'black' }}>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        px: { xs: 2, sm: '2%' },
                        py: { xs: 2 },
                        order: { xs: 1, sm: 2 },
                        alignSelf: 'center',
                    }}
                >
                    <Typography
                        gutterBottom
                        variant="h4"
                        component="div"
                        textAlign="left"
                        sx={{ fontSize: { xs: '3vh', sm: '6vh' } }}
                    >
                        1 MIN BOOKINGS
                    </Typography>
                    <Typography
                        variant="h6"
                        textAlign="justify"
                        sx={{ fontSize: { xs: '2vh', sm: '4vh' } }}
                    >
                        View multiple suppliers that are ready to service your needs. Add additional
                        services like custom clearance and transportation all under single platform.
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        height: { xs: 'auto', sm: '80vh' },
                        order: { xs: 2, sm: 1 },
                        px: { xs: 2, sm: 0 },
                        py: { xs: '4%', sm: 0 },
                    }}
                >
                    <video
                        autoPlay
                        loop
                        muted
                        height={'100%'}
                        width={'100%'}
                        style={{
                            position: 'static',
                            alignContent: 'left',
                            paddingTop: '2.5%',
                            paddingBottom: '5%',
                            paddingRight: '5%',
                            objectFit: 'contain',
                            //boxShadow: '-20px 10px 15px #000640',
                        }}
                    >
                        <source src={ResultsVid} type="video/mp4" />
                    </video>
                </Grid>
            </Grid>
            <Grid container sx={{ bgcolor: '#04012b', color: 'white' }}>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ px: { xs: 2, sm: '2%' }, py: { xs: 2 }, alignSelf: 'center' }}
                >
                    <Typography
                        sx={{ fontSize: { xs: '3vh', sm: '6vh' } }}
                        gutterBottom
                        variant="h4"
                        component="div"
                        textAlign="left"
                    >
                        ORDER MANAGEMENT
                    </Typography>
                    <Typography variant="h6" textAlign="left" sx={{ fontSize: { xs: '2vh', sm: '4vh' } }}>
                        Manage your bookings all under one platform. All your records safe and secure for
                        future references.
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        height: { xs: 'auto', sm: '80vh' },
                        px: { xs: 2, sm: 0 },
                        py: { xs: '4%', sm: 0 },
                    }}
                >
                    <video
                        autoPlay
                        loop
                        muted
                        height={'100%'}
                        width={'100%'}
                        style={{
                            position: 'static',
                            alignContent: 'left',
                            paddingTop: '2.5%',
                            paddingBottom: '5%',
                            paddingRight: '5%',
                            objectFit: 'contain',
                            //boxShadow: '15px -20px 45px grey',
                        }}
                    >
                        <source src={BookingsVid} type="video/mp4" />
                    </video>
                </Grid>
            </Grid>
            <Grid container maxWidth="xl" alignContent={'space-between'}>
                <Grid
                    item
                    xs={12}
                    sx={{ display: 'grid', position: 'relative', justifyContent: 'center', py: '5vh' }}
                >
                    <Button
                        onClick={() => navigate('/forwarding')}
                        onMouseOver={handleFChange}
                        onMouseOut={handleFChange}
                        sx={{
                            display: 'grid',
                            position: 'relative',
                            backgroundImage: `url(${FreightPic})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center 65%',
                            boxShadow: 'inset 0 0 0 100vw rgba(0,0,0,0.5)',
                            color: 'white',
                            width: '95vw',
                            height: '50vh',
                            alignItems: 'end',
                            justifyContent: { xs: 'center', sm: 'right' },
                        }}
                        ref={containerRef}
                    >
                        <Typography
                            sx={{
                                display: { xs: 'flex', sm: 'none' },
                                textAlign: 'center',
                                fontSize: '2vh',
                                fontWeight: 'bold',
                                ml: '15%',
                            }}
                        >
                            FreightForwarding
                        </Typography>
                        <Slide direction="left" in={fReveal} container={containerRef.current}>
                            <Box
                                sx={{
                                    mx: { xs: '2vw' },
                                }}
                            >
                                <Typography
                                    sx={{ textAlign: 'right', fontSize: '5vh', fontWeight: 'bold' }}
                                >
                                    Freight Forwarding
                                </Typography>
                                <Box sx={{ textAlign: 'right' }}>
                                    <Typography sx={{ fontSize: { xs: '3vh' } }}>
                                        Full Freight Services
                                    </Typography>
                                    <Typography sx={{ fontSize: { xs: '3vh' } }}>FCL</Typography>
                                    <Typography sx={{ fontSize: { xs: '3vh' } }}>LCL</Typography>
                                </Box>
                            </Box>
                        </Slide>
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{ display: 'grid', position: 'relative', justifyContent: 'center', pb: '5vh' }}
                >
                    <Button
                        onClick={() => navigate('/trucking')}
                        onMouseOver={handleTChange}
                        onMouseOut={handleTChange}
                        sx={{
                            display: 'grid',
                            position: 'relative',
                            backgroundImage: `url(${TruckPic})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            boxShadow: 'inset 0 0 0 100vw rgba(0,0,0,0.5)',
                            width: '95vw',
                            height: '50vh',
                            color: 'white',
                            alignItems: 'end',
                            justifyContent: { xs: 'center', sm: 'left' },
                        }}
                        ref={containerRef}
                    >
                        <Typography
                            sx={{
                                display: { xs: 'flex', sm: 'none' },
                                textAlign: 'center',
                                alignSelf: 'center',
                                fontSize: '2vh',
                                fontWeight: 'bold',
                            }}
                        >
                            Trucking
                        </Typography>
                        <Slide direction="right" in={tReveal} container={containerRef.current}>
                            <Box
                                sx={{
                                    mx: { xs: '2vw' },
                                }}
                            >
                                <Typography
                                    sx={{ textAlign: 'left', fontSize: '5vh', fontWeight: 'bold' }}
                                >
                                    Trucking
                                </Typography>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography sx={{ fontSize: { xs: '3vh' } }}>Port-to-Door</Typography>
                                    <Typography sx={{ fontSize: { xs: '3vh' } }}>Door-to-Port</Typography>
                                    <Typography sx={{ fontSize: { xs: '3vh' } }}>Door-to-Door</Typography>
                                </Box>
                            </Box>
                        </Slide>
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{ display: 'grid', position: 'relative', justifyContent: 'center', pb: '5vh' }}
                >
                    <Button
                        onClick={() => navigate('/customs')}
                        onMouseOver={handleCChange}
                        onMouseOut={handleCChange}
                        sx={{
                            display: 'grid',
                            position: 'relative',
                            backgroundImage: `url(${CustomsPic})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center 55%',
                            boxShadow: 'inset 0 0 0 100vw rgba(0,0,0,0.5)',
                            width: '95vw',
                            height: '50vh',
                            color: 'white',

                            alignItems: 'end',
                            justifyContent: { xs: 'center', sm: 'right' },
                        }}
                        ref={containerRef}
                    >
                        <Typography
                            sx={{
                                display: { xs: 'flex', sm: 'none' },
                                textAlign: 'center',
                                alignSelf: 'center',
                                fontSize: '2vh',
                                fontWeight: 'bold',
                            }}
                        >
                            Customs
                        </Typography>
                        <Slide direction="left" in={cReveal} container={containerRef.current}>
                            <Box
                                sx={{
                                    mx: { xs: '2vw' },
                                }}
                            >
                                <Typography
                                    sx={{ textAlign: 'right', fontSize: '5vh', fontWeight: 'bold' }}
                                >
                                    Customs
                                </Typography>
                                <Box sx={{ textAlign: 'right' }}>
                                    <Typography sx={{ fontSize: { xs: '3vh' } }}>Inspection</Typography>
                                    <Typography sx={{ fontSize: { xs: '3vh' } }}>Assessment</Typography>
                                    <Typography sx={{ fontSize: { xs: '3vh' } }}>Lab</Typography>
                                </Box>
                            </Box>
                        </Slide>
                    </Button>
                </Grid>
                <Footer />
            </Grid>
        </Grid>
    )
}
