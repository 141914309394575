import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { Box, Toolbar, Typography, TextField, MenuItem, Divider } from '@mui/material'
import UploadButton from './fileUploadButton'
import { APP_HEADER_HEIGHT_PX } from '../Util/constants'
import { uploadBookingDocument } from '../Util/adminFirebase'
import * as React from 'react'
import Drawer from '@mui/material/Drawer'
import { drawerWidth } from '../App'

interface uploadedDocT {
    docType: string
    uploadDate: string
    size: string
    downloadLink: string
    bookingNum: string
}

interface UploadDocsPropsT {
    uuid: string
    bookings: string[]
    setUploadedDoc: Dispatch<SetStateAction<uploadedDocT>>
}

export default function UploadDocs(props: UploadDocsPropsT) {
    const [bookingNo, setBookingNo] = useState('')
    const [docName, setDocName] = useState('')
    const docTypes = [
        'Commercial Invoice',
        'Packing List',
        'Certification',
        'MSDS Sheets',
        'Authority Letter',
        'Other',
    ]

    const uploadDoc = async (file: File) => {
        try {
            const data = await uploadBookingDocument(
                props.uuid,
                bookingNo,
                docName.replaceAll(' ', '_'),
                file,
            )
            const today = new Date()
            const dd = String(today.getDate()).padStart(2, '0')
            const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
            const yyyy = today.getFullYear()

            props.setUploadedDoc({
                bookingNum: bookingNo,
                size: `${(file.size / 1024 ** 2).toFixed(3)} MB`,
                uploadDate: mm + '/' + dd + '/' + yyyy,
                downloadLink: 'Full Path',
                docType: docName,
            })
            console.log('Upload successfully: ', data)
        } catch (error) {
            // TODO: do better error handling and display error message
            console.log(error)
        }
    }
    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: `${drawerWidth + 10}px`,
                    mt: `${APP_HEADER_HEIGHT_PX}px`,
                    boxSizing: 'border-box',
                    bgcolor: 'aliceblue',
                },
            }}
            variant="permanent"
            anchor="right"
        >
            <Toolbar>
                <Typography variant="h6" color="navy" textAlign={'center'}>
                    Upload a Document
                </Typography>
            </Toolbar>
            <Divider />
            <Box component="form" sx={{ ml: '5%', width: '90%', height: '50%' }}>
                <TextField
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setBookingNo(event.target.value)}
                    sx={{ bgcolor: 'white', mt: '2vh' }}
                    variant="outlined"
                    select
                    value={bookingNo}
                    id="bookingNo-input"
                    label="Booking #"
                    fullWidth
                    required
                >
                    {props.bookings.map((option, idx) => (
                        <MenuItem key={idx} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setDocName(event.target.value)}
                    sx={{ bgcolor: 'white', mt: '5%' }}
                    variant="outlined"
                    select
                    value={docName}
                    id="doc-input"
                    label="Document Name"
                    fullWidth
                    required
                >
                    {docTypes.map((option, idx) => (
                        <MenuItem key={idx} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <UploadButton uploadFunc={uploadDoc} />
            </Box>
        </Drawer>
    )
}

/*Upload Document Text Field Button */
/*Drop Files and browse Files Button */
//TODO Display File Preview after Upload
//TODO Add Drag and drop Files
// TODO Add Files to Docs Table
// TODO Add files to their relative booking
//Input
//bookingNo
//DocumentName
//DocumentType
//fileType
//Drag and Drop
