import { LoadingButton } from '@mui/lab'
import { Button, Checkbox, Container, Divider, FormControlLabel, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BookingDetails from '../Components/bookingDetails'
import OrderSummary from '../Components/orderSummary'
import PermanentDrawer from '../Components/permanentDrawer'
import { useCurrentBookingContext } from '../Components/store'
import { createNewBooking } from '../firebase'
import { APP_SIDE_DRAWER_WIDTH_PX, APP_HEADER_HEIGHT_PX } from '../Util/constants'

export default function CustomsBooking() {
    const [checked, setChecked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currentBookingContext] = useCurrentBookingContext()
    const navigate = useNavigate()

    const submitBooking = async () => {
        setLoading(true)
        try {
            // await createNewBooking(
            //     currentBookingContext.uuid,
            //     currentBookingContext.bk_id,
            //     currentBookingContext,
            // )
            navigate('/displayReceipt')
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
    }
    return (
        <Container maxWidth="xl">
            <PermanentDrawer />

            <Grid
                container
                sx={{
                    pl: { xs: '10vw', sm: `${APP_SIDE_DRAWER_WIDTH_PX - 100}px` },
                    pt: `${APP_HEADER_HEIGHT_PX + 10}px`,
                }}
                spacing={2}
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            color: 'navy',
                            fontSize: '4vh',
                        }}
                    >
                        Review Order
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ pr: '20vw' }}>
                    <BookingDetails />
                </Grid>
                <Grid item xs={12} sx={{ pr: '20vw' }}>
                    <OrderSummary />
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Divider sx={{ mt: '2vh' }} />

                <FormControlLabel
                    label={
                        <Typography>
                            I agree to the Terms and Conditions, and Liability Agreement.
                        </Typography>
                    }
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            required
                        />
                    }
                    sx={{ alignSelf: 'center' }}
                />

                <LoadingButton
                    loading={loading}
                    variant="contained"
                    onClick={submitBooking}
                    disabled={!checked}
                    sx={{ width: '10%', mt: '1.5%', alignSelf: 'center' }}
                >
                    Book
                </LoadingButton>
            </Grid>
        </Container>
    )
}
