import * as React from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import Logo from '../../Assets/transiport-logo2.png'
import { APP_HEADER_HEIGHT_PX } from '../../Util/constants'
import { useNavigate } from 'react-router-dom'
import DropDown from './dropdownMenu'
import useMediaQuery from '@mui/material/useMediaQuery'
import theme from '../../globalTheme'
import {
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    AppBar,
    Box,
    Toolbar,
    Typography,
    Container,
    Button,
} from '@mui/material'
import { useState } from 'react'

const pages = ['Platform', 'Services']
const signupPages = ['Contact', 'Register', 'Login']
type Anchor = 'right'

function NavbarDesktop() {
    const navigate = useNavigate()
    return (
        <Container maxWidth="xl">
            <Toolbar disableGutters sx={{ boxSizing: 'border-box' }}>
                <Button onClick={() => navigate('/')}>
                    <Typography noWrap sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        <img src={Logo} alt="Logo" width="170vw" />
                    </Typography>
                </Button>

                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'end' }}>
                    {pages.map((page, idx) => (
                        <DropDown menuOption={page} key={idx} />
                    ))}
                    {signupPages.map((page) => (
                        <Button
                            key={page}
                            onClick={() => navigate(`${'/'}`.concat(page.toLowerCase()))}
                            sx={{
                                color: 'black',
                                fontWeight: 'bold',
                            }}
                        >
                            {page}
                        </Button>
                    ))}
                </Box>
            </Toolbar>
        </Container>
    )
}

function TemporaryDrawer() {
    const navigate = useNavigate()

    const [state, setState] = useState({
        right: false,
    })

    const toggleDrawer =
        (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return
            }

            setState({ ...state, [anchor]: open })
        }

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: '90vw' }}
            role="presentation"
            //onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {pages.map((text) => (
                    <DropDown menuOption={text} key={text} />
                ))}
            </List>
            <Divider />
            <List>
                {signupPages.map((text) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton onClick={() => navigate(`${'/'}`.concat(text.toLowerCase()))}>
                            <ListItemText primary={text} sx={{ fontWeight: 'bold' }} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    )

    return (
        <div>
            <React.Fragment key={'right'}>
                <Button onClick={toggleDrawer('right', true)}>{<MenuIcon />}</Button>
                <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
                    {list('right')}
                </Drawer>
            </React.Fragment>
        </div>
    )
}

function NavbarMobile() {
    const navigate = useNavigate()

    return (
        <Toolbar
            disableGutters
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            <Button onClick={() => navigate('/')}>
                <Typography variant="h6" noWrap component="div">
                    <img src={Logo} alt="Logo" width={'120vw'} />
                </Typography>
            </Button>
            <TemporaryDrawer />
        </Toolbar>
    )
}

export default function ResponsiveAppBar() {
    const desktop = useMediaQuery(theme.breakpoints.up('sm'))
    const mobile = useMediaQuery(theme.breakpoints.down('xl'))
    return (
        <AppBar
            position="fixed"
            sx={{
                background: 'white',
                height: `${APP_HEADER_HEIGHT_PX}px`,
            }}
        >
            {desktop && <NavbarDesktop />}
            {mobile && !desktop && <NavbarMobile />}
        </AppBar>
    )
}
