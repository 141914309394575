import MuiGrid from '@mui/material/Grid'
import {
    BookingDetails,
    BookingFinance,
    BookingMisc,
    BookingOverview,
    BookingStatus,
    ProductDetails,
} from '../adminForms/reusableForms'

interface BookingFormPropsT {
    row: any
    customers: any[]
    onChange: ({
        target: { name, value },
    }: {
        target: {
            name: any
            value: any
        }
    }) => void
}

// TODO: remove BLFile InvoiceFile Hack
export default function BookingPopup(props: BookingFormPropsT) {
    return (
        <MuiGrid container spacing={3}>
            <MuiGrid item xs={12}>
                <BookingOverview
                    setBLFile={props.setBLFile}
                    row={props.row}
                    onChange={props.onChange}
                    customers={props.customers}
                />
                <BookingStatus row={props.row} onChange={props.onChange} />
                <BookingDetails onChange={props.onChange} row={props.row} />
                <ProductDetails onChange={props.onChange} row={props.row} />
                <BookingFinance
                    setInvoiceFile={props.setInvoiceFile}
                    onChange={props.onChange}
                    row={props.row}
                />
                <BookingMisc onChange={props.onChange} row={props.row} />
            </MuiGrid>
        </MuiGrid>
    )
}
