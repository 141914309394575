import { useState } from 'react'
import { GenericTable } from './genericTable'
import { FINANCIAL_TABLE_COLUMNS, FINANCIAL_TABLE_COLUMN_EXTENSIONS } from '../adminConstants'
import { CustomerFinancialPopup } from '../adminPopups/customerFinancialPopup'

export default function FinancialsTable(props) {
    const getRowId = (row) => row.bk_id

    const [rows] = useState(props.rows ? props.rows : [])

    return (
        <GenericTable
            columns={FINANCIAL_TABLE_COLUMNS}
            columnExtensions={FINANCIAL_TABLE_COLUMN_EXTENSIONS}
            getRowId={getRowId}
            title={'CUSTOMER FINANCIALS'}
            popup={CustomerFinancialPopup}
            rows={rows}
        />
    )
}
