// import { formatSaveBookingFinanceInfo } from '../../../Util/adminUtil'
import { GenericPopup } from './genericPopup'
import CustomerFinancialForm from '../adminForms/customerFinancialForm'

export const CustomerFinancialPopup = (props) => {
    const onSave = async () => {
        try {
            console.log(props.row)
            // await formatSaveBookingFinanceInfo(row)
        } catch (error) {
            console.log('Error updating user finance data:', error)
        }
    }
    return (
        <GenericPopup
            title={'Customer Financials'}
            open={props.open}
            onSave={onSave}
            onCancel={props.onCancel}
        >
            <CustomerFinancialForm row={props.row} onChange={props.onEdit} />
        </GenericPopup>
    )
}
