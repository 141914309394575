import { Button, Box, Typography, Grid, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import FooterMenu from './footerMenu'
import Logo from '../../Assets/transiport-logo2.png'

const PLATFORM_PAGES = ['/landingSearch', '/landingBook', '/landingOrderManagement', '/landingAnalytics']
const SERVICE_PAGES = ['Forwarding', 'Customs', 'Trucking']

function Copyright() {
    return (
        <Typography variant="body2" color="white" textAlign="center">
            {'Copyright © '}
            <Link color="inherit" href="https://transiport.com/">
                Transiport
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

export default function Footer() {
    const navigate = useNavigate()

    return (
        <Box
            component="footer"
            sx={{
                position: 'sticky',
                width: '100vw',
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: '#04012b',
                color: 'white',
            }}
        >
            <Grid container maxWidth="xl" sx={{ pb: '2vh' }}>
                <Grid item container xs={6} direction="column" justifyItems="center">
                    <Button onClick={() => navigate('/')}>
                        <Typography noWrap component="div">
                            <img src={Logo} alt="Logo" height={150} />
                        </Typography>
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <FooterMenu header={'PLATFORM'} options={PLATFORM_PAGES} />
                </Grid>
                <Grid item xs={3}>
                    <FooterMenu header={'SERVICES'} options={SERVICE_PAGES} />
                    <Button onClick={() => navigate('/contact')}>
                        <Typography color="white" paddingLeft={'0.5vw'} fontWeight="bold">
                            CONTACT
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <Copyright />
        </Box>
    )
}
