import { useEffect, useState } from 'react'
import { ACTIVE_SHIPMENTS_COLUMNS, ACTIVE_SHIPMENTS_COLUMN_EXTENSIONS } from '../adminConstants'
import { GenericTable } from './genericTable'
import { ActiveBkPopup } from '../adminPopups/activeBkPopup'
// eslint-disable-next-line
/* eslint-disable no-shadow */


export default function TaskTable(props) {
    const [rows, setRows] = useState(props.rows ?? [])
    const getRowId = (row) => row.bk_id

    useEffect(() => {
        if (props.active) {
            setRows(Object.values(props.rows))
        } else if (props.attention) {
            setRows(props.attention)
        }
    }, [props])

    return (
        <GenericTable
            columns={ACTIVE_SHIPMENTS_COLUMNS}
            columnExtensions={ACTIVE_SHIPMENTS_COLUMN_EXTENSIONS}
            getRowId={getRowId}
            title={''}
            popup={ActiveBkPopup}
            rows={rows}
        />
    )
}
