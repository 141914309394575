import { Box, CircularProgress, Grid, Tab, Tabs, Typography } from '@mui/material'
import Drawer from '../Components/permanentDrawer'
import ShipTypePie from '../Components/shipmentTypeChart'
import TypeBar from '../Components/numShipmentPerMonthBar'
import OnTimeBar from '../Components/onTimeDeliveryBar'
import Line from '../Components/spendPerMonthGraph'
import { getUserAnalyticsData, useAuth } from '../Util/adminFirebase'
import { useAppContext } from '../Components/store'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { APP_HEADER_HEIGHT_PX, APP_SIDE_DRAWER_WIDTH_PX } from '../Util/constants'
import { generalBreakdownT, allCarriersInfoT, allMonthInfoT } from '../Util/constants.d'

interface TabPanelProps {
    children?: ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export default function Analytics() {
    const [value, setValue] = useState(0)
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }
    const [loading, setLoading] = useState<boolean>(true)
    const [breakdown, setBreakdown] = useState<generalBreakdownT>(null)
    const [carriersInfo, setCarriersInfo] = useState<allCarriersInfoT>(null)
    const [monthlyInfo, setMonthlyInfo] = useState<allMonthInfoT>(null)
    const appState = useAppContext()
    const navigate = useNavigate()
    useAuth(appState)

    // Load finance data when the component mounts and update whenever
    // user info changes
    useEffect(() => {
        const getAnalyticsData = async (uuid: string) => {
            const docSnap = await getUserAnalyticsData(uuid)
            const data = docSnap.data()
            setBreakdown({
                totalShipments: data.totalShipments,
                totalTransshipments: data.totalTransshipments,
                totalBookings: data.totalBookings,
            })

            const tmpMonthlyInfo: allMonthInfoT = data.monthlyInfo
            setMonthlyInfo(tmpMonthlyInfo)

            const tmpCarriersInfo: allCarriersInfoT = {}
            Object.entries(data.carrierInfo).forEach((item) => {
                const [key, val] = item
                tmpCarriersInfo[key.replaceAll(' ', '_')] = val
            })
            setCarriersInfo(tmpCarriersInfo)
            setLoading(false)
        }
        if (appState && !appState[0].loading && !appState[0].uuid) {
            navigate('/')
        } else if (appState && appState[0].uuid) {
            getAnalyticsData(appState[0].uuid)
        }
    }, [appState, navigate])

    const renderAnalytics = () => {
        if (loading) {
            return (
                <Box
                    sx={{
                        width: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        ml: '55vw',
                        mt: '28%',
                    }}
                >
                    <CircularProgress />
                    <Typography sx={{ ml: '-0.6vw', mt: '1%' }}>Loading</Typography>
                </Box>
            )
        } else {
            return (
                <Grid item xs={12}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="Invoices"
                            sx={{ '& .MuiTabs-flexContainer': { flexWrap: 'wrap' } }}
                        >
                            <Tab label="Shipment Type" {...a11yProps(0)} />
                            <Tab label="Shipments per month" {...a11yProps(1)} />
                            <Tab label="On-time Percentage" {...a11yProps(2)} />
                            <Tab label="Spending per month" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <ShipTypePie breakdown={breakdown} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TypeBar monthInfo={monthlyInfo} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <OnTimeBar carrierInfo={carriersInfo} />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Line monthInfo={monthlyInfo} />
                    </TabPanel>
                </Grid>
            )
        }
    }
    return (
        <Grid container>
            <Drawer />
            <Grid
                container
                sx={{
                    pt: `${APP_HEADER_HEIGHT_PX}px`,
                    pl: { sm: `${APP_SIDE_DRAWER_WIDTH_PX - 100}px`, xs: '14vw' },
                    justifyContent: 'center',
                }}
            >
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            color: 'navy',
                            fontSize: '4vh',
                        }}
                    >
                        Analytics
                    </Typography>
                </Grid>
                {renderAnalytics()}
            </Grid>
        </Grid>
    )
}
