import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

interface shipmentInfoProps {
    sailDate: string
    transitTime: string
    freeDays: number
}

export default function ShipInfo(shipInfoProps: shipmentInfoProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                width: '65%',
                mt: '.5%',
                ml: '1.5%',
                flexDirection: 'row',
                alignContent: 'left',
                justifyContent: 'space-between',
            }}
        >
            <Stack>
                <Typography fontSize={18} fontWeight={'bold'}>
                    ETD
                </Typography>
                <Typography fontSize={16} sx={{ mt: '5%' }}>
                    {shipInfoProps.sailDate}
                </Typography>
            </Stack>
            <Stack>
                <Typography fontSize={18} fontWeight={'bold'}>
                    Transit Time
                </Typography>
                <Typography fontSize={16} sx={{ mt: '5%' }}>
                    {shipInfoProps.transitTime}
                </Typography>
            </Stack>
            <Stack>
                <Typography fontSize={18} fontWeight={'bold'}>
                    Free Days
                </Typography>
                <Typography fontSize={16} sx={{ mt: '5%' }}>
                    {shipInfoProps.freeDays === 21 ? shipInfoProps.freeDays : 14} Days
                </Typography>
            </Stack>
        </Box>
    )
}
