import { ChangeEvent, useState } from 'react'
import { styled } from '@mui/material/styles'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import Stack from '@mui/material/Stack'
import { Button, IconButton } from '@mui/material'

const Input = styled('input')({
    display: 'none',
})

// TODO: define a clear interface for what we expect from a upload function
interface UploadFuncT {
    (file: File): Promise<void>
}

interface UploadButtonPropsT {
    uploadFunc: UploadFuncT
}

export default function UploadButtons(props: UploadButtonPropsT) {
    const [loading, setLoading] = useState<boolean>(false)
    const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
        setLoading(true)
        await props.uploadFunc(e.target.files[0])
        setLoading(false)
    }
    return (
        <Stack direction="row" alignItems="center" justifyContent={'center'} spacing={2}>
            <label htmlFor="contained-button-file">
                <Input accept="doc/*" id="contained-button-file" onChange={uploadFile} type="file" />
                <Button
                    variant="contained"
                    sx={{ mt: '2vh' }}
                    endIcon={<FileUploadOutlinedIcon />}
                    color="primary"
                    component="span"
                >
                    UPLOAD
                </Button>
            </label>
        </Stack>
    )
}
