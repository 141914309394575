import { Grid, CssBaseline, Button, Typography } from '@mui/material'
import MapPic from '../../Assets/map.jpeg'
import SearchVid from '../../Assets/finalSearch.mp4'
import CarrierBar from '../../Assets/logos2.gif'
import Footer from '../../Components/footerComponents/footer'
import LandingAppBar from '../../Components/landingComponents/landingAppBar'
import { APP_HEADER_HEIGHT_PX } from '../../Util/constants'
import { useScrollToTop } from '../../Util/util'

export default function SearchLanding() {
    useScrollToTop()
    return (
        <Grid container component="main">
            <CssBaseline />
            <LandingAppBar />
            <Grid
                container
                sx={{
                    px: { xs: 2 },
                    height: {
                        xs: `calc(70vh - calc(${APP_HEADER_HEIGHT_PX}px)
             )`,
                        sm: '100vh',
                    },
                    justifyContent: 'center',
                }}
            >
                <img
                    alt={'Search'}
                    src={MapPic}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        left: '50%',
                        top: '50%',
                        height: '100%',
                        objectFit: 'cover',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '-1',
                        filter: 'brightness(50%)',
                    }}
                />
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignSelf: { xs: 'flex-end', sm: 'center' },
                        color: 'white',
                        textAlign: { xs: 'left', sm: 'center' },
                    }}
                >
                    <Typography sx={{ fontSize: { xs: '4vh', sm: '8vh' } }}>
                        Freight Charges Demystified
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '2vh', sm: '4vh' } }}>
                        Plan shipments optimally
                    </Typography>
                    <Button
                        variant="contained"
                        href="/register"
                        sx={{ my: { xs: 2 }, alignSelf: 'center' }}
                    >
                        Sign Up
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={0} sm={12} sx={{ display: { xs: 'none', sm: 'flex' } }}>
                <img src={CarrierBar} alt="Carriers" style={{ width: '80vw', marginLeft: '5vw' }} />

                <Typography
                    textAlign="justify"
                    sx={{
                        fontSize: { xs: '1vh', sm: '4vh' },
                        alignSelf: 'center',
                        mr: '2vw',
                    }}
                >
                    Ocean and air shipments are complicated. There's so much involved. We'll do the
                    needful, so your shipment is delivered on time!
                </Typography>
            </Grid>
            <Grid container sx={{ backgroundColor: '#04021b', p: { xs: 0, sm: 0 } }}>
                <Grid item xs={12} sm={6} sx={{ color: 'white', alignSelf: 'center' }}>
                    <Typography
                        fontFamily={['Roboto', 'sans-serif']}
                        fontSize={33}
                        textAlign="justify"
                        fontWeight={325}
                        sx={{
                            fontSize: { xs: '2vh', sm: '4vh' },
                            alignSelf: 'center',
                        }}
                    >
                        Ocean and air shipments are complicated. There's so much involved. We'll do the
                        needful, so your shipment is delivered on time!
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                        px: { xs: 2, sm: 2 },
                        py: { xs: 4, sm: 2 },
                    }}
                >
                    <video
                        autoPlay
                        loop
                        muted
                        height={'100%'}
                        width={'100%'}
                        style={{
                            position: 'static',
                            alignContent: 'left',
                            objectFit: 'cover',
                            //border: '1 solid black',
                            //boxShadow: '15px -20px 45px grey',
                            //transform: 'skew(0deg,10deg)',
                        }}
                    >
                        <source src={SearchVid} type="video/mp4" />
                    </video>
                </Grid>
            </Grid>
            <Footer />

        </Grid>
    )
}
