import {
    Grid,
    TextField,
    MenuItem,
    Button,
    Typography,
    Switch,
    Stack,
    InputAdornment,
} from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { CurrentBookingInfoT } from '../../Util/constants'
import { CUSTOMS, CUSTOMS_PRICE, TRUCKING } from '../additionalServices'
import { useCurrentBookingContext } from '../store'
import { FormGroupHeader, AmountInput, SelectForm } from './adminForms/reusableForms'

const CUSTOMERS = ['', 'PSO', 'Aramco', 'Nishat']

export default function ManualBookingInput() {
    const [customer, setCustomer] = useState('')
    const [freightMargin, setFreightMargin] = useState(null)
    const [customsMargin, setCustomsMargin] = useState(null)
    const [truckMargin, setTruckMargin] = useState(null)

    const [currentBookingContext, setCurrentBookingContext] = useCurrentBookingContext()

    const [checked, setChecked] = useState(true)
    const [dollar, setDollar] = useState(true)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
    }
    const handleTruckCur = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDollar(event.target.checked)
    }

    return (
        <Grid container spacing={5} maxWidth="xl">
            <Grid item xs={6}>
                <FormGroupHeader label={'Add Customer'} />

                <TextField
                    id="customer-select"
                    select
                    label="Customer"
                    value={customer}
                    fullWidth
                    required
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setCustomer(event.target.value)}
                >
                    {CUSTOMERS.map((option, idx) => (
                        <MenuItem key={idx} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormGroupHeader label={'Add Freight Margin'} />
                <AmountInput
                    label={'Freight Margin'}
                    onChange={(e) => setFreightMargin(e.target.value)}
                    type={checked ? 'dollar' : 'percent'}
                    value={freightMargin}
                />
                <Stack direction="row" spacing={1} alignItems="center" sx={{ justifyContent: 'center' }}>
                    <Typography>Percent</Typography>
                    <Switch checked={checked} onChange={handleChange} />
                    <Typography>Dollar</Typography>
                </Stack>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', height:'25%', flexDirection: 'column' }}>
                <FormGroupHeader label={'Add Customs Margin'} />
                <AmountInput
                    label={'Customs Margin'}
                    onChange={(e) => setCustomsMargin(e.target.value)}
                    type="dollar"
                    value={customsMargin}
                />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', height:'25%',flexDirection: 'column' }}>
                <FormGroupHeader label={'Add Trucking Margin'} />
                <TextField
                    fullWidth
                    InputProps={{
                        startAdornment: dollar ? (
                            <InputAdornment position="start">$</InputAdornment>
                        ) : (
                            <InputAdornment position="start">Rs.</InputAdornment>
                        ),
                    }}
                    label={'Trucking Margin'}
                    onChange={(e) => setTruckMargin(e.target.value)}
                    type="currency"
                    value={truckMargin}
                />
                <Stack direction="row" spacing={1} alignItems="center" sx={{ justifyContent: 'center' }}>
                    <Typography>Rupee</Typography>
                    <Switch checked={dollar} onChange={handleTruckCur} />
                    <Typography>Dollar</Typography>
                </Stack>
            </Grid>
        </Grid>
    )
}
