import { Box, CircularProgress, Typography } from '@mui/material'

export const LoadingWheel = () => (
    <Box
        sx={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            ml: '30vw',
            mt: '10%',
        }}
    >
        <CircularProgress />
        <Typography sx={{ ml: '-0.6vw', mt: '1%' }}>Loading</Typography>
    </Box>
)
