import { Dispatch, SetStateAction, ChangeEvent, useEffect, useState } from 'react'
import { TextField, Grid } from '@mui/material'

// TODO Input Verification and exception handling
interface PartyDetailsT {
    companyName: string
    NTN: string
    address: string
    phoneNo: string
    emailAddr: string
}

interface companyTextFieldsPropsT {
    changeFunc: Dispatch<SetStateAction<PartyDetailsT>>
}

export default function CompanyTextFields(props: companyTextFieldsPropsT) {
    const [companyName, setCompanyName] = useState('')
    const [Ntn, setNtn] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')

    useEffect(() => {
        props.changeFunc({
            companyName: companyName,
            NTN: Ntn,
            address: address,
            phoneNo: phone,
            emailAddr: email,
        })
    }, [companyName, Ntn, email, phone, address])

    return (
        <Grid
            container
            spacing={2}
            component="form"
            sx={{
                '& .MuiTextField-root': {
                    m: 1,
                    width: '100%',
                    justifyContent: 'center',
                },
                mb: '2.5%',
            }}
            noValidate
            autoComplete="off"
        >
            <Grid item xs={6}>
                <TextField
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setCompanyName(event.target.value)
                    }
                    variant="outlined"
                    value={companyName}
                    id="company-name-input"
                    label="Company Name"
                    defaultValue="Enter Company Name"
                    required
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setAddress(event.target.value)}
                    variant="outlined"
                    value={address}
                    id="address-input"
                    label="Address"
                    defaultValue="Enter Address"
                    required
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setPhone(event.target.value)}
                    variant="outlined"
                    value={phone}
                    id="phone-input"
                    label="Phone #"
                    defaultValue="Enter Phone Number"
                    required
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                    variant="outlined"
                    value={email}
                    id="email-input"
                    label="Email Address"
                    defaultValue="Enter Email Address"
                    required
                />
            </Grid>
        </Grid>
    )
}
