import * as React from 'react'
import { Box, Typography } from '@mui/material'
import AccountAppBar from '../Components/resultsAppBar'
import { APP_HEADER_HEIGHT_PX } from '../Util/constants'

export default function waitForVerification() {
    const verMessage =
        'We are verifying your details right now. Once verified, you will recieve an email from ' +
        'us. Feel free to contact us with any questions at info@transiport.com ' +
        'or call us at (+92)-320-9472599.'
    return (
        <Box sx={{ position: 'absolute', display: 'flex', maxWidth: `100vw`, height: '100vh' }}>
            <AccountAppBar />
            <Box
                position="relative"
                sx={{
                    ml: '5%',
                    mt: `${APP_HEADER_HEIGHT_PX}px`,
                    marginRight: '2.5%',
                    display: 'block',
                    flexBasis: 'auto',
                    width: '80vw',
                }}
            >
                <Typography
                    sx={{
                        mt: '5%',
                        fontFamily: 'calibri-light',
                        borderBottom: 1,
                        borderColor: 'divider',
                        color: 'navy',
                    }}
                    variant="h3"
                >
                    Welcome to Transiport
                </Typography>
                <Typography sx={{ mt: '7%', fontFamily: 'calibri-light' }} variant="h4">
                    Thank you for registering with us!
                </Typography>
                <Typography sx={{ pt: '2%', fontFamily: 'calibri-light' }} variant="h5">
                    {verMessage}
                </Typography>
            </Box>
        </Box>
    )
}
