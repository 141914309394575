import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import AdminDrawer from '../../Components/adminComponents/adminDrawer'
import PartnersTable from '../../Components/adminComponents/adminTables/partnersTable'
import { APP_HEADER_HEIGHT_PX, APP_SIDE_DRAWER_WIDTH_PX } from '../../Util/constants'
import { loadAllPartners } from '../../Util/adminFirebase'
import { LoadingWheel } from '../../Components/loadingWheel'

export default function Partners() {
    const [partners, setPartners] = useState({})
    const [loading, setLoading] = useState(false)
    // load partners and associated data
    useEffect(() => {
        const loadPartners = async () => {
            // TODO: use app state to verify admin
            setLoading(true)
            try {
                const partners = await loadAllPartners()
                const dataRows = []
                partners.forEach((doc) => {
                    // create data rows
                    const partnerID = doc.id // uuid
                    const partnerData = doc.data()
                    dataRows.push({ ...partnerData, id: partnerID })
                })
                setPartners(dataRows)
                setLoading(false)
            } catch (error) {
                console.log('Error retrieving transiport data: ', error)
                setLoading(false)
            }
        }
        loadPartners()
    }, [])

    return (
        <Grid container maxWidth="xl">
            <AdminDrawer />
            <Grid
                container
                sx={{
                    pt: `${APP_HEADER_HEIGHT_PX}px`,
                    pl: { sm: `${APP_SIDE_DRAWER_WIDTH_PX - 60}px`, xs: 10 },
                    pr: { sm: '10vw' },
                    justifyContent: 'center',
                }}
            >
                {loading ? <LoadingWheel /> : <PartnersTable rows={partners} />}
            </Grid>
        </Grid>
    )
}
