import { Box, Button, CssBaseline, Grid, Typography } from '@mui/material'
import TruckPic from '../../Assets/truckImage.jpg'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import EarbudsOutlinedIcon from '@mui/icons-material/EarbudsOutlined'
import StarRateOutlinedIcon from '@mui/icons-material/StarRateOutlined'
import LandingAppBar from '../../Components/landingComponents/landingAppBar'
import TruckImage from '../../Assets/truck.jpg'
import { APP_HEADER_HEIGHT_PX } from '../../Util/constants'
import Footer from '../../Components/footerComponents/footer'
import { useScrollToTop } from '../../Util/util'

export default function Trucking() {
    useScrollToTop()
    return (
        <Grid container component="main">
            <CssBaseline />
            <LandingAppBar />
            <Grid
                container
                sx={{
                    px: { xs: 2 },
                    height: {
                        xs: `calc(70vh - calc(${APP_HEADER_HEIGHT_PX}px)
             )`,
                        sm: '100vh',
                    },
                    justifyContent: 'center',
                }}
            >
                <img
                    alt={'Trucking'}
                    src={TruckPic}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        left: '50%',
                        top: '50%',
                        height: '100%',
                        objectFit: 'cover',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '-1',
                        filter: 'brightness(50%)',
                    }}
                />
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignSelf: { xs: 'flex-end', sm: 'center' },
                        color: 'white',
                        textAlign: { xs: 'left', sm: 'center' },
                    }}
                >
                    <Typography sx={{ fontSize: { xs: '4vh', sm: '8vh' } }}>
                        Simple, Dependable Hauling
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '2vh', sm: '4vh' } }}>
                        Trucking services customized to uor needs
                    </Typography>
                    <Button
                        variant="contained"
                        href="/register"
                        sx={{ my: { xs: 2 }, alignSelf: 'center' }}
                    >
                        Sign Up
                    </Button>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    bgcolor: '#04012b',
                    color: 'white',
                    pt: { xs: '2vh' },
                    height: { xs: '100vh', sm: 'auto' },
                }}
            >
                <Grid item xs={12} md={4}>
                    <Box sx={{ display: 'stack', textAlign: 'center' }}>
                        <EarbudsOutlinedIcon sx={{ fontSize: 100, justifyContent: 'center' }} />
                        <Typography textAlign="center" fontWeight="bold" fontSize={24}>
                            Flexible
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box
                        sx={{
                            display: 'stack',
                            alignContent: 'center',
                            textAlign: 'center',
                        }}
                    >
                        <StarRateOutlinedIcon sx={{ fontSize: 100 }} />
                        <Typography textAlign="center" fontWeight="bold" fontSize={24}>
                            Quality
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{ display: 'stack', textAlign: 'center' }}>
                        <AddCircleOutlineOutlinedIcon sx={{ fontSize: 100 }} />
                        <Typography textAlign="center" fontWeight="bold" fontSize={24}>
                            Comprehensive
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid item sm={12}>
                <Grid
                    container
                    position="relative"
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        flexDirection: 'row',
                        bgcolor: 'white',
                        color: 'black',
                    }}
                >
                    <Grid item sm={6} alignSelf="center">
                        <Typography
                            fontFamily={['Roboto', 'sans-serif']}
                            fontSize={30}
                            textAlign="left"
                            fontWeight={325}
                            marginX={2}
                        >
                            Add trucking and your goods will be delivered to your door
                        </Typography>
                    </Grid>
                    <Grid item sm={6} alignSelf="center">
                        <img
                            alt={'Truck'}
                            src={TruckImage}
                            height={'100%'}
                            width={'100%'}
                            style={{
                                position: 'static',
                                alignContent: 'left',
                                objectFit: 'cover',
                                //boxShadow: '15px -20px 45px grey',
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Footer />

        </Grid>
    )
}
