import CircleIcon from '@mui/icons-material/Circle'
import { Box, Stepper, Step, StepLabel } from '@mui/material'
import StepConnector from '@mui/material/StepConnector'
import Typography from '@mui/material/Typography'

// Use resultProvider
// Import Route details from json response
interface TimelineProps {
    stops: string[]
}

export default function RouteTimeline(timelineProps: TimelineProps) {
    return (
        <Stepper
            alternativeLabel
            sx={{ mt: '5%' }}
            connector={
                <StepConnector
                    sx={{
                        margin: '0!important',
                        top: 'calc(6.5% )!important',
                        left: 'calc(-45% )!important',
                        right: 'calc(55% )!important',
                        borderTopColor: '#474747',
                    }}
                />
            }
            activeStep={-1}
        >
            {timelineProps.stops
                ? timelineProps.stops.map((label, idx) => (
                      <Step key={idx}>
                          <StepLabel
                              StepIconProps={{ sx: { m: '0!important', fontSize: 7 } }}
                              StepIconComponent={CircleIcon}
                          >
                              <Typography
                                  sx={{ m: '0!important', mb: '5%', color: '#474747' }}
                                  fontSize={15}
                              >
                                  {label}
                              </Typography>
                          </StepLabel>
                      </Step>
                  ))
                : null}
        </Stepper>
    )
}
