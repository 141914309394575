import React, { useState } from 'react'
import { Paper } from '@mui/material'
import { PieSeries, Chart, Title, Legend } from '@devexpress/dx-react-chart-material-ui'
import { Animation } from '@devexpress/dx-react-chart'
import { generalBreakdownT } from '../Util/constants.d'

interface shipmentTypePiePropsT {
    breakdown: generalBreakdownT
}
interface shipmentTypeDataT {
    shipmentType: string
    total: number
}

export default function ShipmentTypePie(props: shipmentTypePiePropsT) {
    const [chartData] = useState<shipmentTypeDataT[]>([
        {
            shipmentType: 'Trans-shipment',
            total: props.breakdown.totalTransshipments,
        },
        {
            shipmentType: 'Direct',
            total: props.breakdown.totalShipments - props.breakdown.totalTransshipments,
        },
    ])

    return (
        <Paper sx={{ bgcolor: 'aliceblue', width: '60%'}}>
            <Chart data={chartData}>
                <PieSeries
                    name="Shipment Type Comparison"
                    valueField="total"
                    argumentField="shipmentType"
                    innerRadius={0.6}
                />
                <Legend position="bottom" />
                <Title text={'Type of Shipments'} />
                <Animation />
            </Chart>
        </Paper>
    )
}
