// GENERIC CONSTANTS
export const EMAIL = 'email'
export const NAME = 'name'
export const PHONE = 'phone'
export const ADDRESS = 'address'
export const NOTES = 'notes'
export const ACTIVE_SHIPMENTS = 'active_shipments'
export const COMPLETED_SHIPMENTS = 'completed_shipments'
export const PENDING_SHIPMENTS = 'pending_shipments'
export const DELAYED_SHIPMENTS = 'delayed_shipments'
export const ATTENTION_REQUIRED_SHIPMENTS = 'attention_required_shipments'

// DATA TYPES
export const DATA_TYPE_BOOKINGS = 'bookings'
export const DATA_TYPE_FINANCE = 'finance'

// BOOKING CONSTANTS
export const BOOKING_ID = 'bk_id'
export const BL_NO = 'bl_no'
export const AGENT = 'agent'
export const INVOICE_ID = 'invoice_num'
export const QUOTE_TYPE = 'quote_type' // SPOT, partner, contract
export const VOYAGE_REF = 'voyage_ref'
export const RATE_REF = 'rate_ref'
export const CONTAINERS_QUANTITY = 'num_containers'
export const CONTAINER_TYPE = 'container_type'
export const INCOTERM = 'inco'
export const BOOKING_TYPE = 'booking_type'
export const TRADE = 'trade'
export const ORIGIN = 'origin'
export const DESTINATION = 'dest'
export const ETD = 'depart_date'
export const ETA = 'arrival_date'
export const LINE = 'carrier'
export const IS_DELAYED = 'is_delayed'
export const DELAYED_BY = 'delayed_by'
export const IS_TRANSSHIPMENT = 'is_transshipment'
export const TOTAL_PRICE_USD = 'total_price_usd'
export const PAID_AMOUNT = 'paid_amount'
export const BALANCE_DUE = 'balance_due'
export const FREIGHT_COST = 'freight_cost'
export const CUSTOMS_COST = 'customs_cost'
export const TRUCKING_COST = 'trucking_cost'
export const TOTAL_MARGIN = 'total_margin'
export const FREIGHT_MARGIN = 'freight_margin'
export const CUSTOMS_MARGIN = 'customs_margin'
export const TRUCKING_MARGIN = 'trucking_margin'
export const FREIGHT_TOTAL = 'freight_total'
export const CUSTOMS_TOTAL = 'customs_total'
export const TRUCKING_TOTAL = 'trucking_total'
export const CURRENT_STATUS = 'current_status'
export const IS_ACTIVE = 'is_active'
export const IS_PENDING = 'is_pending'
export const REQUIRES_ATTENTION = 'requires_attention'
export const ATTENTION_REASON = 'attention_reason'
export const IS_COMPLETED = 'is_completed'
export const INVOICE_FILE = 'invoice_file'
export const BL_FILE = 'bl_file'
export const PROD_DESC = 'prod_desc'
export const HS_CODE = 'hs_code'
export const ADD_CUSTOMS = 'add_customs'
export const ADD_TRUCKING = 'add_trucking'
export const TOTAL_MISC_CHARGES = 'total_misc_charges'
export const MISC_CHARGES_BREAKDOWN = 'misc_charges_breakdown'
export const TOTAL_MISC_CHARGES_COST = 'total_misc_charges_cost'
export const TOTAL_MISC_CHARGES_MARGIN = 'total_misc_charges_margin'
export const MISC_CHARGES_COST_MARGIN_BREAKDOWN = 'misc_charges_cost_margin_breakdown'

// CUSTOMER CONSTANTS
export const CUSTOMER_ID = 'uuid'
export const CUSTOMER_VERIFIED = 'is_verified'
export const CUSTOMER_NTN = 'ntn'
export const CUSTOMER_BOOKINGS = 'total_bookings'
export const CUSTOMER_NOTES = 'customer_notes'
export const CUSTOMER_FREIGHT_MARGIN = 'freight_margin_pct'
export const CUSTOMER_CUSTOMS_MARGIN = 'customs_margin_usd'
export const CUSTOMER_TRUCKING_MARGIN = 'trucking_margin_usd'

// ACTIVE SHIPMENTS CONSTANTS
const DAYS_AT_STAGE = 'days_at_stage'
const CUTOFF_DATE = 'cutoff_date'
const DAYS_TO_CUTOFF = 'days_to_cutoff'
const SPECIAL_INSTRUCTIONS = 'special_instructions'

// TRUCKING CONSTANTS
const BROKER_ID = 'broker_id'
const TRUCKING_PRICE = 'trucking_price_usd'
const TRUCK_QUOTE_DATE = 'truck_quote_date'

// PARTNERS CONSTANTS
const PARTNER_ID = 'partner_id'
const COUNTRY = 'country'
const WHATSAPP = 'whatsapp'
const WECHAT = 'wechat'
const RATE_SHEET_FILE = 'rate_sheet'
const UPLOAD_DATE = 'upload_date'

export const BOOKING_TABLE_COLUMNS = [
    { name: BOOKING_ID, title: 'Booking ID' },
    { name: BL_NO, title: 'B/L No.' },
    //{ name: NAME, title: 'Customer Name/ID' },
    { name: AGENT, title: 'Assigned to' },
    { name: INVOICE_ID, title: 'Invoice No.' },
    { name: CONTAINERS_QUANTITY, title: '# Containers' },
    { name: CONTAINER_TYPE, title: 'Container Type' },
    { name: INCOTERM, title: 'Incoterm' },
    { name: BOOKING_TYPE, title: 'Shipment Type' },
    { name: TRADE, title: 'Trade' },
    { name: ORIGIN, title: 'Origin' },
    { name: DESTINATION, title: 'Destination' },
    { name: ETD, title: 'ETD' },
    { name: ETA, title: 'ETA' },
    { name: LINE, title: 'Shipping Line' },
    { name: IS_DELAYED, title: 'Delayed' },
    { name: DELAYED_BY, title: 'Delay Days' },
    { name: IS_TRANSSHIPMENT, title: 'Trans-shipment' },
    { name: TOTAL_PRICE_USD, title: 'Total Due' },
    { name: PAID_AMOUNT, title: 'Paid ($)' },
    { name: BALANCE_DUE, title: 'Balance Due ($)' },
    { name: FREIGHT_COST, title: 'Freight Cost ($)' },
    { name: CUSTOMS_COST, title: 'Customs Cost ($)' },
    { name: TRUCKING_COST, title: 'Trucking Cost ($)' },
    { name: TOTAL_MARGIN, title: 'Total Margin ($)' },
    { name: FREIGHT_MARGIN, title: 'Freight Margin ($)' },
    { name: CUSTOMS_MARGIN, title: 'Customs Margin ($)' },
    { name: TRUCKING_MARGIN, title: 'Trucking Margin ($)' },
    { name: TOTAL_MISC_CHARGES_COST, title: 'Misc Charges Cost' },
    { name: TOTAL_MISC_CHARGES_MARGIN, title: 'Misc Margin Total' },
    { name: MISC_CHARGES_COST_MARGIN_BREAKDOWN, title: 'Misc Charges Cost Margin Breakdown' },
    { name: CURRENT_STATUS, title: 'Status' },
    { name: IS_ACTIVE, title: 'Active' },
    { name: INVOICE_FILE, title: 'Invoice' },
    { name: BL_FILE, title: 'B/L' },
    { name: PROD_DESC, title: 'Product Description' },
    { name: HS_CODE, title: 'HS Code' },
    { name: ADD_CUSTOMS, title: 'Customs' },
    { name: ADD_TRUCKING, title: 'Trucking' },
]

export const FINANCIAL_TABLE_COLUMNS = [
    { name: BOOKING_ID, title: 'Booking ID' },
    { name: BL_NO, title: 'B/L No.' },
    { name: TOTAL_PRICE_USD, title: 'Total Due' },
    { name: PAID_AMOUNT, title: 'Paid ($)' },
    { name: BALANCE_DUE, title: 'Balance Due ($)' },
    { name: FREIGHT_COST, title: 'Freight Cost ($)' },
    { name: CUSTOMS_COST, title: 'Customs Cost ($)' },
    { name: TRUCKING_COST, title: 'Trucking Cost ($)' },
    { name: TOTAL_MARGIN, title: 'Total Margin ($)' },
    { name: FREIGHT_MARGIN, title: 'Freight Margin ($)' },
    { name: CUSTOMS_MARGIN, title: 'Customs Margin ($)' },
    { name: TRUCKING_MARGIN, title: 'Trucking Margin ($)' },
    { name: TOTAL_MISC_CHARGES, title: 'Total Misc Charge' },
    { name: MISC_CHARGES_BREAKDOWN, title: 'Misc Charges Breakdown' },
    { name: TOTAL_MISC_CHARGES_COST, title: 'Misc Charges Cost' },
    { name: TOTAL_MISC_CHARGES_MARGIN, title: 'Misc Margin Total' },
    { name: MISC_CHARGES_COST_MARGIN_BREAKDOWN, title: 'Misc Charges Cost Margin Breakdown' },
]

export const CUSTOMER_TABLE_COLUMNS = [
    { name: CUSTOMER_ID, title: 'ID' },
    { name: EMAIL, title: 'Email' },
    { name: NAME, title: 'Company Name' },
    { name: CUSTOMER_VERIFIED, title: 'Verified' },
    { name: CUSTOMER_NTN, title: 'NTN' },
    { name: CUSTOMER_BOOKINGS, title: 'Bookings' },
    { name: PHONE, title: 'Phone #' },
    { name: CUSTOMER_FREIGHT_MARGIN, title: 'Freight Margin' },
    { name: CUSTOMER_CUSTOMS_MARGIN, title: 'Trucking Margin' },
    { name: CUSTOMER_TRUCKING_MARGIN, title: 'Customs Margin' },
]

export const ACTIVE_SHIPMENTS_COLUMNS = [
    { name: BOOKING_ID, title: 'Booking No.' },
    { name: BL_NO, title: 'B/L No.' },
    { name: NAME, title: 'Company Name' },
    { name: CURRENT_STATUS, title: 'Shipment Stage' },
    { name: DAYS_AT_STAGE, title: 'Days at Stage' },
    { name: CUTOFF_DATE, title: 'Cutoff Date' },
    { name: DAYS_TO_CUTOFF, title: 'Days to Cutoff' },
    { name: AGENT, title: 'Assigned to' },
    { name: SPECIAL_INSTRUCTIONS, title: 'Special Instructions' },
]

export const CUSTOMER_TABLE_COLUMN_EXTENSIONS = [
    { columnName: CUSTOMER_ID, width: 100 },
    { columnName: NAME, width: 150 },
    { columnName: CUSTOMER_BOOKINGS, width: 150, align: 'center' },
    { columnName: PHONE, width: 150 },
    { columnName: CUSTOMER_FREIGHT_MARGIN, width: 160, align: 'center' },
    { columnName: CUSTOMER_CUSTOMS_MARGIN, width: 150, align: 'center' },
    { columnName: CUSTOMER_TRUCKING_MARGIN, width: 160, align: 'center' },
]

export const BOOKING_TABLE_COLUMN_EXTENSIONS = [
    { columnName: CONTAINERS_QUANTITY, width: 130 },
    { columnName: CONTAINER_TYPE, width: 140 },
    { columnName: INCOTERM, width: 100 },
    { columnName: BOOKING_TYPE, width: 150 },
    { columnName: TRADE, width: 110 },
    { columnName: LINE, width: 130 },
    { columnName: IS_TRANSSHIPMENT, width: 150 },
    { columnName: PROD_DESC, width: 180 },
    { columnName: FREIGHT_COST, width: 150, align: 'center' },
    { columnName: CUSTOMS_COST, width: 160, align: 'center' },
    { columnName: TRUCKING_COST, width: 160, align: 'center' },
    { columnName: TOTAL_MARGIN, width: 150, align: 'center' },
    { columnName: FREIGHT_MARGIN, width: 180, align: 'center' },
    { columnName: CUSTOMS_MARGIN, width: 180, align: 'center' },
    { columnName: TRUCKING_MARGIN, width: 180, align: 'center' },
]

export const FINANCIAL_TABLE_COLUMN_EXTENSIONS = [
    { columnName: BL_NO, width: 100, align: 'center' },
    { columnName: TOTAL_PRICE_USD, width: 150, align: 'center' },
    { columnName: BALANCE_DUE, width: 150, align: 'center' },
    { columnName: FREIGHT_COST, width: 150, align: 'center' },
    { columnName: CUSTOMS_COST, width: 160, align: 'center' },
    { columnName: TRUCKING_COST, width: 160, align: 'center' },
    { columnName: TOTAL_MARGIN, width: 150, align: 'center' },
    { columnName: FREIGHT_MARGIN, width: 180, align: 'center' },
    { columnName: CUSTOMS_MARGIN, width: 180, align: 'center' },
    { columnName: TRUCKING_MARGIN, width: 180, align: 'center' },
]

export const ACTIVE_SHIPMENTS_COLUMN_EXTENSIONS = [
    { columnName: BL_NO, width: 100 },
    { columnName: NAME, width: 150, align: 'center' },
    { columnName: CURRENT_STATUS, width: 250, align: 'center' },
    { columnName: DAYS_AT_STAGE, width: 150, align: 'center' },
    { columnName: CUTOFF_DATE, width: 150, align: 'center' },
    { columnName: DAYS_TO_CUTOFF, width: 160, align: 'center' },
    { columnName: AGENT, width: 160, align: 'center' },
    { columnName: SPECIAL_INSTRUCTIONS, width: 200, align: 'center' },
]

export const TRUCK_TABLE_COLUMNS = [
    { name: BROKER_ID, title: 'Broker #' },
    { name: NAME, title: 'Broker Name' },
    { name: PHONE, title: 'Contact' },
]

export const TRUCKING_RATES_COLUMNS = [
    { name: ORIGIN, title: 'Origin' },
    { name: DESTINATION, title: 'Destination' },
    { name: TRUCKING_PRICE, title: 'Price' },
    { name: TRUCK_QUOTE_DATE, title: 'Date' },
]

export const PARTNERS_TABLE_COLUMNS = [
    { name: PARTNER_ID, title: 'Partner No.' },
    { name: NAME, title: 'Name' },
    { name: COUNTRY, title: 'Country' },
    { name: PHONE, title: 'Phone' },
    { name: ADDRESS, title: 'Address' },
    { name: WHATSAPP, title: 'Whatsapp' },
    { name: WECHAT, title: 'WeChat' },
]

export const RATE_SHEET_TABLE_COLUMNS = [
    { name: RATE_SHEET_FILE, title: 'Rate Sheet' },
    { name: UPLOAD_DATE, title: 'Upload Date' },
]
// Table -> popup + toggle_row -> each row accordion -> contains tables -> popup
