import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import globalTheme from './globalTheme'

ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider theme={globalTheme}>
            <App />
        </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root'),
)
