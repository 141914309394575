import * as React from 'react'
import { Box, Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useNavigate } from 'react-router-dom'
import ServiceTable from '../Components/serviceInfo'
import { useCurrentBookingContext } from '../Components/store'
import { createNewBooking } from '../Util/adminFirebase'
import OrderSummary from '../Components/orderSummary'
import RouteTimeline from '../Components/routeTimeline'
import ShipmentDetails from '../Components/shipInfo'
import LogoParser from '../Components/logoParser'
import BookingDetails from '../Components/bookingDetails'

export default function SubmitBooking() {
    const [checked, setChecked] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [currentBookingContext] = useCurrentBookingContext()
    const navigate = useNavigate()

    const submitBooking = async () => {
        setLoading(true)
        try {
            await createNewBooking(
                currentBookingContext.uuid,
                currentBookingContext.bk_id,
                currentBookingContext,
            )
            navigate('/displayReceipt')
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
    }

    return (
        <Grid
            container
            rowSpacing={3}
            columnSpacing={16}
            sx={{
                mt: '5vh',
                borderRadius: '8px',
                //border: '1px solid black',
            }}
        >
            <Grid item xs={4}>
                <BookingDetails />
            </Grid>

            <Grid direction="column" item xs={8}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        bgcolor: '#F5F5F5',
                        border: (theme) => `1px solid ${theme.palette.divider}`,
                        borderRadius: '15px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            mr: '2%',
                            ml: '2%',
                        }}
                    >
                        <LogoParser carrierName={currentBookingContext.shipping_line} />
                    </Box>
                    <Divider orientation="vertical" flexItem />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        <RouteTimeline stops={currentBookingContext.stops} />
                        <Divider sx={{ width: '100%' }} flexItem />
                        <ShipmentDetails
                            transitTime={currentBookingContext.transit_time}
                            sailDate={currentBookingContext.depart_date}
                            freeDays={currentBookingContext.free_days}
                        />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <OrderSummary />
            </Grid>
            <Grid item xs={8}>
                <ServiceTable />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Divider sx={{ mt: '5%' }} />

                <FormControlLabel
                    label={
                        <Typography>
                            I agree to the Terms and Conditions, and Liability Agreement.
                        </Typography>
                    }
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            required
                        />
                    }
                    sx={{ alignSelf: 'center' }}
                />

                <LoadingButton
                    loading={loading}
                    variant="contained"
                    onClick={submitBooking}
                    disabled={!checked}
                    sx={{ width: '10%', mt: '1.5%', alignSelf: 'center' }}
                >
                    Book
                </LoadingButton>
            </Grid>
        </Grid>
    )
}
