import * as React from 'react'
import Paper from '@mui/material/Paper'
import { ArgumentAxis, ValueAxis, BarSeries, Chart, Title } from '@devexpress/dx-react-chart-material-ui'
import { ValueScale, Animation } from '@devexpress/dx-react-chart'
import { useState } from 'react'
import { allCarriersInfoT } from '../Util/constants.d'

interface carrierNumShipmentsT {
    carrier: string
    percentage: number
}

interface carrierShipPropsT {
    carrierInfo: allCarriersInfoT
}

export default function ShipmentTypePie(props: carrierShipPropsT) {
    const [chartData] = useState<carrierNumShipmentsT[]>([
        {
            carrier: 'CMA CGM',
            percentage:
                ((props.carrierInfo.CMA_CGM.numShipments - props.carrierInfo.CMA_CGM.delayedShipments) /
                    props.carrierInfo.CMA_CGM.numShipments) *
                100,
        },
        /*{
            carrier: 'COSCO',
            percentage:
                ((props.carrierInfo.COSCO.numShipments - props.carrierInfo.COSCO.delayedShipments) /
                    props.carrierInfo.COSCO.numShipments) *
                100,
        },*/
        {
            carrier: 'Evergreen',
            percentage:
                ((props.carrierInfo.Evergreen.numShipments -
                    props.carrierInfo.Evergreen.delayedShipments) /
                    props.carrierInfo.Evergreen.numShipments) *
                100,
        },
        /*{
            carrier: 'HMM',
            percentage:
                ((props.carrierInfo.HMM.numShipments - props.carrierInfo.HMM.delayedShipments) /
                    props.carrierInfo.HMM.numShipments) *
                100,
        },*/
        /*{
            carrier: 'Hapag_Lloyd',
            percentage:
                ((props.carrierInfo.Hapag_LLoyd.numShipments -
                    props.carrierInfo.Hapag_LLoyd.delayedShipments) /
                    props.carrierInfo.Hapag_LLoyd.numShipments) *
                100,
        },*/
        {
            carrier: 'MSC',
            percentage:
                ((props.carrierInfo.MSC.numShipments - props.carrierInfo.MSC.delayedShipments) /
                    props.carrierInfo.MSC.numShipments) *
                100,
        },
        {
            carrier: 'Maersk',
            percentage:
                ((props.carrierInfo.Maersk.numShipments - props.carrierInfo.Maersk.delayedShipments) /
                    props.carrierInfo.Maersk.numShipments) *
                100,
        },
        {
            carrier: 'ONE',
            percentage:
                ((props.carrierInfo.ONE.numShipments - props.carrierInfo.ONE.delayedShipments) /
                    props.carrierInfo.ONE.numShipments) *
                100,
        },
        {
            carrier: 'OOCL',
            percentage:
                ((props.carrierInfo.OOCL.numShipments - props.carrierInfo.CMA_CGM.delayedShipments) /
                    props.carrierInfo.OOCL.numShipments) *
                100,
        },
        /*{
            carrier: 'TS Lines',
            percentage:
                ((props.carrierInfo.TS_Lines.numShipments - props.carrierInfo.TS_Lines.delayedShipments) /
                    props.carrierInfo.TS_Lines.numShipments) *
                100,
        },*/
    ])
    return (
        <Paper sx={{ bgcolor: 'aliceblue', width: '60%' }}>
            <Chart data={chartData}>
                <ValueScale name="percentage" />

                <ArgumentAxis />
                <ValueAxis scaleName="percentage" showGrid={false} showLine={true} showTicks={true} />

                <BarSeries
                    name="onTimeDel"
                    valueField="percentage"
                    argumentField="carrier"
                    scaleName="percentage"
                />
                <Title text={'On-Time Delivery By Carrier (%)'} />
                <Animation />
            </Chart>
        </Paper>
    )
}
