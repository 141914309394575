import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import QuoteResults from './resultsPage'
import AdditionalServicesBox from '../Components/additionalServices'
import ManualBookInput from '../Components/adminComponents/manualBookInput'
import CustomerDetails from './invoicingDetails'
import PermanentDrawer from '../Components/permanentDrawer'
import ReviewBooking from './reviewBooking'
import OrderSummary from '../Components/orderSummary'
import { APP_HEADER_HEIGHT_PX, APP_SIDE_DRAWER_WIDTH_PX } from '../Util/constants'
import { Divider } from '@mui/material'
import { useAppContext } from '../Components/store'
import { ADMIN_UUID } from '../Util/adminFirebase'

export default function BookingStepper() {
    const appState = useAppContext()
    const isAdmin = appState[0].uuid === ADMIN_UUID

    let steps: string[]

    if (isAdmin) {
        steps = ['Select Route', 'Admin Options', 'Enter Information', 'Confirm Booking']
    } else {
        steps = ['Select Route', 'Enter Information', 'Confirm Booking']
    }

    const [activeStep, setActiveStep] = React.useState(0)
    const [skipped, setSkipped] = React.useState(new Set<number>())

    const isStepOptional = (step: number) => {
        return step === 1
    }

    const isStepSkipped = (step: number) => {
        return skipped.has(step)
    }

    const handleNext = () => {
        let newSkipped = skipped
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values())
            newSkipped.delete(activeStep)
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setSkipped(newSkipped)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.")
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values())
            newSkipped.add(activeStep)
            return newSkipped
        })
    }

    const handleReset = () => {
        setActiveStep(0)
    }

    return (
        <Container maxWidth="xl">
            <PermanentDrawer />
            <Grid
                container
                sx={{
                    mt: `calc(${APP_HEADER_HEIGHT_PX}px)`,
                    pl: `calc(${APP_SIDE_DRAWER_WIDTH_PX - 100}px)`,
                }}
                rowSpacing={5}
            >
                <Grid item xs={8}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {}
                            const labelProps: {
                                optional?: React.ReactNode
                            } = {}

                            if (isStepSkipped(index)) {
                                stepProps.completed = false
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            )
                        })}
                    </Stepper>
                </Grid>
                {activeStep === steps.length && (
                    <React.Fragment>
                        {/*TODO Display Confirmation  */}
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        {/* TODO Receipt Email/ Print Booking Confirmation */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                )}
                {activeStep === 0 && (
                    <Grid container maxWidth="xl">
                        <Grid item xs={8} sx={{ pb: '5%' }}>
                            <QuoteResults handleNext={handleNext} />
                        </Grid>
                        <Grid item xs={4} sx={{ pl: '5%' }}>
                            <AdditionalServicesBox />
                        </Grid>
                    </Grid>
                )}

                {activeStep === 1 && isAdmin && (
                    <>
                        <Grid container maxWidth="lg" sx={{ mt: '5vh' }}>
                            <Grid item xs={7}>
                                <ManualBookInput />
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button variant="contained" onClick={handleBack} sx={{ mr: 1 }}>
                                        Back
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleNext} variant="contained">
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Continue'}
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={5} sx={{ mt: '4vh', pl: '5vw' }}>
                                <OrderSummary />
                            </Grid>
                        </Grid>
                    </>
                )}
                {((activeStep === 1 && !isAdmin) || (activeStep == 2 && isAdmin)) && (
                    <>
                        <Grid container maxWidth="lg">
                            <Grid item xs={6}>
                                <CustomerDetails handleNext={handleNext} />
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button variant="contained" onClick={handleBack} sx={{ mr: 1 }}>
                                        Back
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sx={{ pl: '10vw', pt: '5vh' }}>
                                <OrderSummary />
                            </Grid>
                        </Grid>
                    </>
                )}
                {((activeStep === 2 && !isAdmin) || (activeStep == 3 && isAdmin)) && (
                    <Grid container maxWidth="lg">
                        <ReviewBooking />
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button variant="contained" onClick={handleBack} sx={{ mr: 1 }}>
                                Back
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Container>
    )
}
