import { Dispatch, SetStateAction, ReactNode, SyntheticEvent, useState } from 'react'
import { Tabs, Tab, Typography, Box } from '@mui/material'
import BookTable from './bookingsTable'
import InvoiceTable from './invoiceTable'

interface TabPanelProps {
    children?: ReactNode
    index: number
    value: number
}

interface TableType {
    type: string
}

interface uploadedDocT {
    docType: string
    uploadDate: string
    size: string
    downloadLink: string
    bookingNum: string
}

interface BookingsTableType extends TableType {
    uploadedDoc: uploadedDocT
    activeShipments: string[]
    delayedShipments: string[]
    completedShipments: string[]
    setTab: Dispatch<SetStateAction<number>>
    tab: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export function BookingsTableTabs(props: BookingsTableType) {
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        props.setTab(newValue)
    }
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 2, borderColor: 'navy' }}>
                <Tabs
                    value={props.tab}
                    onChange={handleChange}
                    aria-label="Documents"
                    sx={{ '& .MuiTabs-flexContainer': { flexWrap: 'wrap' } }}
                >
                    <Tab label="Active Shipments" {...a11yProps(0)} />
                    <Tab label="Completed Shipments" {...a11yProps(1)} />
                    <Tab label="Delayed Shipments" {...a11yProps(2)} />
                    <Tab label="All Shipments" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel value={props.tab} index={0}>
                <BookTable uploadedDoc={props.uploadedDoc} bookings={props.activeShipments} />
            </TabPanel>
            <TabPanel value={props.tab} index={1}>
                <BookTable uploadedDoc={props.uploadedDoc} bookings={props.completedShipments} />
            </TabPanel>
            <TabPanel value={props.tab} index={2}>
                <BookTable uploadedDoc={props.uploadedDoc} bookings={props.delayedShipments} />
            </TabPanel>
            <TabPanel value={props.tab} index={3}>
                <BookTable
                    uploadedDoc={props.uploadedDoc}
                    bookings={props.activeShipments.concat(props.completedShipments)}
                />
            </TabPanel>
        </Box>
    )
}

export default function TableTabs(props: TableType) {
    const [value, setValue] = useState(0)
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const type = props.type
    switch (type) {
        case 'invoices': {
            return (
                <Box sx={{ width: '83%%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', ml: 3 }}>
                        <Tabs value={value} onChange={handleChange} aria-label="Invoices">
                            <Tab label="Active Invoices" {...a11yProps(0)} />
                            <Tab label="Completed Invoices" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <InvoiceTable />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <InvoiceTable />
                    </TabPanel>
                </Box>
            )
        }
        default: {
            return <></>
        }
    }
}
