import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AccountAppBar from '../Components/resultsAppBar'
import Drawer from '../Components/permanentDrawer'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

const drawerWidth = 256
const appBarWidth = 90

export default function Help() {
    const [message, setMessage] = React.useState('')
    const [companyName, setCompanyName] = React.useState('')
    const [customerName, setCustomerName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [phoneNumber, setPhoneNumber] = React.useState('')

    return (
        <Box sx={{ position: 'relative', display: 'flex', maxWidth: `100vw`, height: '100vh' }}>
            <Drawer />

            <Box
                position="relative"
                sx={{
                    ml: `${drawerWidth}px`,
                    mt: `${appBarWidth}px`,
                    marginRight: '2.5%',
                    pl: '2.5%',
                    width: '83%',
                    display: 'block',
                    flexBasis: 'auto',
                }}
            >
                <Typography
                    sx={{
                        mt: 2,
                        fontFamily: 'calibri-light',
                        color: 'navy',
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}
                    variant="h4"
                >
                    Contact Us
                </Typography>

                <Box
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    component="form"
                >
                    <Stack direction="row">
                        <TextField
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                setCustomerName(event.target.value)
                            }
                            variant="outlined"
                            sx={{ bgcolor: 'aliceblue', mr: '10%', mt: '2.5%', width: '70%' }}
                            value={customerName}
                            id="customer-name-input"
                            label="Your Name"
                            defaultValue="Enter Your Name"
                            required
                        />
                        <TextField
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                setCompanyName(event.target.value)
                            }
                            variant="outlined"
                            sx={{ bgcolor: 'aliceblue', mr: '10%', mt: '2.5%', width: '70%' }}
                            value={companyName}
                            id="company-name-input"
                            label="Company Name"
                            defaultValue="Enter Company Name"
                            required
                        />
                    </Stack>
                    <Stack direction="row">
                        <TextField
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                setEmail(event.target.value)
                            }
                            variant="outlined"
                            sx={{ bgcolor: 'aliceblue', mr: '10%', mt: '2.5%', width: '70%' }}
                            value={email}
                            id="email-contact-input"
                            label="Email"
                            defaultValue="Enter Your Email"
                            required
                        />
                        <TextField
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                setPhoneNumber(event.target.value)
                            }
                            variant="outlined"
                            sx={{ bgcolor: 'aliceblue', mr: '10%', mt: '2.5%', width: '70%' }}
                            value={phoneNumber}
                            id="phone-number-input"
                            label="Phone Number"
                            defaultValue="Enter Phone Number"
                            required
                        />
                    </Stack>
                    <TextField
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setMessage(event.target.value)
                        }
                        variant="outlined"
                        sx={{ bgcolor: 'aliceblue', mr: '10%', mt: '2.5%', width: '90%' }}
                        value={message}
                        id="message-input"
                        label="Message"
                        multiline
                        rows={10}
                        defaultValue="Enter Message"
                        required
                    />
                    <Typography sx={{ mt: 2, fontFamily: 'calibri-light', color: 'navy' }} variant="h6">
                        Phone Number: (+92)345-4830000
                    </Typography>

                    <Typography
                        sx={{
                            mt: 2,
                            width: '50%',
                            mr: '50%',
                            fontFamily: 'calibri-light',
                            color: 'navy',
                        }}
                        variant="h6"
                    >
                        Email: <a href="mailto:info@transiport.com">info@transiport.com</a>
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            height: '5%',
                            width: '10%',
                            mt: '2%',
                            ml: '28%',
                            mb: '5%',
                        }}
                        //onClick={handleSubmit}
                    >
                        <Typography sx={{ fontSize: 20 }}>Submit</Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
