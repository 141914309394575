import { ChangeEvent, MouseEvent, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    Autocomplete,
    MenuItem,
    TextField,
    Typography,
    Divider,
    Toolbar,
    Stack,
    ToggleButtonGroup,
    ToggleButton,
    Grid,
    Container,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import DirectionsBoatFilledRoundedIcon from '@mui/icons-material/DirectionsBoatFilledRounded'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DesktopDatePicker'
import AirplanemodeActiveTwoToneIcon from '@mui/icons-material/AirplanemodeActiveTwoTone'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import PermanentDrawer from '../Components/permanentDrawer'
import { searchVoyages } from '../firebase'
import { ADMIN_UUID, useAuth } from '../Util/adminFirebase'
import { useAppContext, useCurrentBookingContext } from '../Components/store'

import {
    APP_HEADER_HEIGHT_PX,
    APP_SIDE_DRAWER_WIDTH_PX,
    CONTAINERS,
    EXPORT_INCOS,
    IMPORT_INCOS,
    INCOTERMS,
    PORTS,
    SUPPORTED_DESTINATION_PORTS,
    SUPPORTED_ORIGIN_PORTS,
} from '../Util/constants'

// DEV DEP
import { encode as base64_encode } from 'base-64'
import AdminDrawer from '../Components/adminComponents/adminDrawer'

const SearchPage = () => {
    const appState = useAppContext()

    let drawer: JSX.Element = null
    if (appState[0].uuid === ADMIN_UUID) {
        drawer = <AdminDrawer />
    } else {
        drawer = <PermanentDrawer />
    }
    const [currentBookingContext, setCurrentBookingContext] = useCurrentBookingContext()
    // state instantiate
    const [type, setType] = useState<string>('FCL')
    // weight
    const [weight, setWeight] = useState<string>('')
    //volume
    const [volume, setVolume] = useState<string>('')
    // src
    const [origin, setOrigin] = useState<string>('')
    // dst
    const [dest, setDest] = useState<string>('')
    //cust location
    const [custLocation, setCustLocation] = useState<string>('')
    //date
    const [date, setDate] = useState<Date | null>(null)
    // import/export
    const [trade, setTrade] = useState<string>('Import')
    // cargo type
    const [cargoType, setCargoType] = useState<string>('General')
    // container
    const [container, setContainer] = useState<string>('')
    //quantity
    const [quantity, setQuantity] = useState<string>('')
    // incoterm
    const [inco, setInco] = useState<string>('')
    //custShipmentType
    const [custShipType, setCustShipType] = useState<string>('')
    // is data loading
    const [loading, setLoading] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string>('')

    const navigate = useNavigate()
    useAuth(appState)

    useEffect(() => {
        if (appState && !appState[0].loading && !appState[0].uuid) {
            navigate('/')
        }
    }, [appState, navigate])

    const handleType = (event: MouseEvent<HTMLElement>, newType: string | null) => {
        if (newType !== null) {
            setType(newType)
        }
    }

    const handleTrade = (event: MouseEvent<HTMLElement>, newTrade: string | null) => {
        if (newTrade !== null) {
            setTrade(newTrade)
        }
    }

    const handleCargoType = (event: MouseEvent<HTMLElement>, newCargoType: string | null) => {
        if (newCargoType !== null) {
            setCargoType(newCargoType)
        }
    }

    const handleSubmit = async () => {
        let dataValid = false
        switch (type) {
            case 'FCL':
                dataValid = !!(origin && trade && dest && date && weight && inco && container && quantity)
                break
            case 'LCL':
                dataValid = !!(origin && trade && dest && date && weight && inco && volume && quantity)
                break
            case 'Customs':
                dataValid = !!(custShipType && custLocation && trade && quantity)
                break
            default:
                console.log(`Unrecognized type: ${type}`)
                break
        }
        if (!dataValid) {
            setErrMsg('Please fill out all the fields')
            return
        }
        // Search for voyages
        setErrMsg('')
        setLoading(true)
        try {
            // const docSnapshot = await searchVoyages(origin)
            // const data = docSnapshot.data()
            // //addVoyage("Karachi",date)

            // TODO: partner rates move to Cloud Function aggregator
            const partnerRates = []
            let rates = await getRatesFreightify()
            if (rates && rates.offers) {
                rates['offers'].push(...partnerRates)
            } else if (partnerRates) {
                rates = { reqId: '1', offers: partnerRates }
            }
            navigate('/results', { state: { searchRes: rates } })
        } catch (error) {
            setErrMsg(error.message)
            setLoading(false)
        }
        setLoading(false)
    }

    const renderErrMsg = () => {
        if (errMsg) {
            return <Typography sx={{ fontSize: 16, color: 'red', ml: '34%' }}>{errMsg}</Typography>
        }
    }

    // Temporary Customs Results
    // TODO FIX
    const handleCustoms = () => {
        const dataValid = !!(custShipType && custLocation && trade && quantity && date)
        if (!dataValid) {
            setErrMsg('Please fill out all the fields')
            return
        }
        const newBookingContext = {
            ...currentBookingContext,
            arrival_date: date.toISOString().split('T')[0],
            booking_type: custShipType,
            dest: custLocation,
            quantity: quantity,
            trade: trade,
            customs_price_usd: 200,
            total_price_usd: 200,
            customs_only: true,
            num_containers: quantity,
            cargo_type: cargoType,
            container_type: container,
        }

        setCurrentBookingContext(newBookingContext)
        navigate('/custResults')
    }

    /* ============ TEMP FREIGHTIFTY HOOKS ================= */
    interface oceanPortT {
        country: string
        name: string
        province: string
        unlocs: string
        type: string
    }
    const API_KEY = 'DcqfdImGBq8SxufuXFvgG8r3bR2ccHFddYliCBsg'
    // const customerID = '012f3de0-ebcd-11ec-85c5-af1878ed6da4'
    // const clientSecret = 'f7gzglfIBsBDL04dLUdi9z5fM1ahopnPk3hQ8YW3'
    // const freightifyUsername = 'apilink@freightify.com'
    // const freightifyPassword = 'Alpha@123'
    const ACCESS_TOKEN =
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjFmMmZmYjA4N2Q0NTI2MmI0YTJlZjA0ZjQwNTE2MDRmYmRiODAzYjc4ZDkyYzQxYjMyMjYzNGZhMTgwMjdhMDBhZWQ0YmM3YmFhODYwOTRiIn0.eyJhdWQiOiIxMCIsImp0aSI6IjFmMmZmYjA4N2Q0NTI2MmI0YTJlZjA0ZjQwNTE2MDRmYmRiODAzYjc4ZDkyYzQxYjMyMjYzNGZhMTgwMjdhMDBhZWQ0YmM3YmFhODYwOTRiIiwiaWF0IjoxNjY2MDI0OTU4LCJuYmYiOjE2NjYwMjQ5NTgsImV4cCI6MTY2ODYxNjk1OCwic3ViIjoiMjhhZTBhZDAtOTQ3NS0xMWVjLWE5OGMtNjkwOTMxY2U4NDllIiwic2NvcGVzIjpbXSwicGVybWlzc2lvbnMiOnsiSU5TVEFOVF9SQVRFUyI6IjExMTEiLCJJTlNUQU5UX1JBVEVTX0ZDTCI6IjExMTEiLCJJTlNUQU5UX1JBVEVTX0xDTCI6IjExMTEiLCJJTlNUQU5UX1JBVEVTX0FJUiI6IjExMTEiLCJTSElQTUVOVFMiOiIxMTExIn0sImVtYWlsIjoiYXBpbGlua0BmcmVpZ2h0aWZ5LmNvbSIsInRyaWFsRXhwaXJ5IjoiMjAyMy0wNi0xNCAxNjowMDoyNiIsImRvbWFpbiI6InRyYW5zaXBvcnQuZnJlaWdodGlmeS5jb20iLCJ2ZW5kb3JJZCI6MTkyMSwiYXBpS2V5IjoiRGNxZmRJbUdCcThTeHVmdVhGdmdHOHIzYlIyY2NIRmRkWWxpQ0JzZyIsInVzZXJSb2xlIjoiVVNFUiIsInVzZXJSb2xlVHlwZSI6IkZPUldBUkRFUiIsInN1YnZlbmRvcklkIjowLCJlbnZpcm9ubWVudCI6IlByb2R1Y3Rpb24iLCJ1c2VyQ29tcGFueSI6ImJjOWQ4MWMwLTkzYWItMTFlYy04YWRlLTk5NTAzOGVkOTIyMCJ9.N30mX_UMZK7kJL1P4iZCqXVFPCueqRR2XUmQ8RcHhN2mx-BmoHAp8aLruj7-i2CfA0CSXkiVXjpcSyoYvOb8AmArV5e4P9rPL45EQQty1pdaj_tvjSZWoAm9Q4gg271d1e2xT33qKirstY1D8wjVphJ1eGddY6VoyJmGlWl5pUNQyW5J_05_sfjl-vyian3EeFTAeOVS0jdspHjiK_xQrVdcSez2Wk4YtRG5p-u3QmIBdWVkPhLERZ6o2-xpmVePDRhFwM3Vli5XXDsPOF_I5RDKoiF57LU3vG1tSgzS-P-PD9PuVEU6nOe7IaHaAgW2C77tVlIkBv_XaIFvCdRu7Mct3jd4t6ZlN3wAV23pjnogZ4Gm0SRBsflXPZKP_XKHjVF9HoTn8lGhGg_gPhrNM7xtftlGBvRc0u-LAdHhOznmOjuMBkojwH0A1wnK-qOZHheS9tSL-VMPznqvs_ODuVziNUgDT2eC014ZhvwHh94-PL_IaaezGelAp8L37UuQDprPcEflStGA4keVdblZDFpXAvMnRpoGmslN0p585DqPFctyELt5WzA-iJQmbu5NJDbAHapA44HG4UJXBh_XKNfWUcYVc3v9s3_x26_o0q1DTh_CcPVeMsiDJZR9iqx87JeIUpVSn9Y7e1J4yFIYoUhZEOkKbBzhAiyvo0WONLw'

    // const createAccessToken = async () => {
    //     const data = await fetch(
    //         'https://asia-south1-transiport-service-account.cloudfunctions.net/send-cors-req',
    //         {
    //             method: 'POST',
    //             body: JSON.stringify({
    //                 endpoint: 'https://api.freightify.com/oauth2/token',
    //                 method: 'POST',
    //                 body: {
    //                     username: freightifyUsername,
    //                     password: freightifyPassword,
    //                     grant_type: 'password',
    //                     scope: '',
    //                 },
    //                 header: {
    //                     Authorization: 'Basic ' + base64_encode(customerID + ':' + clientSecret),
    //                     'Content-Type': 'application/x-www-form-urlencoded',
    //                     Accept: '*/*',
    //                 },
    //             }),
    //         },
    //     )
    //     const dataJSON = await data.json()
    //     return dataJSON
    // }

    // const getAccessToken = async () => {
    //     // const EXPIRE_TIME = 2 /*hrs*/ * 60 /*min*/ * 60 /*seconds*/ * 1000 /*ms*/
    //     const EXPIRE_TIME = 20 /*min*/ * 60 /*seconds*/ * 1000 /*ms*/
    //     let accessToken = localStorage.getItem('accessToken')
    //     if (accessToken) {
    //         // check if recent
    //         const accessTokenAge = localStorage.getItem('accessTokenAge')
    //         if (accessTokenAge && new Date().getTime() - accessTokenAge < EXPIRE_TIME) {
    //             return accessToken
    //         }
    //         // TODO: check for refresh token, and refresh
    //     }
    //     // token not available, get a new one
    //     const accessTokenData = await createAccessToken()
    //     // store token and metadata in local storage
    //     accessToken = accessTokenData.access_token
    //     if (accessToken) {
    //         localStorage.setItem('accessToken', accessToken)
    //         localStorage.setItem('accessTokenAge', new Date().getTime())
    //         localStorage.setItem('refreshToken', accessTokenData.refresh_token)
    //         return accessToken
    //     }
    //     // no access token
    //     return null
    // }

    const formatContainerQueryStr = () => {
        let containerQueryStr = ''
        let containerType = ''
        switch (container) {
            case "20' Dry Standard":
                containerType = '20GP'
                break
            case "40' Dry Standard":
                containerType = '40GP'
                break
            case "40' Dry High":
                containerType = '40HC'
                break
        }
        if (quantity > 0 && containerType && weight) {
            containerQueryStr = `${quantity}X${containerType}X${weight}XKGs`
        } else {
            setErrMsg(
                'Invalid container data (most likely container type; ' +
                    'only supporting: 20/40 Dry Standard and 40 Dry High',
            )
        }
        return containerQueryStr
    }

    // Check if date valid and convert to ISO format
    const getISODate = (date) => {
        // check if date valid
        let valid = true
        const currDate = new Date()
        const isYearGreater = date.getFullYear() > currDate.getFullYear()
        if (!isYearGreater) {
            valid &= currDate.getFullYear() == date.getFullYear()
            const isMonthGreater = date.getMonth() > currDate.getMonth()
            if (!isMonthGreater) {
                valid &= date.getMonth() == currDate.getMonth()
                valid &= date.getDate() >= currDate.getDate()
            }
        }
        let month = (date.getMonth() + 1).toString()
        let day = date.getDate().toString()
        if (month.length == 1) {
            month = '0' + month
        }
        if (day.length == 1) {
            day = '0' + day
        }
        return valid ? `${date.getFullYear()}-${month}-${day}` : null
    }

    const getRatesFreightify = async () => {
        // ISO date format is YYYY-MM-DDT...
        const isoDate = getISODate(date)
        const containerQuery = formatContainerQueryStr()
        if (!containerQuery || !isoDate) {
            setErrMsg('Invalid date or bad container params.')
            return
        }
        // const accessToken = await getAccessToken()
        const accessToken = ACCESS_TOKEN
        if (!accessToken) {
            setErrMsg('Something went wrong. Try again.')
            return
        }
        const data = await fetch(
            'https://asia-south1-transiport-service-account.cloudfunctions.net/send-cors-req',
            {
                method: 'POST',
                body: JSON.stringify({
                    endpoint:
                        'https://api.freightify.com/v1/prices?' +
                        new URLSearchParams({
                            originPort: origin,
                            destinationPort: dest,
                            departureDate: isoDate,
                            mode: 'FCL',
                            containers: containerQuery,
                        }),
                    method: 'GET',
                    header: {
                        Authorization: 'Bearer ' + accessToken,
                        Accept: '*/*',
                        'x-api-key': API_KEY,
                    },
                }),
            },
        )
        const dataJSON = await data.json()
        return dataJSON
    }
    /* =================== ================== */

    return (
        <Container maxWidth="xl">
            {drawer}

            <Grid
                container
                sx={{
                    pl: { xs: '10vw', sm: `${APP_SIDE_DRAWER_WIDTH_PX}px` },
                    mt: `${APP_HEADER_HEIGHT_PX}px`,
                }}
                component="form"
                noValidate
                autoComplete="off"
            >
                <Grid
                    item
                    xs={8}
                    sx={{
                        justifyContent: 'center',
                    }}
                >
                    <Grid item xs={12} sm={12}>
                        <Typography
                            sx={{
                                borderBottom: 1,
                                borderColor: 'divider',
                                color: 'navy',
                                fontSize: '4vh',
                                mt: '2vh',
                            }}
                        >
                            Search Rate
                        </Typography>
                    </Grid>
                    {type !== 'Customs' && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Divider textAlign="center">
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            color: 'navy',
                                            fontFamily: 'calibri',
                                            fontWeight: 'bold',
                                            fontSize: 20,
                                        }}
                                        display="block"
                                    >
                                        Route
                                    </Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    freeSolo
                                    value={PORTS[0]}
                                    options={PORTS}
                                    getOptionLabel={(option: oceanPortT) => {
                                        if (option.unlocs && option.type == 'port') {
                                            const label = `${option.unlocs} (${option.name}, ${option.country})`
                                            return label
                                        }
                                        return ''
                                    }}
                                    onChange={(
                                        event: ChangeEvent<HTMLInputElement>,
                                        selectedVal: string | null,
                                        //reason,
                                        //details,
                                    ) => {
                                        if (selectedVal) {
                                            setOrigin(selectedVal.unlocs)
                                        }
                                    }}
                                    renderInput={(params) => {
                                        return <TextField {...params} label="Origin" fullWidth required />
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    freeSolo
                                    value={PORTS[0]}
                                    options={PORTS}
                                    autoHighlight
                                    getOptionLabel={(option: oceanPortT) => {
                                        if (option.unlocs && option.type == 'port') {
                                            const label = `${option.unlocs} (${option.name}, ${option.country})`
                                            return label
                                        }
                                        return ''
                                    }}
                                    onChange={(
                                        event: ChangeEvent<HTMLInputElement>,
                                        selectedVal: string | null,
                                    ) => {
                                        if (selectedVal) {
                                            if (selectedVal.unlocs == 'PKBQM') {
                                                setDest('PKQCT')
                                            } else {
                                                setDest(selectedVal.unlocs)
                                            }
                                        }
                                    }}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                label="Destination"
                                                fullWidth
                                                required
                                            />
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <DatePicker
                                            label="ETD"
                                            value={date}
                                            minDate={new Date()}
                                            maxDate={
                                                new Date(
                                                    Date.now() +
                                                        1000 /*sec*/ *
                                                            60 /*min*/ *
                                                            60 /*hour*/ *
                                                            24 /*day*/ *
                                                            30,
                                                )
                                            }
                                            onChange={setDate}
                                            renderInput={(params) => <TextField required {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    freeSolo
                                    id="incoterm-select"
                                    options={trade === 'Export' ? EXPORT_INCOS : IMPORT_INCOS}
                                    value={trade === 'Export' ? EXPORT_INCOS[0] : IMPORT_INCOS[0]}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Incoterm" required fullWidth />
                                    )}
                                    onChange={(
                                        event: ChangeEvent<HTMLInputElement>,
                                        selectedVal: string | null,
                                    ) => {
                                        if (selectedVal) {
                                            setInco(selectedVal)
                                        }
                                    }}
                                    getOptionDisabled={
                                        trade === 'Export'
                                            ? (option) =>
                                                  option === EXPORT_INCOS[1] ||
                                                  option === EXPORT_INCOS[2] ||
                                                  option === EXPORT_INCOS[3] ||
                                                  option === EXPORT_INCOS[4] ||
                                                  option === EXPORT_INCOS[5] ||
                                                  option === EXPORT_INCOS[6] ||
                                                  option === EXPORT_INCOS[7] ||
                                                  option === EXPORT_INCOS[8]
                                            : (option) =>
                                                  option === IMPORT_INCOS[3] || option === IMPORT_INCOS[4]
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <ToggleButtonGroup
                                    orientation="horizontal"
                                    value={trade}
                                    exclusive
                                    onChange={handleTrade}
                                    fullWidth
                                >
                                    <ToggleButton value="Import" color="primary">
                                        Import
                                    </ToggleButton>
                                    <ToggleButton value="Export" color="primary">
                                        Export
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: '2.5vh' }}>
                                <ToggleButtonGroup
                                    orientation="horizontal"
                                    value={cargoType}
                                    exclusive
                                    onChange={handleCargoType}
                                    fullWidth
                                >
                                    <ToggleButton value="General" color="primary">
                                        General
                                    </ToggleButton>
                                    <ToggleButton value="Reefer" color="primary" disabled>
                                        Reefer
                                    </ToggleButton>
                                    <ToggleButton value="Dangerous" disabled color="primary">
                                        Dangerous
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider textAlign="center">
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            color: 'navy',
                                            fontFamily: 'calibri',
                                            fontWeight: 'bold',
                                            fontSize: 20,
                                        }}
                                        display="block"
                                    >
                                        Container Details
                                    </Typography>
                                </Divider>
                            </Grid>

                            {type === 'FCL' && (
                                <Grid item xs={12}>
                                    <TextField
                                        id="container-select"
                                        select
                                        label="Container"
                                        value={container}
                                        fullWidth
                                        required
                                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                            setContainer(event.target.value)
                                        }
                                    >
                                        {CONTAINERS.map((option, idx) => (
                                            <MenuItem key={idx} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <TextField
                                    id="container-quantity"
                                    label="Quantity"
                                    type="number"
                                    value={quantity}
                                    fullWidth
                                    required
                                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                        setQuantity(event.target.value)
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                        setWeight(event.target.value)
                                    }
                                    value={weight}
                                    id="weight-input"
                                    label="Weight in kg"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            {(type === 'LCL' || type === 'Air') && (
                                <Grid item xs={12}>
                                    <TextField
                                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                            setVolume(event.target.value)
                                        }
                                        value={volume}
                                        id="volume-input"
                                        label="Volume in cbm"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            )}
                        </Grid>
                    )}
                    {type === 'Customs' && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Divider textAlign="center">
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            color: 'navy',
                                            fontFamily: 'calibri',
                                            fontWeight: 'bold',
                                            fontSize: 20,
                                        }}
                                        display="block"
                                    >
                                        Customs Type
                                    </Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <ToggleButtonGroup
                                        orientation="horizontal"
                                        value={trade}
                                        exclusive
                                        fullWidth
                                        onChange={handleTrade}
                                    >
                                        <ToggleButton value="Export" color="primary">
                                            Origin
                                        </ToggleButton>
                                        <ToggleButton value="Import" color="primary">
                                            Destination
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <DatePicker
                                            label="ETA"
                                            value={date}
                                            onChange={setDate}
                                            renderInput={(params) => <TextField required {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="shipment-type-select"
                                    select
                                    label="Type"
                                    value={custShipType}
                                    fullWidth
                                    required
                                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                        setCustShipType(event.target.value)
                                    }
                                >
                                    <MenuItem value="FCL">FCL</MenuItem>
                                    <MenuItem value="LCL">LCL</MenuItem>
                                    <MenuItem value="Air">Air</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider textAlign="center">
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            color: 'navy',
                                            fontFamily: 'calibri',
                                            fontWeight: 'bold',
                                            fontSize: 20,
                                        }}
                                        display="block"
                                    >
                                        Customs Location
                                    </Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="source-select"
                                    select
                                    label="Customs Location"
                                    value={custLocation}
                                    fullWidth
                                    required
                                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                        setCustLocation(event.target.value)
                                    }
                                >
                                    {SUPPORTED_DESTINATION_PORTS.map((option, idx) => (
                                        <MenuItem key={idx} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider textAlign="center">
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            color: 'navy',
                                            fontFamily: 'calibri',
                                            fontWeight: 'bold',
                                            fontSize: 20,
                                        }}
                                        display="block"
                                    >
                                        Container Details
                                    </Typography>
                                </Divider>
                            </Grid>
                            {custShipType === 'FCL' && (
                                <Grid item xs={12}>
                                    <TextField
                                        id="container-select"
                                        select
                                        label="Container"
                                        value={container}
                                        fullWidth
                                        required
                                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                            setContainer(event.target.value)
                                        }
                                    >
                                        {CONTAINERS.map((option, idx) => (
                                            <MenuItem key={idx} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <TextField
                                    id="container-quantity"
                                    label="Quantity"
                                    type="number"
                                    value={quantity}
                                    fullWidth
                                    required
                                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                        setQuantity(event.target.value)
                                    }
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12} sx={{ display: 'grid', mt: '4vh', justifyContent: 'center' }}>
                        <LoadingButton
                            sx={{ '& .MuiLoadingButton-loading': { color: 'primary' } }}
                            loading={loading}
                            variant="contained"
                            onClick={type === 'Customs' ? handleCustoms : handleSubmit}
                        >
                            <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>Search</Typography>
                            <ArrowForwardIosIcon sx={{ fontSize: 20, fontWeight: 'bold' }} />
                        </LoadingButton>
                    </Grid>

                    {renderErrMsg()}
                </Grid>
                <Grid item xs={4}>
                    <Toolbar
                        sx={{
                            position: 'relative',
                            //maxWidth: 'fit-content',
                            marginLeft: '20vh',
                            //mt: '8%',
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={3}
                            sx={{
                                justifyContent: 'space-around',
                                //height: '35vh',
                                mt: '80px',
                                display: 'block',
                                background: 'aliceblue',
                                //border: '3px solid navy',
                                //borderRadius: '15px'
                            }}
                        >
                            <ToggleButtonGroup
                                orientation="vertical"
                                value={type}
                                sx={{ height: 'fit-content' }}
                                color="primary"
                                exclusive
                                onChange={handleType}
                            >
                                <ToggleButton
                                    value="FCL"
                                    sx={{
                                        color: 'navy',
                                        fontWeight: 'bold',
                                        border: '3px solid navy',
                                        borderBottom: '1px solid navy',
                                        borderRadius: '15px',
                                    }}
                                >
                                    <DirectionsBoatFilledRoundedIcon />
                                    FCL
                                </ToggleButton>
                                <ToggleButton
                                    value="LCL"
                                    sx={{
                                        color: 'navy',
                                        fontWeight: 'bold',
                                        border: '1px solid navy',
                                        borderRight: '3px solid navy',
                                        borderLeft: '3px solid navy',
                                        borderRadius: '15px',
                                    }}
                                >
                                    <Inventory2OutlinedIcon />
                                    LCL
                                </ToggleButton>
                                <ToggleButton
                                    value="Customs"
                                    sx={{
                                        color: 'navy',
                                        fontWeight: 'bold',
                                        border: '1px solid navy',
                                        borderRight: '3px solid navy',
                                        borderLeft: '3px solid navy',
                                        borderRadius: '15px',
                                    }}
                                >
                                    <TopicOutlinedIcon />
                                    Customs
                                </ToggleButton>
                                <ToggleButton
                                    value="Air"
                                    sx={{
                                        color: 'navy',
                                        fontWeight: 'bold',
                                        border: '3px solid navy',
                                        borderRadius: '15px',
                                    }}
                                >
                                    <AirplanemodeActiveTwoToneIcon />
                                    Air
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Stack>
                    </Toolbar>
                </Grid>
            </Grid>
        </Container>
    )
}
export default SearchPage
