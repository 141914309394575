import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui'
import { useState } from 'react'
import { getRateSheetURLs } from '../../../Util/adminFirebase'
import { LoadingWheel } from '../../loadingWheel'
import RateSheetTable from './rateSheetTable'
import { PARTNERS_TABLE_COLUMNS } from '../adminConstants'
import { GenericTable } from './genericTable'
import { PartnersPopup } from '../adminPopups/partnerPopup'
// eslint-disable-next-line
/* eslint-disable no-shadow */

export default function PartnersTable(props) {
    const getRowId = (row) => row.id

    const [rows] = useState(props.rows ?? [])
    const [partnersDataLoading, setPartnersDataLoading] = useState({})
    const [partnersRateSheetURLs, setPartnersRateSheetURLs] = useState({})

    const RowDetail = ({ row }) => {
        if (partnersDataLoading[row.id]) {
            return <LoadingWheel />
        } else {
            // generate table of ratesheets
            return <RateSheetTable rows={partnersRateSheetURLs[row.id]} partnerID={row.id} />
        }
    }

    const ToggleCell = ({ expanded, onToggle, row, ...restProps }) => (
        <TableRowDetail.ToggleCell
            {...restProps}
            expanded={expanded}
            row={row}
            onToggle={() => {
                // partner rate sheet urls download
                setPartnersDataLoading({ ...partnersDataLoading, [row.id]: true })
                try {
                    console.log('XXX: ', row)
                    getRateSheetURLs(row.id, row.active_sheets)
                        .then((data) => {
                            const partnerSheets = data.map((val) => ({
                                rate_sheet: val,
                                upload_date: '',
                            }))
                            setPartnersRateSheetURLs({
                                ...partnersRateSheetURLs,
                                [row.id]: partnerSheets,
                            })
                            setPartnersDataLoading({ ...partnersDataLoading, [row.id]: false })
                        })
                        .catch((err) => {
                            console.log(err)
                            setPartnersDataLoading({ ...partnersDataLoading, [row.id]: false })
                        })
                } catch (err) {
                    console.log('Failed to get partner ratesheet URLs', err)
                    setPartnersDataLoading({ ...partnersDataLoading, [row.id]: false })
                }
                onToggle()
            }}
        />
    )

    return (
        <GenericTable
            columns={PARTNERS_TABLE_COLUMNS}
            getRowId={getRowId}
            title={'PARTNERS'}
            popup={PartnersPopup}
            rows={rows}
            toggleCell={ToggleCell}
            toggleRow={RowDetail}
        />
    )
}
