import { Box, List, ListItemText, ListItemButton, Button } from '@mui/material'
import { Link as RouterLink, LinkProps as RouterLinkProps, useNavigate } from 'react-router-dom'
import React, { ReactElement, useEffect, useState } from 'react'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import DirectionsBoatOutlinedIcon from '@mui/icons-material/DirectionsBoatOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import Logo from '../Assets/transiport-logo2.png'
import SearchIcon from '@mui/icons-material/Search'
import { useAppContext } from './store'
import WhatsappFeature from './whatsappFeature'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { getCurrentUser, signout } from '../Util/adminFirebase'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
    backgroundColor: 'aliceblue',
    width: drawerWidth,
    '& .MuiListItemText-primary': { fontWeight: 'bold', color: 'navy' },
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    backgroundColor: 'aliceblue',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

export default function MiniDrawer() {
    const navigate = useNavigate()

    // TODO: find a more elegant solution here
    const [oldUuid, setOldUuid] = useState<string>('')
    const appState = useAppContext()

    // set old uid after component mounted
    useEffect(() => {
        setOldUuid(appState[0].uuid)
    }, [])

    const handleSignout = async () => {
        try {
            // setState is async so theoretically can return after signout
            appState[1]({ ...appState[0], uuid: '', loading: true })
            await signout()
            appState[1]({ ...appState[0], uuid: '', loading: false })
            navigate('/')
        } catch (error) {
            if (getCurrentUser().uid == oldUuid) {
                appState[1]({ ...appState[0], uuid: oldUuid, loading: false })
            }
            console.log(error)
        }
    }

    const theme = useTheme()
    const [open, setOpen] = React.useState(false)

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar sx={{ bgcolor: 'white' }}>
                    <IconButton
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                            color: 'navy',
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Button onClick={() => navigate('/')}>
                        <Typography variant="h6" noWrap component="div">
                            <img src={Logo} alt="Logo" height={60} />
                        </Typography>
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} sx={{ bgcolor: '#04012b' }}>
                <DrawerHeader>
                    <IconButton sx={{ color: 'navy' }} onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem disablePadding sx={{ display: 'block', fontWeight: 'bold', color: 'navy' }}>
                        <ListItemButton
                            onClick={() => {
                                navigate('/' + 'Search'.toLowerCase())
                            }}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                fontWeight: 'bold',
                                color: 'navy',
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: 'navy',
                                }}
                            >
                                <SearchIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Search'} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            onClick={() => {
                                navigate('/' + 'Bookings'.toLowerCase())
                            }}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: 'navy',
                                }}
                            >
                                <DirectionsBoatOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Bookings'} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            onClick={() => {
                                navigate('/' + 'Track'.toLowerCase())
                            }}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: 'navy',
                                }}
                            >
                                <LocationOnOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Track'} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            onClick={() => {
                                navigate('/' + 'Analytics'.toLowerCase())
                            }}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: 'navy',
                                }}
                            >
                                <BarChartOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Analytics'} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            onClick={() => {
                                navigate('/' + 'Financials'.toLowerCase())
                            }}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: 'navy',
                                }}
                            >
                                <AttachMoneyOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Financials'} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            onClick={() => {
                                navigate('/' + 'Settings'.toLowerCase())
                            }}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: 'navy',
                                }}
                            >
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Settings'} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            onClick={() => {
                                navigate('/' + 'Help'.toLowerCase())
                            }}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: 'navy',
                                }}
                            >
                                <HelpOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Help'} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            onClick={handleSignout}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: 'navy',
                                }}
                            >
                                <LogoutOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Logout'} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    {/* <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <WhatsappFeature />
                            </ListItemIcon>
                            <ListItemText primary={'Whatsapp'} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem> */}
                </List>
            </Drawer>
        </Box>
    )
}
