import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Typography,
    Divider,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material'
import { useCurrentBookingContext } from './store'

//TODO Automatically generate table by passing
// details from the selected route

export default function ReviewOrder() {
    const [currentBookingContext] = useCurrentBookingContext()
    return (
        <Grid
            component={Paper}
            elevation={3}
            container
            sx={{ border: (theme) => `1px solid ${theme.palette.divider}`, borderRadius: '15px' }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    bgcolor: '#F5F5F5',
                }}
            >
                <Typography sx={{ fontWeight: 'bold', pl: '2%', color: 'navy' }}>
                    Invoicing Details
                </Typography>
            </Grid>

            <Grid item xs={6}>
                <TableContainer
                    sx={{
                        bgcolor: '#F5F5F5',
                        color: 'navy',
                        fontWeight: 'bold',
                        height: '100%',
                    }}
                >
                    <Table size="small" aria-label="service table">
                        <TableBody>
                            <TableRow sx={{ '& td': { border: 0 } }}>
                                <TableCell variant="head" sx={{ color: 'gray' }}>
                                    CNEE Name
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    {currentBookingContext.consignee_details.companyName}
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '& td': { border: 0 } }}>
                                <TableCell variant="head" sx={{ color: 'gray' }}>
                                    CNEE Address{' '}
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    {currentBookingContext.consignee_details.address}
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '& td': { border: 0 } }}>
                                <TableCell variant="head" sx={{ color: 'gray' }}>
                                    CNEE Email
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    {currentBookingContext.consignee_details.emailAddr}
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '& td': { border: 0 } }}>
                                <TableCell variant="head" sx={{ color: 'gray' }}>
                                    CNEE Phone
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    {currentBookingContext.consignee_details.phoneNo}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={6}>
                <TableContainer
                    sx={{
                        bgcolor: '#F5F5F5',
                        color: 'navy',
                        fontWeight: 'bold',
                        height: '100%',
                    }}
                >
                    <Table size="small" aria-label="service table">
                        <TableBody>
                            <TableRow sx={{ '& td': { border: 0 } }}>
                                <TableCell variant="head" sx={{ color: 'gray' }}>
                                    Supplier Name
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    {currentBookingContext.shipper_details.companyName}
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '& td': { border: 0 } }}>
                                <TableCell variant="head" sx={{ color: 'gray' }}>
                                    Supplier Address{' '}
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    {currentBookingContext.shipper_details.address}
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '& td': { border: 0 } }}>
                                <TableCell variant="head" sx={{ color: 'gray' }}>
                                    Supplier Email
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    {currentBookingContext.shipper_details.emailAddr}
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ '& td': { border: 0 } }}>
                                <TableCell variant="head" sx={{ color: 'gray' }}>
                                    Supplier Phone #
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    {currentBookingContext.shipper_details.phoneNo}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}
