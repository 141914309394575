import MuiGrid from '@mui/material/Grid'
import { BookingFinance, BookingOverview } from '../adminForms/reusableForms'

export default function FinanceForm({ row, onChange }) {
    return (
        <MuiGrid container spacing={3}>
            <MuiGrid item xs={12}>
                <BookingOverview onChange={onChange} row={row} />
                <BookingFinance onChange={onChange} row={row} />
            </MuiGrid>
        </MuiGrid>
    )
}
