/*
	Add functions and constants that are used by multiple components
*/

import * as utilTypes from './util.d'
import * as searchResInterfaces from '../Components/resultInterface'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

export const parseCosts = (charges: searchResInterfaces.Charge[]): utilTypes.Costs => {
    const costs: utilTypes.Costs = []
    charges.forEach((charge) => {
        costs.push([charge.description, charge.amountUsd])
    })
    return costs
}

const isValidDocPath = (path: string): boolean => {
    // We expect path to be formatted as:
    // collection_1/doc_1/collection_2/doc_2/.../collection_k/doc_k
    if (!path || path.startsWith('/')) {
        return false
    }
    const splitPath = path.split('/')
    return splitPath.length % 2 == 0
}

// method will return '' if no valid docName
export const getDocFromPath = (path: string): string => {
    if (!isValidDocPath(path)) {
        return ''
    }
    const splitPath = path.split('/')
    return splitPath[splitPath.length - 1]
}

// method will return '' if not valid path
export const getDeepestCollectionFromPath = (path: string): string => {
    if (!path || path.startsWith('/')) {
        return ''
    }
    const splitPath = path.split('/')
    if (splitPath.length == 1) {
        return path
    }
    return splitPath[splitPath.length - 2]
}

export const useScrollToTop = () => {
    const location = useLocation()
    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [location])
}
