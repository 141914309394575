import { Box, CircularProgress, Grid, Tab, Tabs, Typography } from '@mui/material'
import Drawer from '../Components/permanentDrawer'
import PaymentDetails from '../Components/payDetailsAccordion'
import { getUserFinanceData, useAuth } from '../Util/adminFirebase'
import { useAppContext } from '../Components/store'
import { SyntheticEvent, useEffect, useState, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import InvoiceTable from '../Components/invoiceTable'
import { APP_HEADER_HEIGHT_PX, APP_SIDE_DRAWER_WIDTH_PX } from '../Util/constants'
interface bookingDuesT {
    balanceDue: string
    invoiceNum: string
    totalAmount: string
    bookingNo: string
}

interface TabPanelProps {
    children?: ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const FinancePage = () => {
    const [value, setValue] = useState(0)
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }
    const [loading, setLoading] = useState<boolean>(true)
    // Total value of services/goods: all paid and upaid combined
    const [, setTotalAmount] = useState<string>(null)
    const [, setTotalAmountDue] = useState<string>(null)
    const [splitDues, setSplitDues] = useState<bookingDuesT[]>(null)
    const appState = useAppContext()
    const navigate = useNavigate()
    useAuth(appState)

    // Load finance data when the component mounts
    useEffect(() => {
        const getFinanceData = async (uuid: string) => {
            const docSnap = await getUserFinanceData(uuid)
            const financeData = docSnap.data()
            setTotalAmount(financeData.totalAmount)
            setTotalAmountDue(financeData.totalAmountDue)
            const tmpSplitDues = []
            Object.entries(financeData.paymentsDue).forEach((item) => {
                const [bookingNum, details] = item
                const bookingDues: bookingDuesT = {
                    ...details,
                    bookingNo: bookingNum,
                }
                tmpSplitDues.push(bookingDues)
            })
            setSplitDues(tmpSplitDues)
            setLoading(false)
        }
        if (appState && !appState[0].loading && !appState[0].uuid) {
            navigate('/')
        } else if (appState && appState[0].uuid) {
            getFinanceData(appState[0].uuid)
        }
    }, [appState, navigate])
    const renderLoading = () => {
        console.log(splitDues)
        if (loading) {
            return (
                <Box
                    sx={{
                        width: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        ml: '55vw',
                        mt: '28%',
                    }}
                >
                    <CircularProgress />
                    <Typography sx={{ ml: '-0.6vw', mt: '1%' }}>Loading</Typography>
                </Box>
            )
        } else {
            return (
                <Grid
                    container
                    position="relative"
                    sx={{
                        pt: `${APP_HEADER_HEIGHT_PX}px`,
                        pl: { sm: `${APP_SIDE_DRAWER_WIDTH_PX - 80}px`, xs: 10 },
                        justifyContent: 'center',
                        display: 'block',
                        flexBasis: 'auto',
                    }}
                >
                    <Grid item xs={8} sm={10}>
                        <Typography
                            sx={{
                                borderBottom: 1,
                                borderColor: 'divider',
                                color: 'navy',
                                fontSize: '4vh',
                            }}
                        >
                            Invoices
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sm={10}>
                        <PaymentDetails />
                        <Box>
                            <Box sx={{ borderBottom: 3, borderColor: 'navy' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="Invoices">
                                    <Tab label="Active Invoices" {...a11yProps(0)} />
                                    <Tab label="Completed Invoices" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <InvoiceTable invoices={splitDues} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <InvoiceTable invoices={splitDues} />
                            </TabPanel>
                        </Box>
                    </Grid>
                </Grid>
            )
        }
    }
    return (
        <Grid container>
            <Drawer />
            {renderLoading()}
        </Grid>
    )
}

export default FinancePage
