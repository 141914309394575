import { TextField, FormControlLabel, Checkbox } from '@mui/material'
import MuiGrid from '@mui/material/Grid'
import { FormGroupHeader } from './reusableForms'
import {
    ADDRESS,
    EMAIL,
    NAME,
    CUSTOMER_ID,
    CUSTOMER_VERIFIED,
    CUSTOMER_NTN,
    CUSTOMER_NOTES,
    CUSTOMER_BOOKINGS,
    PHONE,
    CUSTOMER_FREIGHT_MARGIN,
    CUSTOMER_CUSTOMS_MARGIN,
    CUSTOMER_TRUCKING_MARGIN,
} from '../adminConstants'

const checkBoxStyle = {
    display: 'flex',
    justifyContent: 'center',
}
/*  
    Form labels and categories
    Form group 1 (Basic customer info): [customer_id, company_name, is_verified]
    Form group 2 (Customer contact): [email, phone, address]
    Form group 3 (Customer finance): [NTN, freight_margin_pct, trucking_margin_usd, customs_margin_usd]
    Form group 4 (Misc): [total_bookings, our_notes]
 */
export const CustomerForm = ({ row, onChange }) => {
    return (
        <MuiGrid container spacing={3}>
            <FormGroupHeader label={'Customer Overview'} />
            <MuiGrid item xs={6}>
                <TextField
                    name={CUSTOMER_ID}
                    label="Customer ID"
                    disabled
                    fullWidth
                    value={row[CUSTOMER_ID] || ''}
                    onChange={onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6} sx={checkBoxStyle}>
                {!row.is_verified ? (
                    <FormControlLabel
                        control={<Checkbox />}
                        name={CUSTOMER_VERIFIED}
                        label="Verified"
                        value={row[CUSTOMER_VERIFIED] || ''}
                        onChange={onChange}
                    />
                ) : null}
            </MuiGrid>
            <MuiGrid item xs={12}>
                <TextField
                    name={NAME}
                    fullWidth
                    label="Company Name"
                    value={row[NAME] || ''}
                    onChange={onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <TextField
                    name={EMAIL}
                    fullWidth
                    label="Email"
                    value={row[EMAIL] || ''}
                    onChange={onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <TextField
                    name={PHONE}
                    fullWidth
                    label="Phone No"
                    value={row[PHONE] || ''}
                    onChange={onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={12}>
                <TextField
                    name={ADDRESS}
                    fullWidth
                    label="Address"
                    value={row[ADDRESS] || ''}
                    onChange={onChange}
                />
            </MuiGrid>
            <FormGroupHeader label={'Customer Financial Info'} />
            <MuiGrid item xs={12}>
                <TextField
                    name={CUSTOMER_NTN}
                    fullWidth
                    label="NTN"
                    value={row[CUSTOMER_NTN] || ''}
                    onChange={onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <TextField
                    name={CUSTOMER_FREIGHT_MARGIN}
                    fullWidth
                    label="Percent Freight Margin"
                    value={row[CUSTOMER_FREIGHT_MARGIN] || ''}
                    onChange={onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <TextField
                    name={CUSTOMER_TRUCKING_MARGIN}
                    fullWidth
                    label="Trucking Margin"
                    value={row[CUSTOMER_TRUCKING_MARGIN] || ''}
                    onChange={onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <TextField
                    name={CUSTOMER_CUSTOMS_MARGIN}
                    fullWidth
                    label="Customs Margin"
                    value={row[CUSTOMER_CUSTOMS_MARGIN] || ''}
                    onChange={onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <TextField
                    name={CUSTOMER_BOOKINGS}
                    fullWidth
                    label="# Bookings"
                    value={row[CUSTOMER_BOOKINGS] || ''}
                    onChange={onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={12}>
                <TextField
                    name={CUSTOMER_NOTES}
                    fullWidth
                    multiline
                    label="Customer notes"
                    value={row[CUSTOMER_NOTES] || ''}
                    onChange={onChange}
                />
            </MuiGrid>
        </MuiGrid>
    )
}
