import * as React from 'react'
import Paper from '@mui/material/Paper'
import { ArgumentAxis, ValueAxis, Title, Chart, LineSeries, BarSeries } from '@devexpress/dx-react-chart-material-ui'
import { Animation } from '@devexpress/dx-react-chart'
import { allMonthInfoT, months } from '../Util/constants.d'
import { useState } from 'react'

interface monthSpentT {
    month: string
    amountPaid: number
}

interface spendPerMonthPropsT {
    monthInfo: allMonthInfoT
}

export default function ShipmentTypePie(props: spendPerMonthPropsT) {
    const [chartData] = useState<monthSpentT[]>([
        {
            month: 'NOVEMBER',
            amountPaid: props.monthInfo[months.NOVEMBER].amountPaid,
        },
        {
            month: 'DECEMBER',
            amountPaid: props.monthInfo[months.DECEMBER].amountPaid,
        },
        {
            month: 'JANUARY',
            amountPaid: props.monthInfo[months.JANUARY].amountPaid,
        },
        {
            month: 'FEBRUARY',
            amountPaid: props.monthInfo[months.FEBRUARY].amountPaid,
        },
        {
            month: 'MARCH',
            amountPaid: props.monthInfo[months.MARCH].amountPaid,
        },
    ])

    return (
        <Paper sx={{ bgcolor: 'aliceblue', width: '60%' }}>
            <Chart data={chartData}>
                <ArgumentAxis />
                <ValueAxis />

                <BarSeries
                    name="Total Spending Per Month"
                    valueField="amountPaid"
                    argumentField="month"
                />

                <Title text={'Total Spending per Month'} />
                <Animation />
            </Chart>
        </Paper>
    )
}
