import React from 'react'
import {
    Button,
    Typography,
    TextField,
    Grid,
    Container,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Stack,
    Switch,
} from '@mui/material'
import { CurrentBookingInfoT } from '../Util/constants.d'
import { useCurrentBookingContext } from '../Components/store'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import { FormGroupHeader } from './adminComponents/adminForms/reusableForms'

export const CUSTOMS = 'Customs'
export const TRUCKING = 'Trucking'
export const FREE_DAYS = 'Free Days'
export const CUSTOMS_PRICE = 200
export const FREE_DAYS_PRICE = 150
export default function AdditionalServicesBox() {
    const [freeDays, setFreeDays] = React.useState(false)
    const [truckDest, setTruckDest] = React.useState('')
    const [truckingPrice, setTruckingPrice] = React.useState(null)
    const [custExpanded, setCustExpanded] = React.useState(false)
    const [truckExpanded, setTruckExpanded] = React.useState(false)
    const [freeDaysExpanded, setFreeDaysExpanded] = React.useState(false)
    const [currentBookingContext, setCurrentBookingContext] = useCurrentBookingContext()

    const toggleService = (service: string) => {
        let newBookingContext: CurrentBookingInfoT = null
        switch (service) {
            case CUSTOMS:
                if (currentBookingContext.add_customs) {
                    newBookingContext = {
                        ...currentBookingContext,
                        add_customs: false,
                        customs_price_usd: null,
                    }
                } else {
                    newBookingContext = {
                        ...currentBookingContext,
                        add_customs: true,
                        customs_price_usd: CUSTOMS_PRICE,
                    }
                }
                break
            case TRUCKING:
                if (currentBookingContext.add_trucking) {
                    newBookingContext = {
                        ...currentBookingContext,
                        add_trucking: false,
                        trucking_price_usd: null,
                    }
                } else {
                    newBookingContext = {
                        ...currentBookingContext,
                        add_trucking: true,
                        trucking_price_usd: truckingPrice,
                    }
                }
                break
            case FREE_DAYS:
                if (currentBookingContext.add_free_days) {
                    newBookingContext = {
                        ...currentBookingContext,
                        add_free_days: false,
                        free_days: 14,
                        trucking_price_usd: null,
                    }
                } else {
                    newBookingContext = {
                        ...currentBookingContext,
                        add_free_days: true,
                        free_days: 21,
                        free_days_price_usd: FREE_DAYS_PRICE,
                    }
                }
                break
            default:
                break
        }
        setCurrentBookingContext(newBookingContext)
    }

    const calcTrucking = (truckDest: string) => {
        let truckingCharge = 0
        setTruckingPrice(truckingCharge)
        if (truckDest.toLowerCase() === 'lahore') {
            truckingCharge = 155000
            setTruckingPrice(truckingCharge)
            return truckingCharge.toLocaleString('en-US', {
                style: 'currency',
                currency: 'PKR',
            })
        }
        if (truckDest.toLowerCase() === 'karachi') {
            truckingCharge = 17000
            setTruckingPrice(truckingCharge)
            return truckingCharge.toLocaleString('en-US', {
                style: 'currency',
                currency: 'PKR',
            })
        }
        if (truckDest.toLowerCase() === 'islamabad') {
            truckingCharge = 180000
            setTruckingPrice(truckingCharge)
            return truckingCharge.toLocaleString('en-US', {
                style: 'currency',
                currency: 'PKR',
            })
        }
        if (truckDest) {
            truckingCharge = 160000
            setTruckingPrice(truckingCharge)
            return truckingCharge.toLocaleString('en-US', {
                style: 'currency',
                currency: 'PKR',
            })
        }
        return ''
    }

    return (
        <Box
            sx={{
                mt: '10vh',
                ml: '5%',
                bgcolor: 'aliceblue',
                border: '3px solid navy',
                borderRadius: '15px',
            }}
        >
            <Typography
                sx={{
                    fontWeight: 'bold',
                    fontSize: '3vh',
                    textAlign: 'center',
                    color: 'navy',
                    pb: '2.5%',
                    borderBottom: '2px solid navy',
                }}
            >
                Additional Services
            </Typography>
            <Accordion
                sx={{
                    bgcolor: currentBookingContext.add_customs ? '#D9EFD9' : 'aliceblue',
                    borderBottom: '2px solid navy',
                }}
                expanded={custExpanded}
            >
                <AccordionSummary
                    expandIcon={
                        currentBookingContext.add_customs ? (
                            <IconButton
                                sx={{ transform: custExpanded ? 'rotate(180deg)' : null }}
                                onClick={() => setCustExpanded(!custExpanded)}
                            >
                                <CheckIcon fontSize="small" />
                            </IconButton>
                        ) : (
                            <IconButton onClick={() => setCustExpanded(!custExpanded)}>
                                <AddIcon fontSize="small" />
                            </IconButton>
                        )
                    }
                    aria-controls="additional-service-content"
                    id="service-name"
                >
                    <Typography sx={{ pl: '2%', fontWeight: 'bold', color: 'navy' }}>CUSTOMS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* <Box sx={{ display: 'flex', flexDirection: 'column' }}> */}

                    <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                            variant={currentBookingContext.add_customs ? 'outlined' : 'contained'}
                            onClick={() => {
                                toggleService(CUSTOMS)
                                setCustExpanded(!custExpanded)
                            }}
                            sx={{
                                pl: '2.5%',
                                pr: '2.5%',
                                height: '5%',
                                width: '100%',
                            }}
                        >
                            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                                {currentBookingContext.add_customs
                                    ? 'Remove Customs'
                                    : `Add For ${CUSTOMS_PRICE.toLocaleString('en-US', {
                                          style: 'currency',
                                          currency: 'USD',
                                      })}`}
                            </Typography>
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Accordion
                sx={{
                    bgcolor: currentBookingContext.add_trucking ? '#D9EFD9' : 'aliceblue',
                    borderBottom: '2px solid navy',
                }}
                expanded={truckExpanded}
            >
                <AccordionSummary
                    expandIcon={
                        currentBookingContext.add_trucking ? (
                            <IconButton onClick={() => setTruckExpanded(!truckExpanded)}>
                                <CheckIcon
                                    sx={{ transform: truckExpanded ? 'rotate(180deg)' : null }}
                                    fontSize="small"
                                />
                            </IconButton>
                        ) : (
                            <IconButton onClick={() => setTruckExpanded(!truckExpanded)}>
                                <AddIcon fontSize="small" />
                            </IconButton>
                        )
                    }
                    aria-controls="additional-service-content"
                    id="service-name"
                    sx={{ ml: '2%', fontWeight: 'bold', color: 'navy' }}
                >
                    TRUCKING
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            //bgcolor: currentBookingContext.add_trucking ? 'green' : 'inherit',
                        }}
                    >
                        <TextField
                            id="truck-dest-select"
                            label="Destination"
                            value={truckDest}
                            fullWidth
                            variant="outlined"
                            defaultValue="Enter Destination"
                            required
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setTruckDest(event.target.value)
                                calcTrucking(event.target.value)
                            }}
                        />
                        <Button
                            variant={currentBookingContext.add_trucking ? 'outlined' : 'contained'}
                            onClick={() => {
                                toggleService(TRUCKING)
                                setTruckExpanded(!truckExpanded)
                            }}
                            sx={{
                                ml: '2.5%',
                                mt: '5%',
                                mr: '2.5%',
                                height: '5%',
                                width: '100%',
                            }}
                        >
                            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                                {currentBookingContext.add_trucking ? 'Remove Trucking' : `Add`}
                            </Typography>
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion
                sx={{ bgcolor: currentBookingContext.add_free_days ? '#D9EFD9' : 'aliceblue' }}
                expanded={freeDaysExpanded}
            >
                <AccordionSummary
                    expandIcon={
                        currentBookingContext.add_free_days ? (
                            <IconButton onClick={() => setFreeDaysExpanded(!freeDaysExpanded)}>
                                <CheckIcon
                                    sx={{ transform: freeDaysExpanded ? 'rotate(180deg)' : null }}
                                    fontSize="small"
                                />
                            </IconButton>
                        ) : (
                            <IconButton onClick={() => setFreeDaysExpanded(!freeDaysExpanded)}>
                                <AddIcon fontSize="small" />
                            </IconButton>
                        )
                    }
                    aria-controls="additional-service-content"
                    id="service-name"
                    sx={{ ml: '2%', fontWeight: 'bold', color: 'navy' }}
                >
                    FREE DAYS
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            //bgcolor: currentBookingContext.add_trucking ? 'green' : 'inherit',
                        }}
                    >
                        <Button
                            variant={currentBookingContext.add_free_days ? 'outlined' : 'contained'}
                            onClick={() => {
                                toggleService(FREE_DAYS)
                                setFreeDaysExpanded(!freeDaysExpanded)
                            }}
                            sx={{
                                ml: '2.5%',
                                mt: '5%',
                                mr: '2.5%',
                                height: '5%',
                                width: '100%',
                            }}
                        >
                            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                                {currentBookingContext.add_free_days
                                    ? 'Remove Free Days'
                                    : `21 Free Days : $150`}
                            </Typography>
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}