import { useState, useEffect } from 'react'
import { BOOKING_TABLE_COLUMNS, BOOKING_TABLE_COLUMN_EXTENSIONS } from '../adminConstants'
import { BookingPopup } from '../adminPopups/bookingPopup'
import { GenericTable } from './genericTable'
import { initializeBookingData } from '../../../Util/adminUtil'
// eslint-disable-next-line
/* eslint-disable no-shadow */

export default function BookingsTable(props) {
    const [rows, setRows] = useState(props.rows ? props.rows : [])
    useEffect(() => {
        if (props.rows) {
            setRows(props.rows)
        }
    }, [props])

    const getRowId = (row) => row.bk_id

    return (
        <GenericTable
            columns={BOOKING_TABLE_COLUMNS}
            columnExtensions={BOOKING_TABLE_COLUMN_EXTENSIONS}
            getRowId={getRowId}
            title={'BOOKINGS'}
            popup={BookingPopup}
            popupProps={{ customers: props.customers }}
            defaultRow={initializeBookingData()}
            rows={rows}
        />
    )
}
