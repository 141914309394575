import { useState } from 'react'
import BookingForm from '../adminForms/bookingForm'
import { GenericPopup } from './genericPopup'
import * as adminConstants from '../adminConstants'
import { formatBookingData, formattedBookingDataT, genBookingFileName } from '../../../Util/adminUtil'
import { adminCreateOrUpdateBooking, uploadBookingDocument } from '../../../Util/adminFirebase'

export const BookingPopup = (props) => {
    // TODO: fix this hack
    const [invoiceFile, setInvoiceFile] = useState(null)
    const [blFile, setBLFile] = useState(null)
    const onSave = async () => {
        const bookingDataFormatted = {} as formattedBookingDataT
        formatBookingData(props.row, bookingDataFormatted)
        try {
            if (props.newEntry) {
                // TODO: should be handled in adminUtils
                // set some basic data about a new booking
                bookingDataFormatted.bookingInfo[adminConstants.IS_PENDING] = true
                bookingDataFormatted.bookingInfo[adminConstants.IS_ACTIVE] = false
                bookingDataFormatted.bookingInfo[adminConstants.REQUIRES_ATTENTION] = false
                bookingDataFormatted.bookingInfo[adminConstants.IS_COMPLETED] = false
            }
            console.log('Saving booking info...')
            const promises = []
            const uuid = bookingDataFormatted.bookingInfo[adminConstants.CUSTOMER_ID]
            const bkID = bookingDataFormatted.bookingInfo[adminConstants.BOOKING_ID]
            if (invoiceFile) {
                bookingDataFormatted.bookingUserFinance[adminConstants.INVOICE_FILE] = genBookingFileName(
                    uuid,
                    bkID,
                    invoiceFile.name,
                )
                promises.push(uploadBookingDocument(uuid, bkID, invoiceFile.name, invoiceFile))
            }
            if (blFile) {
                bookingDataFormatted.bookingUserFinance[adminConstants.BL_FILE] = genBookingFileName(
                    uuid,
                    bkID,
                    blFile.name,
                )
                promises.push(uploadBookingDocument(uuid, bkID, blFile.name, blFile))
            }
            promises.push(
                adminCreateOrUpdateBooking(props.row[adminConstants.BOOKING_ID], bookingDataFormatted),
            )
            await Promise.all(promises)
            console.log('Saved booking info successfully!')
        } catch (err) {
            console.log('Error creating/editing booking: ', err)
        }
        props.onSave()
    }
    return (
        <GenericPopup
            title={'Booking Details'}
            open={props.open}
            onSave={onSave}
            onCancel={props.onCancel}
        >
            <BookingForm
                row={props.row}
                setBLFile={setBLFile}
                setInvoiceFile={setInvoiceFile}
                onChange={props.onEdit}
                customers={props.customers}
            />
        </GenericPopup>
    )
}
