import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import AdminDrawer from '../../Components/adminComponents/adminDrawer'
import CustomerTable from '../../Components/adminComponents/adminTables/customerTable'
import { APP_HEADER_HEIGHT_PX, APP_SIDE_DRAWER_WIDTH_PX } from '../../Util/constants'
import { LoadingWheel } from '../../Components/loadingWheel'
import { loadCustomers, loadTransiportUserData } from '../../Util/adminFirebase'

export default function Customers() {
    // is data loading
    const [loading, setLoading] = useState<boolean>(false)
    const [rows, setRows] = useState([])

    // load customers first, and then render table
    useEffect(() => {
        const loadAllUsers = async () => {
            // TODO: use app state to verify admin
            setLoading(true)
            const customers = await loadCustomers()
            const dataRows = []
            const transiportDataPromises = []
            customers.forEach((doc) => {
                // create data rows
                const customerID = doc.id // uuid
                const customerData = doc.data()
                // TODO: combine with loadCustomers and optimize
                transiportDataPromises.push(
                    loadTransiportUserData(customerID).then((docRaw) => {
                        let tportData = docRaw.data()
                        tportData = tportData ? tportData : {}
                        // TODO: match it with customer info interface
                        dataRows.push({ ...customerData, ...tportData, uuid: customerID })
                    }),
                )
            })
            try {
                await Promise.all(transiportDataPromises)
                setRows(dataRows)
                setLoading(false)
            } catch (error) {
                console.log('Error retrieving transiport data: ', error)
                setLoading(false)
            }
        }
        loadAllUsers()
    }, [])

    return (
        <Grid container maxWidth="xl">
            <AdminDrawer />
            <Grid
                container
                sx={{
                    pt: `${APP_HEADER_HEIGHT_PX}px`,
                    pl: { sm: `${APP_SIDE_DRAWER_WIDTH_PX - 60}px`, xs: 10 },
                    pr: { sm: '10vw' },
                    justifyContent: 'center',
                }}
            >
                {loading ? <LoadingWheel /> : <CustomerTable rows={rows} />}
            </Grid>
        </Grid>
    )
}
