import { updatePartner } from '../../../Util/adminFirebase'
import { PartnersForm } from '../adminForms/partnersForm'
import { GenericPopup } from './genericPopup'

export const PartnersPopup = (props) => {
    const onSave = async () => {
        updatePartner(props.row)
    }
    return (
        <GenericPopup
            title={'Partner Details'}
            open={props.open}
            onSave={onSave}
            onCancel={props.onCancel}
        >
            <PartnersForm row={props.row} onChange={props.onEdit} />
        </GenericPopup>
    )
}
