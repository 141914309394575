import Box from '@mui/material/Box'
import CMACGM from '../Assets/cma_logo.png'
import Cosco from '../Assets/cosco_logo.png'
import Hapag from '../Assets/hapag_logo.png'
import HMM from '../Assets/hmm_logo.png'
import Maersk from '../Assets/maersk_logo.png'
import MSC from '../Assets/msc_logo.png'
import ONE from '../Assets/one_logo.png'
import OOCL from '../Assets/oocl_logo.png'
import TSL from '../Assets/ts_logo.png'
import Sealand from '../Assets/Sealand.jpg'
import Evergreen from '../Assets/evergreen_logo.png'

interface carrierT {
    carrierName: string
    carrierScac: string
}
export default function ParseLogo(carrier: carrierT) {
    const logoList = [CMACGM, Cosco, Hapag, HMM, MSC, ONE, OOCL, TSL, Maersk, Evergreen, Sealand]
    const logoNames = [
        'CMACGM',
        'Cosco',
        'Hapag',
        'HMM',
        'MSC',
        'ONE',
        'OOCL',
        'TSL',
        'Maersk',
        'Evergreen',
        'Sealand',
    ]
    const carrierScac = ['CMDU', 'COSU', 'EGLV', 'MAEU', 'MSCU', 'ONEY', 'OOLU', '22AA', 'YMLU']

    const carrierNameString = carrier.carrierName + ' '

    const carrierNameArray = carrierNameString.split(' ')

    const name = carrierNameArray[0]

    return (
        <Box sx={{ width: '90%', height: '100%', display: 'flex', flexDirection: 'row', ml: '2%' }}>
            {logoList.map((logo, idx) => {
                if (carrier.carrierName.includes(logoNames[idx])) {
                    return (
                        <Box key={idx} sx={{ mt: '20%', mb: '30%' }}>
                            <img src={logo} alt="Logo" width="130em" height="80em" />
                        </Box>
                    )
                }
            })}
        </Box>
    )
}
