import React, { ReactElement, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Logout from '@mui/icons-material/Logout'
import { Link as RouterLink, LinkProps as RouterLinkProps, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { signout, getCurrentUser } from '../Util/adminFirebase'
import { useAppContext } from './store'

interface ButtonLinkProps {
    primary: string | ReactElement<any, any>
    to: string
}

function ButtonLink(props: ButtonLinkProps) {
    const { primary, to } = props

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(function Link(
                itemProps,
                ref,
            ) {
                return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />
            }),
        [to],
    )

    return (
        <Button component={renderLink}>
            <Typography
                sx={{
                    fontSize: '10',
                    fontWeight: '400',
                }}
            >
                {primary}
            </Typography>
        </Button>
    )
}

export default function AccountMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    // TODO: find a more elegant solution here
    const [oldUuid, setOldUuid] = useState<string>('')
    const appState = useAppContext()
    const navigate = useNavigate()
    // set old uid after component mounted
    useEffect(() => {
        setOldUuid(appState[0].uuid)
    }, [])
    const handleSignout = async () => {
        try {
            // setState is async so theoretically can return after signout
            appState[1]({ ...appState[0], uuid: '', loading: true })
            await signout()
            appState[1]({ ...appState[0], uuid: '', loading: false })
            navigate('/')
        } catch (error) {
            if (getCurrentUser().uid == oldUuid) {
                appState[1]({ ...appState[0], uuid: oldUuid, loading: false })
            }
            console.log(error)
        }
    }
    return (
        <React.Fragment>
            <Box
                sx={{
                    justifyContent: 'right',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    marginLeft: '80%',
                }}
            >
                <Tooltip title="Account">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32, color: 'white' }} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px navy)',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'aliceblue',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem>
                    <ButtonLink to="/settings" primary="Account Settings" />
                </MenuItem>
                <MenuItem>
                    <ButtonLink to="/help" primary="Help" />
                </MenuItem>
                <MenuItem onClick={handleSignout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    {/*<ButtonLink to="/" primary="" />*/}
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}
