import { Box, Button, Divider, Paper, Typography } from '@mui/material'
import { useState, Dispatch, SetStateAction } from 'react'

interface trackProps {
    value: number
    name: string
    delayOneToFour?: number
    delayFivePlus?: number
    index: number
    onClick: Dispatch<SetStateAction<number>>
}

export default function TrackBox(props: trackProps) {
    const [table, setTable] = useState(props.name)
    const [selectedIdx, setSelectedIdx] = useState(0)
    const isSelected: boolean = selectedIdx === props.index

    const handleClick = () => {
        setSelectedIdx(props.index)
        props.onClick(props.index)
    }

    return (
        <Button
            onClick={handleClick}
            variant="outlined"
            value={table}
            sx={{
                bgcolor: '#F5F5F5',
                border: '2px solid navy',
                display: 'block',
                width: 'fit-content',
                height: 100,
                boxShadow: '-2px 2px 2px gray',
            }}
        >
            {/* {(props.name === 'ALL' || props.name === 'IN TRANSIT' || props.name === 'PENDING') && ( */}
            <>
                <Box sx={{ width: 150, height: '75%', display: 'flex', paddingLeft: 1, color: 'navy' }}>
                    <Typography
                        variant="caption"
                        align="left"
                        sx={{ fontWeight: 'bold', fontSize: '2vh', color: 'black' }}
                    >
                        {props.name}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        justifySelf: 'flex-end',
                        height: '20%',
                        color: 'primary',
                        paddingRight: 1,
                        verticalAlign: 'bottom',
                    }}
                >
                    <Typography align="right" sx={{ fontWeight: 'bold' }}>
                        {props.value}
                    </Typography>
                </Box>
            </>
            {/* )}
            {props.name === 'DELAYED SHIPMENTS' && (
                <Box sx={{
                        width: 300,
                        display: 'block',
                        justifyContent: 'space-between',
                        color: 'primary',
                    }}
                >
                    <Box sx={{ minWidth: '100%', display: 'flex', height: '100%' }}>
                        <Typography
                            variant="caption"
                            align="left"
                            sx={{ fontWeight: 'bold', fontSize: '2vh', color: 'black', width: '100%' }}
                        >
                            {props.name}
                        </Typography>

                        <Typography align="right" sx={{ width: '50%', fontWeight: 'bold' }}>
                            {props.value}
                        </Typography>
                    </Box>
                    <Divider sx={{ borderBottomColor: 'navy', borderBottomWidth: '3px' }} />

                    <Box
                        sx={{
                            color: 'black',
                            minWidth: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            mt: 2,
                        }}
                    >
                        <Typography
                            textAlign={'left'}
                            variant="caption"
                            sx={{ width: '50%', fontWeight: 'bold' }}
                        >
                            1-4 days delayed
                        </Typography>

                        <Typography align="right" sx={{ width: '50%' }}>
                            {props.value}
                        </Typography>

                        <Divider sx={{ borderBottomWidth: '3px' }} />

                        <Typography
                            textAlign={'left'}
                            variant="caption"
                            sx={{ width: '50%', fontWeight: 'bold' }}
                        >
                            5+ days delayed
                        </Typography>

                        <Typography align="right" sx={{ width: '50%' }}>
                            {props.delayFivePlus}
                        </Typography>
                    </Box>
                </Box>
            )} */}
        </Button>
    )
}
