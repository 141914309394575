import Papa from 'papaparse'
import * as adminConstants from '../Components/adminComponents/adminConstants'
import * as generalConstants from '../Util/constants'

export interface bookingGeneralInfoT {
    [adminConstants.ADD_CUSTOMS]: boolean
    [adminConstants.ADD_TRUCKING]: boolean
    [adminConstants.AGENT]: string
    [adminConstants.BOOKING_ID]: string
    [adminConstants.BOOKING_TYPE]: string
    [adminConstants.BL_NO]: string
    [adminConstants.BL_FILE]: string
    [adminConstants.CUSTOMER_ID]: string
    [adminConstants.CONTAINERS_QUANTITY]: number
    [adminConstants.CONTAINER_TYPE]: string
    [adminConstants.CURRENT_STATUS]: string
    [adminConstants.DELAYED_BY]: number
    [adminConstants.DESTINATION]: string
    [adminConstants.ETD]: string
    [adminConstants.ETA]: string
    [adminConstants.HS_CODE]: string
    [adminConstants.INCOTERM]: string
    [adminConstants.IS_ACTIVE]: boolean
    [adminConstants.IS_COMPLETED]: boolean
    [adminConstants.IS_DELAYED]: boolean
    [adminConstants.IS_PENDING]: boolean
    [adminConstants.IS_TRANSSHIPMENT]: boolean
    [adminConstants.LINE]: string
    [adminConstants.ORIGIN]: string
    [adminConstants.PROD_DESC]: string
    [adminConstants.TRADE]: string
    [adminConstants.QUOTE_TYPE]: string
    [adminConstants.VOYAGE_REF]: string
    [adminConstants.RATE_REF]: string
}

// TODO: for now misc charges are lumped and a total price is show to customer
// the description gives a breakdown of the charges
export interface bookingFinanceInfoT {
    [adminConstants.CUSTOMS_TOTAL]: number
    [adminConstants.FREIGHT_TOTAL]: number
    [adminConstants.INVOICE_ID]: string
    [adminConstants.INVOICE_FILE]: string
    [adminConstants.PAID_AMOUNT]: number
    [adminConstants.TOTAL_PRICE_USD]: number
    [adminConstants.TRUCKING_TOTAL]: number
    [adminConstants.TOTAL_MISC_CHARGES]: number
    [adminConstants.MISC_CHARGES_BREAKDOWN]: string // breakdown: (item, total)
}

//
export interface bookingTransiportFinanceT {
    [adminConstants.CUSTOMS_COST]: number
    [adminConstants.CUSTOMS_MARGIN]: number
    [adminConstants.FREIGHT_COST]: number
    [adminConstants.FREIGHT_MARGIN]: number
    [adminConstants.TOTAL_MARGIN]: number
    [adminConstants.TRUCKING_COST]: number
    [adminConstants.TRUCKING_MARGIN]: number
    [adminConstants.TOTAL_MISC_CHARGES_COST]: number
    [adminConstants.TOTAL_MISC_CHARGES_MARGIN]: number
    [adminConstants.MISC_CHARGES_COST_MARGIN_BREAKDOWN]: string // breakdown: (item, cost, margin)
}

// TODO: add bookingTracking interface when ready
export interface flatBookingDataT
    extends bookingGeneralInfoT,
        bookingFinanceInfoT,
        bookingTransiportFinanceT {}

export interface formattedBookingDataT {
    bookingInfo: bookingGeneralInfoT
    bookingUserFinance: bookingFinanceInfoT
    bookingTransiportFinance: bookingTransiportFinanceT
    bookingTracking: Object
}

export interface customerBasicProfileT {
    [adminConstants.NAME]: string
    [adminConstants.EMAIL]: string
    [adminConstants.ADDRESS]: string
    [adminConstants.CUSTOMER_VERIFIED]: boolean
    [adminConstants.CUSTOMER_NTN]: string
    [adminConstants.CUSTOMER_BOOKINGS]: number
    [adminConstants.PHONE]: string
}

export interface customerBookingsDataT {
    [adminConstants.ACTIVE_SHIPMENTS]: string[]
    [adminConstants.ATTENTION_REQUIRED_SHIPMENTS]: string[]
    [adminConstants.COMPLETED_SHIPMENTS]: string[]
    [adminConstants.DELAYED_SHIPMENTS]: string[]
    [adminConstants.PENDING_SHIPMENTS]: string[]
}

export interface customerAnalyticsDataT {
    carrier_info: Object
    monthly_info: Object
    total_shipments: number
    total_transshipments: number
    total_customs: number
    total_truckings: number
}

export interface customerFinanceDataT {
    completed_payments: Object
    due_payments: Object
}

export interface customerTransiportDataT {
    [adminConstants.CUSTOMER_NOTES]: string
    [adminConstants.CUSTOMER_FREIGHT_MARGIN]: number
    [adminConstants.CUSTOMER_CUSTOMS_MARGIN]: number
    [adminConstants.CUSTOMER_TRUCKING_MARGIN]: number
}

export interface customerProfileT {
    basicProfile: customerBasicProfileT
    bookingsData: customerBookingsDataT
    analyticsData: customerAnalyticsDataT
    financeData: customerFinanceDataT
    transiportData: customerTransiportDataT
}

// TODO: add bookingTracking interface when ready
export interface flatCustomerDataT
    extends customerBasicProfileT,
        customerBookingsDataT,
        customerAnalyticsDataT,
        customerFinanceDataT,
        customerTransiportDataT {}

const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export const sleep = async (ms, fn, ...args) => {
    await timeout(ms)
    return fn(...args)
}

export const bruteSanitize = (dataObj) => {
    for (const item in dataObj) {
        // convert undefineds to empty strs
        dataObj[item] = dataObj[item] ?? ''
    }
}

export const genBookingFileName = (uuid, bookingNum, fileName) => {
    return `${uuid}/${bookingNum + '/'}${fileName}`
}

export const formatCustomerProfile = (customerData): customerBasicProfileT => {
    const customerProfile = {
        [adminConstants.NAME]: customerData[adminConstants.NAME],
        [adminConstants.EMAIL]: customerData[adminConstants.EMAIL],
        [adminConstants.ADDRESS]: customerData[adminConstants.ADDRESS],
        [adminConstants.CUSTOMER_VERIFIED]: customerData[adminConstants.CUSTOMER_VERIFIED] ?? true,
        [adminConstants.CUSTOMER_NTN]: customerData[adminConstants.CUSTOMER_NTN],
        [adminConstants.CUSTOMER_BOOKINGS]: customerData[adminConstants.CUSTOMER_BOOKINGS],
        [adminConstants.PHONE]: customerData.phone,
    } as customerBasicProfileT
    bruteSanitize(customerProfile)
    return customerProfile
}

export const formatCustomerTransiportData = (customerData): customerTransiportDataT => {
    const customerTransiportData = {
        [adminConstants.CUSTOMER_NOTES]: customerData[adminConstants.CUSTOMER_NOTES],
        [adminConstants.CUSTOMER_FREIGHT_MARGIN]: customerData[adminConstants.CUSTOMER_FREIGHT_MARGIN],
        [adminConstants.CUSTOMER_TRUCKING_MARGIN]: customerData[adminConstants.CUSTOMER_TRUCKING_MARGIN],
        [adminConstants.CUSTOMER_CUSTOMS_MARGIN]: customerData[adminConstants.CUSTOMER_CUSTOMS_MARGIN],
    } as customerTransiportDataT
    bruteSanitize(customerTransiportData)
    return customerTransiportData
}

export const createNewCustomerProfile = (customerData): customerProfileT => {
    const publicData: customerBasicProfileT = formatCustomerProfile(customerData)
    const transiportData: customerTransiportDataT = formatCustomerTransiportData(customerData)
    const customerFinance: customerFinanceDataT = { completed_payments: {}, due_payments: {} }
    const customerAnalytics: customerAnalyticsDataT = {
        carrier_info: {},
        monthly_info: {},
        total_shipments: 0,
        total_transshipments: 0,
        total_customs: 0,
        total_truckings: 0,
    }
    const customerBookings: customerBookingsDataT = {
        [adminConstants.ACTIVE_SHIPMENTS]: [],
        [adminConstants.ATTENTION_REQUIRED_SHIPMENTS]: [],
        [adminConstants.COMPLETED_SHIPMENTS]: [],
        [adminConstants.DELAYED_SHIPMENTS]: [],
        [adminConstants.PENDING_SHIPMENTS]: [],
    }

    const newUserProfile = {
        basicProfile: publicData,
        transiportData: transiportData,
        financeData: customerFinance,
        analyticsData: customerAnalytics,
        bookingsData: customerBookings,
    } as customerProfileT
    // remove any undefined values from each object
    Object.values(newUserProfile).forEach((profileObj) => {
        bruteSanitize(profileObj)
    })
    return newUserProfile
}

export const formatBookingGeneralInfo = (rawData, formattedData: formattedBookingDataT) => {
    formattedData.bookingInfo = {
        [adminConstants.BOOKING_ID]: rawData[adminConstants.BOOKING_ID],
        [adminConstants.BL_NO]: rawData[adminConstants.BL_NO],
        [adminConstants.CUSTOMER_ID]: rawData[adminConstants.CUSTOMER_ID],
        [adminConstants.AGENT]: rawData[adminConstants.AGENT],
        [adminConstants.CONTAINERS_QUANTITY]: rawData[adminConstants.CONTAINERS_QUANTITY],
        [adminConstants.CONTAINER_TYPE]: rawData[adminConstants.CONTAINER_TYPE],
        [adminConstants.INCOTERM]: rawData[adminConstants.INCOTERM],
        [adminConstants.BOOKING_TYPE]: rawData[adminConstants.BOOKING_TYPE],
        [adminConstants.TRADE]: rawData[adminConstants.TRADE],
        [adminConstants.ORIGIN]: rawData[adminConstants.ORIGIN],
        [adminConstants.DESTINATION]: rawData[adminConstants.DESTINATION],
        [adminConstants.ETD]: rawData[adminConstants.ETD],
        [adminConstants.ETA]: rawData[adminConstants.ETA],
        [adminConstants.LINE]: rawData[adminConstants.LINE],
        [adminConstants.IS_DELAYED]: rawData[adminConstants.IS_DELAYED],
        [adminConstants.DELAYED_BY]: rawData[adminConstants.DELAYED_BY],
        [adminConstants.IS_TRANSSHIPMENT]: rawData[adminConstants.IS_TRANSSHIPMENT],
        [adminConstants.CURRENT_STATUS]: rawData[adminConstants.CURRENT_STATUS],
        [adminConstants.IS_ACTIVE]: rawData[adminConstants.IS_ACTIVE],
        [adminConstants.IS_PENDING]: rawData[adminConstants.IS_PENDING],
        [adminConstants.IS_COMPLETED]: rawData[adminConstants.IS_COMPLETED],
        [adminConstants.BL_FILE]: rawData[adminConstants.BL_FILE],
        [adminConstants.PROD_DESC]: rawData[adminConstants.PROD_DESC],
        [adminConstants.HS_CODE]: rawData[adminConstants.HS_CODE],
        [adminConstants.ADD_CUSTOMS]: rawData[adminConstants.ADD_CUSTOMS],
        [adminConstants.ADD_TRUCKING]: rawData[adminConstants.ADD_TRUCKING],
        [adminConstants.QUOTE_TYPE]: rawData[adminConstants.QUOTE_TYPE],
        [adminConstants.VOYAGE_REF]: rawData[adminConstants.VOYAGE_REF],
        [adminConstants.RATE_REF]: rawData[adminConstants.RATE_REF],
    }
    bruteSanitize(formattedData.bookingInfo)
}

// TODO: brainstorm and look at marine traffic api result sample
export const formatBookingTrackingInfo = (rawData, formattedData: formattedBookingDataT) => {
    formattedData.bookingTracking = {}
    bruteSanitize(formattedData.bookingTracking)
}

export const formatBookingFinanceInfo = (rawData, formattedData: formattedBookingDataT) => {
    formattedData.bookingUserFinance = {
        [adminConstants.TOTAL_PRICE_USD]: rawData[adminConstants.TOTAL_PRICE_USD],
        [adminConstants.PAID_AMOUNT]: rawData[adminConstants.PAID_AMOUNT],
        [adminConstants.FREIGHT_TOTAL]:
            rawData[adminConstants.FREIGHT_COST] + rawData[adminConstants.FREIGHT_MARGIN],
        [adminConstants.CUSTOMS_TOTAL]:
            rawData[adminConstants.CUSTOMS_COST] + rawData[adminConstants.CUSTOMS_MARGIN],
        [adminConstants.TRUCKING_TOTAL]:
            rawData[adminConstants.TRUCKING_COST] + rawData[adminConstants.TRUCKING_MARGIN],
        [adminConstants.INVOICE_FILE]: rawData[adminConstants.INVOICE_FILE],
        [adminConstants.INVOICE_ID]: rawData[adminConstants.INVOICE_ID],
        [adminConstants.TOTAL_MISC_CHARGES]: rawData[adminConstants.TOTAL_MISC_CHARGES],
        [adminConstants.MISC_CHARGES_BREAKDOWN]: rawData[adminConstants.MISC_CHARGES_BREAKDOWN],
    }
    formattedData.bookingTransiportFinance = {
        [adminConstants.FREIGHT_COST]: rawData[adminConstants.FREIGHT_COST],
        [adminConstants.CUSTOMS_COST]: rawData[adminConstants.CUSTOMS_COST],
        [adminConstants.TRUCKING_COST]: rawData[adminConstants.TRUCKING_COST],
        [adminConstants.TOTAL_MARGIN]: rawData[adminConstants.TOTAL_MARGIN],
        [adminConstants.FREIGHT_MARGIN]: rawData[adminConstants.FREIGHT_MARGIN],
        [adminConstants.CUSTOMS_MARGIN]: rawData[adminConstants.CUSTOMS_MARGIN],
        [adminConstants.TRUCKING_MARGIN]: rawData[adminConstants.TRUCKING_MARGIN],
        [adminConstants.TOTAL_MISC_CHARGES_COST]: rawData[adminConstants.TOTAL_MISC_CHARGES_COST],
        [adminConstants.TOTAL_MISC_CHARGES_MARGIN]: rawData[adminConstants.TOTAL_MISC_CHARGES_MARGIN],
        [adminConstants.MISC_CHARGES_COST_MARGIN_BREAKDOWN]:
            rawData[adminConstants.MISC_CHARGES_COST_MARGIN_BREAKDOWN],
    }
    bruteSanitize(formattedData.bookingUserFinance)
    bruteSanitize(formattedData.bookingTransiportFinance)
}

export const formatBookingData = (rawData, formattedData: formattedBookingDataT) => {
    formatBookingGeneralInfo(rawData, formattedData)
    formatBookingFinanceInfo(rawData, formattedData)
    formatBookingTrackingInfo(rawData, formattedData)
}

export const initializeBookingData = (): flatBookingDataT => {
    return {
        // bookingGeneralInfoT
        [adminConstants.ADD_CUSTOMS]: false,
        [adminConstants.ADD_TRUCKING]: false,
        [adminConstants.AGENT]: '',
        [adminConstants.BOOKING_ID]: '',
        [adminConstants.BOOKING_TYPE]: '',
        [adminConstants.BL_NO]: '',
        [adminConstants.BL_FILE]: '',
        [adminConstants.CUSTOMER_ID]: '',
        [adminConstants.CONTAINERS_QUANTITY]: 0,
        [adminConstants.CONTAINER_TYPE]: '',
        [adminConstants.CURRENT_STATUS]: '',
        [adminConstants.DELAYED_BY]: 0,
        [adminConstants.DESTINATION]: '',
        [adminConstants.ETD]: '',
        [adminConstants.ETA]: '',
        [adminConstants.HS_CODE]: '',
        [adminConstants.INCOTERM]: '',
        [adminConstants.IS_ACTIVE]: false,
        [adminConstants.IS_COMPLETED]: false,
        [adminConstants.IS_DELAYED]: false,
        [adminConstants.IS_PENDING]: false,
        [adminConstants.IS_TRANSSHIPMENT]: false,
        [adminConstants.LINE]: '',
        [adminConstants.ORIGIN]: '',
        [adminConstants.PROD_DESC]: '',
        [adminConstants.TRADE]: '',
        [adminConstants.QUOTE_TYPE]: '',
        [adminConstants.VOYAGE_REF]: '',
        [adminConstants.RATE_REF]: '',
        // bookingFinanceInfoT
        [adminConstants.CUSTOMS_TOTAL]: 0,
        [adminConstants.FREIGHT_TOTAL]: 0,
        [adminConstants.INVOICE_ID]: '',
        [adminConstants.INVOICE_FILE]: '',
        [adminConstants.PAID_AMOUNT]: 0,
        [adminConstants.TOTAL_PRICE_USD]: 0,
        [adminConstants.TRUCKING_TOTAL]: 0,
        [adminConstants.TOTAL_MISC_CHARGES]: 0,
        [adminConstants.MISC_CHARGES_BREAKDOWN]: '',
        // bookingTransiportFinanceT
        [adminConstants.CUSTOMS_COST]: 0,
        [adminConstants.CUSTOMS_MARGIN]: 0,
        [adminConstants.FREIGHT_COST]: 0,
        [adminConstants.FREIGHT_MARGIN]: 0,
        [adminConstants.TOTAL_MARGIN]: 0,
        [adminConstants.TRUCKING_COST]: 0,
        [adminConstants.TRUCKING_MARGIN]: 0,
        [adminConstants.TOTAL_MISC_CHARGES_COST]: 0,
        [adminConstants.TOTAL_MISC_CHARGES_MARGIN]: 0,
        [adminConstants.MISC_CHARGES_COST_MARGIN_BREAKDOWN]: '',
    }
}

// TODO: redo ASAP
export const initializeCustomerData = (): flatCustomerDataT => {
    return {
        [adminConstants.NAME]: '',
        [adminConstants.EMAIL]: '',
        [adminConstants.ADDRESS]: '',
        [adminConstants.CUSTOMER_VERIFIED]: true,
        [adminConstants.CUSTOMER_NTN]: '',
        [adminConstants.CUSTOMER_BOOKINGS]: 0,
        [adminConstants.PHONE]: '',
        [adminConstants.ACTIVE_SHIPMENTS]: [],
        [adminConstants.ATTENTION_REQUIRED_SHIPMENTS]: [],
        [adminConstants.COMPLETED_SHIPMENTS]: [],
        [adminConstants.DELAYED_SHIPMENTS]: [],
        [adminConstants.PENDING_SHIPMENTS]: [],
        carrier_info: {},
        monthly_info: {},
        total_shipments: 0,
        total_transshipments: 0,
        total_customs: 0,
        total_truckings: 0,
        completed_payments: {},
        due_payments: {},
        [adminConstants.CUSTOMER_NOTES]: '',
        [adminConstants.CUSTOMER_FREIGHT_MARGIN]: 5,
        [adminConstants.CUSTOMER_CUSTOMS_MARGIN]: 100,
        [adminConstants.CUSTOMER_TRUCKING_MARGIN]: 60,
    }
}

// TODO: robustify
// we only support conversions to string, number or bool
const typeCastDataToPrimitive = (val, type) => {
    const valType = typeof val
    if (type === valType) {
        return val
    }
    if (type === 'boolean') {
        if (valType === 'string') {
            // only true/yes is converted to true, any other string
            // will eval to false
            const sanitizedVal = val.trim().toLowerCase()
            return sanitizedVal === 'true' || sanitizedVal === 'yes'
        } else if (valType === 'number') {
            Boolean(val)
        } else {
            // console.log(`Cannot convert ${valType} to Boolean.`)
        }
    } else if (type === 'string') {
        String(val)
    } else if (type === 'number') {
        if (!isNaN(val)) {
            return Number(val)
        } else {
            // console.log(`Cannot convert ${valType} to Number.`)
        }
    } else {
        // console.log(`Type conversion to ${type} not supported.`)
    }
}

// TODO: needs robustification in terms of inferring from supplied data
export const sanitizeFlatBookingData = (rawData) => {
    const formattedBookingData: flatBookingDataT = initializeBookingData()
    for (const key in rawData) {
        if (!(key in formattedBookingData)) {
            // console.log(`key: ${key}, not in flatBookingDataT, removing key`)
            // From the Javascript/ECMAScript specification (specifically 12.6.4 The for-in Statement):
            // Properties of the object being enumerated may be deleted during enumeration.
            delete rawData[key]
        } else {
            const valType = typeof rawData[key]
            const desiredType = typeof formattedBookingData[key]
            if (valType === desiredType) {
                formattedBookingData[key] = rawData[key]
            } else {
                // console.log(`trying to cast key: ${key} of type ${valType} to ${desiredType}...`)
                // console.log(
                //     `new: ${typeof typeCastDataToPrimitive(rawData[key], desiredType)}`,
                //     typeCastDataToPrimitive(rawData[key], desiredType),
                //     `old: ${typeof rawData[key]} `,
                //     rawData[key],
                //     `default: ${typeof formattedBookingData[key]} `,
                //     formattedBookingData[key],
                // )
                formattedBookingData[key] =
                    typeCastDataToPrimitive(rawData[key], desiredType) ?? formattedBookingData[key]
            }
        }
    }
    // replace rawData with the formatted data
    Object.assign(rawData, formattedBookingData)
}

export const isShipmentPending = (currentStatus) => {
    return (
        currentStatus == generalConstants.STAGE_CUSTOMER_PLACED_BOOKING ||
        currentStatus == generalConstants.STAGE_TRANISPORT_PLACED_BOOKING
    )
}

export const isShipmentCompleted = (currentStatus) => {
    return currentStatus == generalConstants.STAGE_CONTAINER_DELIVERED
}

// TODO: add currency checks and option for return currency type
export const calculateTotalPrice = (userFinanceInfo: bookingFinanceInfoT) => {
    return (
        userFinanceInfo[adminConstants.FREIGHT_TOTAL] +
        userFinanceInfo[adminConstants.CUSTOMS_TOTAL] +
        userFinanceInfo[adminConstants.TRUCKING_TOTAL] +
        userFinanceInfo[adminConstants.TOTAL_MISC_CHARGES]
    )
}

export const calculateBalanceDue = (userFinanceInfo: bookingFinanceInfoT) => {
    return calculateTotalPrice(userFinanceInfo) - userFinanceInfo[adminConstants.PAID_AMOUNT]
}

export const parseRateSheet = async (file) => {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            transformHeader: (value: string): string => {
                return value
                    .trim()
                    .toLowerCase()
                    .replaceAll(/-|\(|\)/g, '')
                    .replaceAll(' ', '_')
            },
            transform: (value: string): string => {
                return value.trim()
            },
            complete: (results) => {
                return resolve(results.data)
            },
            error: (error) => {
                return reject(error)
            },
        })
    })
}

/*
    // TODO: standardize somehow
    rate: {
        carrier: ...,
        container: ...,
        eta: ...,
        etd: ...,
        free_days: ...,
        pod: ...,
        pol: ...,
        rate_usd: ...,
        transit_time_days: ...,
        transshipment_stop: ...,
    }

    [voyage_id]: [
        {
            "productOffer": {
                carrierName: carrier,                
                originPort: pol
                destinationPort: pod,
                freeDays: free_days
            },
            productPrice: {
                routeSchedule: [
                    {
                        sailingDate: etd,
                        transitTime: transit_time_days + ' Days',
                        scheduleDetails: [
                            {
                                fromLocation: {
                                    unLocCode: pol,
                                    departure: etd
                                },
                                toLocation: {
                                    unLocCode: transhipment_stop[0],
                                    arrival: ''                                  
                                },
                                transport: {
                                    vessel: {
                                        name: ''
                                    },
                                    transportMode: ',
                                    voyageNumber: ''
                                }
                            },
                            ...
                            {
                                fromLocation: {
                                    unLocCode: transhipment_stop[-1],
                                    departure: ''
                                },
                                toLocation: {
                                    unLocCode: pod,
                                    arrival: eta                                  
                                },
                                transport: {
                                    vessel: {
                                        name: ''
                                    },
                                    transportMode: '',
                                    voyageNumber: ''
                                }
                            },
                        ]
                    }
                ],
                charges: [
                    {
                        amount: rate_usd,
                        amountUsd: rate_usd,
                        description: Basic Ocean Freight,
                        containerType: container
                    }
                ],
                totalUSDAmount: rate_usd
            }
        },
    ]
*/
export const parseVoyageFromRate = (rate) => {
    // TODO: do something more elegant, and also handle multiple stops
    // (below is) for stronger checking downstairs
    if (!rate.transshipment_stop) {
        rate.transshipment_stop = undefined
    }

    const voyageData = {
        productOffer: {
            carrierName: rate.carrier,
            originPort: rate.pol,
            destinationPort: rate.pod,
            freeDays: rate.free_days,
        },
        productPrice: {
            routeSchedule: [
                {
                    sailingDate: rate.etd,
                    transitTime: rate.transit_time_days + ' Days',
                    scheduleDetails: [
                        {
                            fromLocation: {
                                unLocCode: rate.pol,
                                departure: rate.etd,
                            },
                            toLocation: {
                                unLocCode: rate.transshipment_stop ?? rate.pod,
                                arrival: rate.transshipment_stop ?? rate.eta,
                            },
                            transport: {
                                vessel: {
                                    name: '',
                                },
                                transportMode: '',
                                voyageNumber: '',
                            },
                        },
                    ],
                },
            ],
            charges: [
                {
                    amount: rate.rate_usd,
                    amountUsd: rate.rate_usd,
                    description: 'Basic Ocean Freight',
                    containerType: rate.container,
                },
            ],
            totalUSDAmount: rate.rate_usd,
        },
    }
    // Conditionally add stop if transshipment
    if (rate.transshipment_stop) {
        const transhipmentDetails = {
            fromLocation: {
                unLocCode: rate.transshipment_stop,
                departure: '',
            },
            toLocation: {
                unLocCode: rate.pod,
                arrival: rate.eta,
            },
            transport: {
                vessel: {
                    name: '',
                },
                transportMode: '',
                voyageNumber: '',
            },
        }
        voyageData.productPrice.routeSchedule[0].scheduleDetails.push(transhipmentDetails)
    }

    if (rate.carrier === 'Maersk') {
        const extraCharges = [
            {
                amount: 126.68,
                amountUsd: 126.68,
                description: 'Terminal Handling Service - Origin',
                containerType: rate.container,
            },
            {
                amount: 14.79,
                amountUsd: 14.79,
                description: 'Export Service',
                containerType: rate.container,
            },
            {
                amount: 66.59,
                amountUsd: 66.59,
                description: 'Documentation Fee Origin',
                containerType: rate.container,
            },
            {
                amount: 45,
                amountUsd: 45,
                description: 'Import Service',
                containerType: rate.container,
            },
            {
                amount: 170,
                amountUsd: 170,
                description: 'Terminal Handling Service - Destination',
                containerType: rate.container,
            },
            {
                amount: 10,
                amountUsd: 10,
                description: 'Inland Additional Import Service',
                containerType: rate.container,
            },
            {
                amount: 65,
                amountUsd: 65,
                description: 'Documentation Fee Destination',
                containerType: rate.container,
            },
        ]
        voyageData.productPrice.charges.concat(extraCharges)
    }

    if (rate.carrier === 'CMACGM') {
        const extraCharges = [
            {
                amount: 14,
                amountUsd: 14,
                description: 'Ocean Carrier-Intl & port',
                containerType: rate.container,
            },
            {
                amount: 261,
                amountUsd: 261,
                description: 'Terminal handl. Ch destination',
                containerType: rate.container,
            },
            {
                amount: 37,
                amountUsd: 37,
                description: 'Container Maintenance Charge',
                containerType: rate.container,
            },
        ]
        voyageData.productPrice.charges.concat(extraCharges)
    }

    if (rate.carrier === 'OOCL') {
        const extraCharges = [
            {
                amount: 14,
                amountUsd: 14,
                description: 'Ocean Carrier-Intl & port',
                containerType: rate.container,
            },
            {
                amount: 261,
                amountUsd: 261,
                description: 'Terminal handl. Ch destination',
                containerType: rate.container,
            },
            {
                amount: 37,
                amountUsd: 37,
                description: 'Container Maintenance Charge',
                containerType: rate.container,
            },
        ]
        voyageData.productPrice.charges.concat(extraCharges)
    }

    if (rate.carrier === 'MSC') {
        const extraCharges = [
            {
                amount: 14,
                amountUsd: 14,
                description: 'Ocean Carrier-Intl & port',
                containerType: rate.container,
            },
            {
                amount: 261,
                amountUsd: 261,
                description: 'Terminal handl. Ch destination',
                containerType: rate.container,
            },
            {
                amount: 37,
                amountUsd: 37,
                description: 'Container Maintenance Charge',
                containerType: rate.container,
            },
        ]
        voyageData.productPrice.charges.concat(extraCharges)
    }
    if (rate.carrier === 'Evergreen') {
        const extraCharges = [
            {
                amount: 14,
                amountUsd: 14,
                description: 'Ocean Carrier-Intl & port',
                containerType: rate.container,
            },
            {
                amount: 261,
                amountUsd: 261,
                description: 'Terminal handl. Ch destination',
                containerType: rate.container,
            },
            {
                amount: 37,
                amountUsd: 37,
                description: 'Container Maintenance Charge',
                containerType: rate.container,
            },
        ]
        voyageData.productPrice.charges.concat(extraCharges)
    }
    if (rate.carrier === 'ONE') {
        const extraCharges = [
            {
                amount: 14,
                amountUsd: 14,
                description: 'Ocean Carrier-Intl & port',
                containerType: rate.container,
            },
            {
                amount: 261,
                amountUsd: 261,
                description: 'Terminal handl. Ch destination',
                containerType: rate.container,
            },
            {
                amount: 37,
                amountUsd: 37,
                description: 'Container Maintenance Charge',
                containerType: rate.container,
            },
        ]
        voyageData.productPrice.charges.concat(extraCharges)
    }

    if (rate.carrier === 'WanHai' || rate.carrier === 'TSL' || rate.carrier === 'KMTC') {
        const extraCharges = [
            {
                amount: 14,
                amountUsd: 14,
                description: 'Ocean Carrier-Intl & port',
                containerType: rate.container,
            },
            {
                amount: 261,
                amountUsd: 261,
                description: 'Terminal handl. Ch destination',
                containerType: rate.container,
            },
            {
                amount: 37,
                amountUsd: 37,
                description: 'Container Maintenance Charge',
                containerType: rate.container,
            },
        ]
        voyageData.productPrice.charges.concat(extraCharges)
    }

    return voyageData
}

export const genVoyageID = (partnerID, rateSheetID, rate) => {
    // TODO: hash the id, based on some known seed
    // const smoke = Math.floor(Math.random() * 10 ** 5)
    // return `${smoke}_${partnerID}_${rateSheetID}_${rate.pol}_${rate.pod}_${rate.container}`.replaceAll(
    //     ' ',
    //     '_',
    // )
    return (
        `${rate.etd}_${partnerID}_${rateSheetID}_${rate.pol}_` + `${rate.pod}_${rate.container}`
    ).replaceAll(/\/|\s/g, '_')
}
