import * as React from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import globalTheme from '../globalTheme'

export default function PaymentAccordion() {
    return (
        <Accordion sx={{ m: '5%' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography variant="h6" sx={{ color: 'navy', fontWeight: 'bold' }}>
                    Payment Details
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Table size="small" aria-label="service table">
                    <TableBody>
                        <TableRow sx={{ '& td': { border: 0 } }}>
                            <TableCell variant="head" sx={{ color: 'gray' }}>
                                Account No.
                            </TableCell>
                            <TableCell sx={{ color: 'navy', fontWeight: 'bold' }}>
                                0010087799760014
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '& td': { border: 0 } }}>
                            <TableCell variant="head" sx={{ color: 'gray' }}>
                                SWIFT Code
                            </TableCell>
                            <TableCell sx={{ color: 'navy', fontWeight: 'bold' }}>ABPAPKKA979</TableCell>
                        </TableRow>
                        <TableRow sx={{ '& td': { border: 0 } }}>
                            <TableCell variant="head" sx={{ color: 'gray' }}>
                                Account Owner
                            </TableCell>
                            <TableCell sx={{ color: 'navy', fontWeight: 'bold' }}>
                                TRANSIPORT TECHNOLOGIES (Private) Limited
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '& td': { border: 0 } }}>
                            <TableCell variant="head" sx={{ color: 'gray' }}>
                                Branch Name
                            </TableCell>
                            <TableCell sx={{ color: 'navy', fontWeight: 'bold' }}>
                                CANAL VIEW C.H. SOCIETY THOKER NIAZ BAIG LAHORE
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '& td': { border: 0 } }}>
                            <TableCell variant="head" sx={{ color: 'gray' }}>
                                Branch Code
                            </TableCell>
                            <TableCell sx={{ color: 'navy', fontWeight: 'bold' }}>0810</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    )
}
