import { Grid, CssBaseline, Typography, Button } from '@mui/material'
import BookBG from '../../Assets/bookbg.jpg'
import ResultsVid from '../../Assets/finalResults.mp4'
import LandingAppBar from '../../Components/landingComponents/landingAppBar'
import Footer from '../../Components/footerComponents/footer'
import { APP_HEADER_HEIGHT_PX } from '../../Util/constants'
import { useScrollToTop } from '../../Util/util';

export default function Booking() {
    useScrollToTop()
    return (
        <Grid container component="main">
            <CssBaseline />
            <LandingAppBar />
            <Grid
                container
                sx={{
                    px: { xs: 2 },
                    height: {
                        xs: `calc(70vh - calc(${APP_HEADER_HEIGHT_PX}px)
             )`,
                        sm: '100vh',
                    },
                    justifyContent: 'center',
                }}
            >
                <img
                    alt={'Book'}
                    src={BookBG}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        left: '50%',
                        top: '50%',
                        height: '100%',
                        objectFit: 'cover',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '-1',
                        filter: 'brightness(50%)',
                    }}
                />
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignSelf: { xs: 'flex-end', sm: 'center' },
                        color: 'white',
                        textAlign: { xs: 'left', sm: 'center' },
                    }}
                >
                    <Typography sx={{ fontSize: { xs: '4vh', sm: '8vh' } }}>
                        Instant Bookings With Single Click
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '2vh', sm: '4vh' } }}>
                        Your only digital freight partner
                    </Typography>

                    <Button
                        variant="contained"
                        href="/register"
                        sx={{ my: { xs: 2 }, alignSelf: 'center' }}
                    >
                        Sign Up
                    </Button>
                </Grid>
            </Grid>
            <Grid container sx={{ pt: '4vh', pb: '4vh', pr: { xs: '5vw' }, bgcolor: 'white' }}>
                <Grid item xs={12} sm={6} sx={{ alignSelf: 'center' }}>
                    <Typography
                        textAlign="justify"
                        sx={{
                            fontSize: { xs: '2vh', sm: '4vh' },
                            px: 2,
                        }}
                    >
                        No more hassle, No more hidden fees. No more surprises. Benefit from a network of
                        carriers for best service and best rates.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mx: { xs: 2, sm: 0 } }}>
                    <video
                        autoPlay
                        loop
                        muted
                        height={'100%'}
                        width={'100%'}
                        style={{
                            position: 'static',
                            alignContent: 'left',
                            marginTop: '2.5%',
                            marginBottom: '5%',
                            objectFit: 'initial',
                            border: '1 solid black',
                            //boxShadow: '15px -20px 45px grey',
                            //transform: 'skew(0deg,10deg)',
                        }}
                    >
                        <source src={ResultsVid} type="video/mp4" />
                    </video>
                </Grid>
            </Grid>
            <Footer />

        </Grid>
    )
}
