import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CustomerInput from '../Components/customerInput'
import { useCurrentBookingContext } from '../Components/store'
import { useState } from 'react'
import { FormGroupHeader } from '../Components/adminComponents/adminForms/reusableForms';

interface PartyDetailsT {
    companyName: string
    NTN: string
    address: string
    phoneNo: string
    emailAddr: string
}
interface handleNextT {
    handleNext: () => void
}

export default function InvoiceDetails(handleNext: handleNextT) {
    const [submit, setSubmit] = useState<boolean>(false)
    const [currentBookingContext, setCurrentBookingContext] = useCurrentBookingContext()
    const [consigneeDetails, setConsigneeDetails] = useState<PartyDetailsT>(null)
    const [shipperDetails, setShipperDetails] = useState<PartyDetailsT>(null)

    const submitBooking = () => {
        console.log(currentBookingContext)
        const newBookingContext = {
            ...currentBookingContext,
            consignee_details: { ...consigneeDetails },
            shipper_details: { ...shipperDetails },
            is_pending: true,
            bk_id: 'bk_no_19', // TODO: FIX, HACKY!!
        }
        setSubmit(true)
        setCurrentBookingContext(newBookingContext)
        handleNext.handleNext()
    }
    return (
        <Grid container maxWidth="xl" columnSpacing={2}>
            <Grid item xs={12} sm={12} sx={{ mt: '2.5%' }}>
                <FormGroupHeader label="Consignee Details" />

                <CustomerInput changeFunc={setConsigneeDetails} />
            </Grid>
            <Grid item xs={12} sm={12} sx={{ mt: '2.5%' }}>
                <FormGroupHeader label="Supplier Details" />

                <CustomerInput changeFunc={setShipperDetails} />
            </Grid>
            <Box
                sx={{
                    justifyContent: 'space-around',
                    width: '100%',
                    margin: '2.5%',
                    display: 'flex',
                }}
            >
                <Button variant={submit ? 'outlined' : 'contained'} onClick={submitBooking}>
                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                        {submit ? 'Edit Details' : 'Submit Details'}
                    </Typography>
                </Button>
            </Box>
        </Grid>
    )
}
