import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useNavigate } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
interface DropDownProps {
    menuOption: string
}

export default function DropDown(DropDownProps: DropDownProps) {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const option = DropDownProps.menuOption
    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    color: 'black',
                    fontWeight: { xs: 'normal', sm: 'bold' },
                    ml: { xs: 1, sm: 0 },
                }}
            >
                {option} <KeyboardArrowDownIcon />
            </Button>

            {option === 'Platform' && (
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => navigate('/landingSearch')}>Search Freight</MenuItem>
                    <MenuItem onClick={() => navigate('/landingBook')}>Book Freight</MenuItem>
                    <MenuItem onClick={() => navigate('/landingOrderManagement')}>
                        Order Management
                    </MenuItem>
                    <MenuItem onClick={() => navigate('/landingAnalytics')}>Analytics</MenuItem>
                </Menu>
            )}
            {option === 'Services' && (
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => navigate('/forwarding')}>Freight Forwarding</MenuItem>
                    <MenuItem onClick={() => navigate('/customs')}>Customs</MenuItem>
                    <MenuItem onClick={() => navigate('/trucking')}>Trucking</MenuItem>
                </Menu>
            )}
            {option === 'Sort by' && (
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => DropDownProps.optionClickFunc(1)}>Price</MenuItem>
                    <MenuItem onClick={() => DropDownProps.optionClickFunc(2)}>Stops</MenuItem>
                    <MenuItem onClick={() => DropDownProps.optionClickFunc(3)}>ETD</MenuItem>
                    <MenuItem onClick={() => DropDownProps.optionClickFunc(4)}>Transit Time</MenuItem>
                </Menu>
            )}
        </div>
    )
}
