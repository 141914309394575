import ActiveBkForm from '../adminForms/activeBkForm'
import { GenericPopup } from './genericPopup'

export const ActiveBkPopup = (props) => {
    return (
        <GenericPopup
            title={'Task Page'}
            open={props.open}
            onSave={props.onSave}
            onCancel={props.onCancel}
        >
            <ActiveBkForm row={props.row} onChange={props.onEdit} />
        </GenericPopup>
    )
}
