import * as constantsTypes from './constants.d'
import * as oceanPorts from 'ocean-ports'

// Stlying related constants
export const APP_HEADER_HEIGHT_PX = 80
export const APP_SIDE_DRAWER_WIDTH_PX = 256

// Search related constants
// TODO: clean up and make more accurate

export const BOOKING_TYPES = ['', 'LCL', 'FCL', 'AIR', 'CUSTOMS ONLY']

// pending
export const STAGE_CUSTOMER_PLACED_BOOKING = 'stage_customer_placed_booking'
export const STAGE_TRANISPORT_PLACED_BOOKING = 'stage_tranisport_placed_booking'
// active
const STAGE_BOOKING_CONFIRMED = 'stage_booking_confirmed'
const STAGE_EMPTY_CONTAINER_PICKED_UP = 'stage_empty_container_picked_up'
const STAGE_CONTAINER_LOADING = 'stage_container_loading'
const STAGE_VGM_SUBMISSION = 'stage_vgm_submission'
const STAGE_SI_SUBMISSION = 'stage_si_submission'
const STAGE_CONTAINER_BOARDED = 'stage_container_boarded'
const STAGE_CONTAINER_IN_TRANSIT = 'stage_container_in_transit'
const STAGE_CONTAINER_ARRIVED = 'stage_container_arrived'
const STAGE_CUSTOMS_CLEARANCE_PENDING = 'stage_customs_clearance_pending'
const STAGE_CUSTOMS_CLEARANCE_COMPLETED = 'stage_customs_clearance_completed'
const STAGE_TRUCKING_CONTAINER_PICK_UP_PENDING = 'stage_trucking_container_pick_up_pending'
const STAGE_TRUCKING_CONTAINER_IN_TRANSIT = 'stage_trucking_container_in_transit'
// completed
export const STAGE_CONTAINER_DELIVERED = 'stage_container_delivered'

// NB: stages are strictly ordered
export const STAGES = new Map([
    [STAGE_CUSTOMER_PLACED_BOOKING, 'Booking placed by customer'],
    [STAGE_TRANISPORT_PLACED_BOOKING, 'Booking placed by Transiport'],
    [STAGE_EMPTY_CONTAINER_PICKED_UP, 'Empty container picked up (post CRO)'],
    [STAGE_BOOKING_CONFIRMED, 'Booking confirmed'],
    [STAGE_CONTAINER_LOADING, 'Goods loading on container'],
    [STAGE_VGM_SUBMISSION, 'VGM submission in process'],
    [STAGE_SI_SUBMISSION, 'SI submission in process'],
    [STAGE_CONTAINER_BOARDED, 'Container boarded on vessel'],
    [STAGE_CONTAINER_IN_TRANSIT, 'Container in transit (ocean)'],
    [STAGE_CONTAINER_ARRIVED, 'Container arrived at port'],
    [STAGE_CUSTOMS_CLEARANCE_PENDING, 'Container pending customs clearance'],
    [STAGE_CUSTOMS_CLEARANCE_COMPLETED, 'Container cleared customs'],
    [STAGE_TRUCKING_CONTAINER_PICK_UP_PENDING, 'Container waiting truck pick-up'],
    [STAGE_TRUCKING_CONTAINER_IN_TRANSIT, 'Container in transit (truck)'],
    [STAGE_CONTAINER_DELIVERED, 'Container delivered'],
])

export const TRADES = ['', 'Import', 'Export']
export const [YES, NO] = ['Yes', 'No']
export const YES_NO = ['', YES, NO]
export const INCOTERMS = ['', 'FOB', 'EXW', 'FCA', 'CFR', 'FAS', 'CIF', 'CPT', 'CIP', 'DAT', 'DAP', 'DDP']
export const IMPORT_INCOS = ['', 'FOB', 'EXW', 'FCA', 'FAS']
export const EXPORT_INCOS = ['', 'FOB', 'CIF', 'CFR', 'CPT', 'CIP', 'DAT', 'DAP', 'DDP']
export const SUPPORTED_ORIGIN_PORTS = [
    'Shanghai',
    'Qingdao',
    'Ningbo',
    'Tiajin',
    'Guangzhou',
    'Shenzhen',
    'Dalian',
    'Hong Kong',
    'Hamburg',
    'Antwerp',
    'Rotterdam',
    'Felixstowe',
    'Los Angeles',
    'New York',
    'Sydney',
]
export const SUPPORTED_DESTINATION_PORTS = ['Karachi', 'Port Qasim']
export const CONTAINERS = [
    "20' Dry Standard",
    "40' Dry Standard",
    "40' Dry High",
    "45' Dry High",
    "20' Tank",
    "40' Tank",
    "20' Reefer Standard",
    "40' Reefer High",
    "20' Open Top",
    "40' Open Top",
    "40' Open Top High",
    "20' Flat",
    "'40' Flat Standard",
    "40' Flat High",
    "40' Collapsible Flat Rack",
]

// TODO: redo
// User data related constants
export const ALLOW_READ = 'ALLOW_READ'
export const ALLOW_WRITE = 'ALLOW_WRITE'
export const USER_DATA_LOC_PREFIX = 'DIR_PREFIX'
export const USER_DATA_LOC_SUFFIX = 'DIR_SUFFIX'
export const LOGIN_CONTEXT = 'LOGIN'
export const SIGNUP_CONTEXT = 'SIGNUP'
export const CONTEXT_DICT: constantsTypes.Contexts = {
    [LOGIN_CONTEXT]: {
        [ALLOW_READ]: true,
        [ALLOW_WRITE]: false,
        [USER_DATA_LOC_PREFIX]: 'users',
        [USER_DATA_LOC_SUFFIX]: '',
    },
    [SIGNUP_CONTEXT]: {
        [ALLOW_READ]: false,
        [ALLOW_WRITE]: true,
        [USER_DATA_LOC_PREFIX]: 'users',
        [USER_DATA_LOC_SUFFIX]: '',
    },
}

export const EMPTY_CURRENT_BOOKING: constantsTypes.CurrentBookingInfoT = {
    uuid: null,
    invoice_num: null,
    origin: null,
    dest: null,
    depart_date: null,
    arrival_date: null,
    shipping_line: null,
    vessel_num: null,
    vessel_name: null,
    is_delayed: null,
    delayed_by: null,
    booking_type: null,
    is_transshipment: null,
    stops: null,
    eta_stops: null,
    etd_stops: null,
    documents: null,
    seller_details: null,
    consignee_details: null,
    total_price_usd: null,
    freight_paid: null,
    payment_date: null,
    add_customs: null,
    customs_price_usd: null,
    add_trucking: null,
    trucking_price_usd: null,
    is_pending: null,
}

// TODO: Something cleaner. There's a lot of data here.
export const PORTS: constantsTypes.oceanPortT[] = [<constantsTypes.oceanPortT>{}]
oceanPorts.JSON.forEach((item) => {
    if (item.type == 'port') {
        // NB: lib doesn't port qasim correct unloc
        if (item.unlocs == 'PKBQM') {
            item.unlocs = 'PKQCT'
        }
        PORTS.push(item)
    }
})
