import * as React from 'react'
import * as searchResInterfaces from '../Components/resultInterface'
import * as constantsTypes from '../Util/constants.d'

export interface Shipment {
    type: string
}

export interface TradeMode {
    mode: string
}

export interface Weight {
    weight: number
}

export interface SearchRequest {
    id: number
    type: Shipment
    mode: TradeMode
    source?: number
    destination?: number
    location?: number
    inco?: string
    date: Date
    container?: string
    quantity: number
    weight: number
    volume?: number
    commodity?: string
}

export interface BookingRequest {
    id: number
    carrier: string
    transitTime: number
    departDate: Date
    expArrival: Date
}

export interface Charges {
    total: number
    freight: number
    terminalHandling: number
    delOrderFee: number
    billOfLadingFee: number
    customsFee: number
}

//Implementation
export const useShipment = (initial: Shipment) => {
    return React.useState<Shipment>(initial)
}
export type UseShipmentType = ReturnType<typeof useShipment>
export type ShipmentType = UseShipmentType[0]
export type SetShipmentType = UseShipmentType[1]

export const useWeight = (initial: Weight) => {
    return React.useState(initial)
}
export type UseWeightType = ReturnType<typeof useWeight>
export type WeightType = UseWeightType[0]
export type SetWeighttType = UseWeightType[1]

export const useTradeMode = (initial: TradeMode) => {
    return React.useState<TradeMode>(initial)
}
export type UseTradeModeType = ReturnType<typeof useTradeMode>
export type TradeModeType = UseShipmentType[0]
export type SetTradeModeType = UseShipmentType[1]

export const useSearchRequest = (initial: SearchRequest) => {
    return React.useState<SearchRequest>(initial)
}
export type UseSearchRequestType = ReturnType<typeof useSearchRequest>
export type SearchRequestType = UseSearchRequestType[0]
export type SetSearchRequestType = UseSearchRequestType[1]

export const useResult = (initial: searchResInterfaces.Offer) => {
    return React.useState<searchResInterfaces.Offer>(initial)
}
export type UseResultReturnType = ReturnType<typeof useResult>
export type ResultReturnType = UseResultReturnType[0]
export type SetResultReturnType = UseResultReturnType[1]

export const useBookingRequest = (initial: BookingRequest) => {
    return React.useState<BookingRequest>(initial)
}
export type UseBookingRequestType = ReturnType<typeof useBookingRequest>
export type BookingRequestType = UseBookingRequestType[0]
export type SetBookingRequestType = UseBookingRequestType[1]

export const useCharges = (initial: Charges) => {
    return React.useState<Charges>(initial)
}
export type UseChargesType = ReturnType<typeof useCharges>
export type ChargesType = UseChargesType[0]
export type SetChargesType = UseChargesType[1]

const ResultContext = React.createContext<UseResultReturnType | null>(null)

export const useResultContext = () => {
    return React.useContext(ResultContext)
}
export const ResultProvider = (
    { children }: { children: React.ReactNode },
    result: searchResInterfaces.Offer,
    key: number,
) => {
    return (
        <ResultContext.Provider value={useResult(result)} key={key}>
            {children}
        </ResultContext.Provider>
    )
}

// ============== APP CONTEXT ============== //
export interface BasicAppStateT {
    uuid: string
    loading: boolean
    appMode: string // prod/dev
}

const genCurrentBookingState = (initial: constantsTypes.CurrentBookingInfoT) => {
    return React.useState<constantsTypes.CurrentBookingInfoT>(initial)
}
export type CurrentBookingStateT = ReturnType<typeof genCurrentBookingState>
export type CurrentBookingStateSetterT = CurrentBookingStateT[1]

const currentBookingContext = React.createContext<CurrentBookingStateT | null>(null)

export const useCurrentBookingContext = () => {
    return React.useContext(currentBookingContext)
}
export const currentBookingContextProvider = (
    { children }: { children: React.ReactNode },
    result: constantsTypes.CurrentBookingInfoT,
    key: number | null = null,
) => {
    return (
        <currentBookingContext.Provider value={genCurrentBookingState(result)} key={key}>
            {children}
        </currentBookingContext.Provider>
    )
}

// TODO: add switcheable callback, and if can't switch then add cases
// to handle different scenarios
const GenBasicInfoState = (initial: BasicAppStateT) => {
    return React.useState<BasicAppStateT>(initial)
}
export type UserBasicInfoStateObjT = ReturnType<typeof GenBasicInfoState>
export type UserBasicInfoSetterT = UserBasicInfoStateObjT[1]

const appContext = React.createContext<UserBasicInfoStateObjT | null>(null)

export const useAppContext = () => {
    return React.useContext(appContext)
}
export const appContextProvider = (
    { children }: { children: React.ReactNode },
    result: BasicAppStateT,
    key: number | null = null,
) => {
    return (
        <appContext.Provider value={GenBasicInfoState(result)} key={key}>
            {children}
        </appContext.Provider>
    )
}
