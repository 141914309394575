import { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'

export const GenericPopup = (props) => {
    const [loading, setLoading] = useState<boolean>(false)

    const onSave = async () => {
        setLoading(true)
        await props.onSave()
    }

    return (
        <Dialog open={props.open} onClose={props.onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
            <DialogContent>{props.children}</DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel} color="secondary">
                    Cancel
                </Button>
                <LoadingButton
                    color="primary"
                    onClick={onSave}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
