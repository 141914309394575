import { Grid } from '@mui/material'
import AdminDrawer from '../../Components/adminComponents/adminDrawer'
import TruckTable from '../../Components/adminComponents/adminTables/truckTable'
import { APP_HEADER_HEIGHT_PX, APP_SIDE_DRAWER_WIDTH_PX } from '../../Util/constants'

export default function Customers() {
    return (
        <Grid container maxWidth="xl">
            <AdminDrawer />
            <Grid
                container
                sx={{
                    pt: `${APP_HEADER_HEIGHT_PX}px`,
                    pl: { sm: `${APP_SIDE_DRAWER_WIDTH_PX - 60}px`, xs: 10 },
                    pr: { sm: '10vw' },
                    justifyContent: 'center',
                }}
            >
                <TruckTable />
            </Grid>
        </Grid>
    )
}
