import { GenericPopup } from './genericPopup'
import { setupNewCustomerProfile, updateCustomerInfo, signupCustomer } from '../../../Util/adminFirebase'
import {
    formatCustomerProfile,
    formatCustomerTransiportData,
    createNewCustomerProfile,
    customerProfileT,
} from '../../../Util/adminUtil'
import { CustomerForm } from '../adminForms/customerForm'

export const CustomerPopup = (props) => {
    const onSave = async () => {
        const formData = props.row
        let uuid = formData.uuid
        let err = false
        if (!uuid) {
            // signup user with email/pwd combo
            try {
                const { user } = await signupCustomer(formData.email, '123456')
                uuid = user.uid
                // create customer profile
                const newCustomerProfile: customerProfileT = createNewCustomerProfile(formData)
                await setupNewCustomerProfile(uuid, newCustomerProfile)
            } catch (error) {
                err = true
                const errorCode = error.code
                const errorMessage = error.message
                console.log(errorCode, errorMessage)
            }
        }
        if (!err) {
            // write user data to firestore
            try {
                await updateCustomerInfo(
                    uuid,
                    formatCustomerProfile(formData),
                    formatCustomerTransiportData(formData),
                )
            } catch (error) {
                console.log(error)
            }
        }
        props.onSave(formData)
    }
    return (
        <GenericPopup
            title={'Customer Details'}
            open={props.open}
            onSave={onSave}
            onCancel={props.onCancel}
        >
            <CustomerForm row={props.row} onChange={props.onEdit} />
        </GenericPopup>
    )
}
