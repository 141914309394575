import { useState, useEffect } from 'react'
import { Box, CircularProgress, Paper, Typography } from '@mui/material'
import {
    RowDetailState,
    SearchState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
} from '@devexpress/dx-react-grid'
import {
    Grid,
    PagingPanel,
    Table,
    TableHeaderRow,
    TableRowDetail,
    Toolbar,
    SearchPanel,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import { loadBookings } from '../Util/adminFirebase'
import { useAppContext } from '../Components/store'

interface basicBookingInfo {
    bookNo: string
    origin: string
    dest: string
    start: string
    end: string
    currentPort: string
    status: string
}

interface trackingTablePropsT {
    bookings: string[]
}

export default function TrackingTable(props: trackingTablePropsT) {
    const [searchValue, setSearchState] = useState('')
    const [loading, setLoading] = useState<boolean>(true)
    const [pageSizes] = useState([5, 10, 15, 0])
    const [rows, setRows] = useState<basicBookingInfo[]>([])
    const [columns] = useState([
        { name: 'bookNo', title: 'Booking #' },
        { name: 'origin', title: 'Origin' },
        { name: 'dest', title: 'Destination' },
        { name: 'start', title: 'Depart Date' },
        { name: 'end', title: 'Arrival Date' },
        { name: 'currentPort', title: 'Last Location' },
        { name: 'status', title: 'Status' },
    ])
    const TableHeaderComponent = (props) => (
        <VirtualTable.TableHead {...props} style={{ backgroundColor: 'aliceblue' }} />
    )
    const ContainerComponent = (props) => (
        <VirtualTable.Table {...props} style={{ height: 'min-content' }} />
    )
    const HeaderContent = (props) => (
        <TableHeaderRow.Row {...props} style={{ fontWeight: 'bold', fontSize: '2vh' }} />
    )
    const appState = useAppContext()
    // Equivalent to component did mount
    useEffect(() => {
        const loadBookingsInfo = async (bookings: string[]) => {
            setLoading(true)
            if (bookings) {
                const newRows = []
                const docSnaps = await loadBookings(bookings)
                docSnaps.forEach((item) => {
                    const itemData = item.data()
                    newRows.push({
                        bookNo: itemData.bk_id,
                        origin: itemData.origin,
                        dest: itemData.dest,
                        start: itemData.depart_date,
                        end: itemData.arrival_date,
                        currentPort: itemData.current_stop,
                        status: itemData.is_delayed ? `Delayed (by ${itemData.delayed_by})` : 'On Time',
                    })
                })
                setRows(newRows)
            }
            setLoading(false)
        }
        loadBookingsInfo(props.bookings)
    }, [props])

    const renderTable = () => {
        if (loading) {
            return (
                <Box
                    sx={{
                        width: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        ml: '30vw',
                        mt: '10%',
                    }}
                >
                    <CircularProgress />
                    <Typography sx={{ ml: '-0.6vw', mt: '1%' }}>Loading</Typography>
                </Box>
            )
        } else {
            return (
                <Paper sx={{ bgcolor: '#F5F5F5', ml: '5%', mr: '5%' }}>
                    <Grid rows={rows} columns={columns}>
                        <PagingState defaultCurrentPage={0} defaultPageSize={5} />
                        <IntegratedPaging />
                        <SearchState value={searchValue} onValueChange={setSearchState} />
                        <IntegratedFiltering />
                        <VirtualTable
                            headComponent={TableHeaderComponent}
                            containerComponent={ContainerComponent}
                        />
                        <TableHeaderRow contentComponent={HeaderContent} />
                        <Toolbar />
                        <SearchPanel />
                        <PagingPanel pageSizes={pageSizes} />
                    </Grid>
                </Paper>
            )
        }
    }

    return renderTable()
}
