import { TextField } from '@mui/material'
import MuiGrid from '@mui/material/Grid'
import { TportDatePicker } from './reusableForms'

export default function TruckRatesForm(row, onChange) {
    return (
        <MuiGrid container spacing={3}>
            <MuiGrid item xs={6}>
                <TextField
                    fullWidth
                    name="origin"
                    label="Origin"
                    value={row.origin || ''}
                    onChange={onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <TextField
                    fullWidth
                    name="destination"
                    label="Destination"
                    value={row.destination || ''}
                    onChange={onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <TextField
                    fullWidth
                    name="trucking_price_usd"
                    label="Trucking Price"
                    value={row.trucking_price_usd || ''}
                    onChange={onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <TportDatePicker
                    name={'truck_quote_date'}
                    label={'Quote Date'}
                    value={row.truck_quote_date}
                    onChange={onChange}
                />
            </MuiGrid>
        </MuiGrid>
    )
}
