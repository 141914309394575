import Box from '@mui/material/Box'
import AccountAppBar from '../Components/resultsAppBar'
import PermanentDrawer from '../Components/permanentDrawer'
import { appBarWidth, drawerWidth } from '../App'
import ServiceInfo from '../Components/serviceInfo'
import RouteTimeline from '../Components/routeTimeline'
import BookingInfo from '../Components/bookingDetails'
import { useCurrentBookingContext } from '../Components/store'
import Typography from '@mui/material/Typography'

interface BookingConfProps {
    bookingNo: string
    phoneNo: string
}

export default function ReceiptSummary(confirmation: BookingConfProps) {
    const [currentBookingContext] = useCurrentBookingContext()
    return (
        <Box sx={{ position: 'relative', display: 'flex', maxWidth: `100vw`, height: '100vh' }}>
            <PermanentDrawer />
            <Box
                position="absolute"
                sx={{
                    ml: `${drawerWidth}px`,
                    mt: `${appBarWidth}px`,
                    marginRight: '2.5%',
                    width: '83%',
                    display: 'block',
                    flexBasis: 'auto',
                }}
            >
                <Typography
                    sx={{
                        pt: '2%',
                        ml: 2,
                        fontFamily: 'calibri-light',
                        borderBottom: 1,
                        borderColor: 'divider',
                        color: 'navy',
                    }}
                    variant="h4"
                >
                    Booking Confirmation
                </Typography>

                <Typography sx={{ fontSize: 20, ml: '22%', mt: '10%' }}>
                    You booking has been received. We are coordinating with the shipping line
                </Typography>
                <Typography sx={{ fontSize: 20, ml: '22%', }}>
                    to place your order. Your booking reference number is {currentBookingContext.bk_id}. Please
                </Typography>
                <Typography sx={{ fontSize: 20, ml: '22%' }}>
                    contact us at +92-345-4830000 if you have any questions.
                </Typography>
            </Box>
        </Box>
    )
}
