import {
    Box,
    Checkbox,
    Container,
    CssBaseline,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { signupCustomer, setupNewCustomerProfile } from '../Util/adminFirebase'
import { APP_HEADER_HEIGHT_PX, SIGNUP_CONTEXT } from '../Util/constants'
import LandingAppBar from '../Components/landingComponents/landingAppBar'

export default function Registration() {
    const volsMenu = ['0 to 25', '25 to 50', '50 to 100']
    const industryMenu = ['Automotive', 'Textile', 'Construction']
    const [expectedVol, setExpectedVol] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [company, setCompany] = useState<string>('')
    const [industry, setIndustry] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [errMsg, setErrMsg] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const navigate = useNavigate()

    // TODO: move to util file
    const checkPwdValid = (pwd: string, confirmPwd: string) => {
        if (!(pwd.length >= 6) || !(confirmPwd.length >= 6)) {
            return false
        }
        return pwd === confirmPwd
    }

    // TODO: move to util. Actually, add a validator
    const checkEmailValid = (email: string) => {
        if (!email.includes('@')) {
            return false
        }
        const splitEmail = email.split('@')
        if (splitEmail.length != 2) {
            return false
        }
        if (!splitEmail[1].includes('.')) {
            return false
        }
        return true
    }

    const validateForm = () => {
        // setState is async, hence the smattering of returns
        if (!checkEmailValid(email)) {
            setErrMsg('Invalid email address.')
            return false
        } else if (!checkPwdValid(password, confirmPassword)) {
            setErrMsg(
                'Error in passwords. Password must be at-least 6 characters long. Please fill and confirm match.',
            )
            return false
        } else if (!(name && company && industry && phone && expectedVol)) {
            setErrMsg('Please fill out all the fields')
            return false
        } else if (errMsg != '') {
            setErrMsg('')
        }
        return true
    }

    const handleSubmit = async () => {
        if (!validateForm()) {
            return
        }
        setLoading(true)
        let uuid = ''
        // signup user with email/pwd combo
        try {
            const { user } = await signupCustomer(email, password)
            uuid = user.uid
        } catch (error) {
            const errorCode = error.code
            const errorMessage = error.message
            console.log(errorCode, errorMessage)
            setErrMsg(errorCode)
            setLoading(false)
        }
        // write user data to firestore
        try {
            // TODO: basic profile interface import from adminUtil
            const basicUserProfile = {
                email: email,
                name: name,
                company: company,
                industry: industry,
                phone: phone,
                expectedVol: expectedVol,
                detailsVerified: false,
            }
            await setupNewCustomerProfile(uuid, basicUserProfile)
            navigate('/verification')
        } catch (error) {
            const errorCode = error.code
            const errorMessage = error.message
            console.log(errorCode, errorMessage, error)
            setLoading(false)
        }
    }

    const renderErrMsg = () => {
        if (errMsg) {
            return <Typography sx={{ fontSize: 16, color: 'red' }}>{errMsg}</Typography>
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <LandingAppBar />
            <Box
                sx={{
                    paddingTop: `calc(${APP_HEADER_HEIGHT_PX + 10}px)`,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5" color="navy">
                    Sign up
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="email-address"
                                name="email"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    setEmail(event.target.value)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    setPassword(event.target.value)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="confirm-password"
                                label="Confirm Password"
                                type="password"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    setConfirmPassword(event.target.value)
                                }
                                name="confirmPassword"
                                autoComplete="confirm-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="fullname"
                                label="Full Name"
                                type="string"
                                id="name"
                                autoComplete="full-name"
                                onChange={(event) => setName(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="company"
                                label="Company Name"
                                type="string"
                                id="company"
                                autoComplete="company-name"
                                onChange={(event) => setCompany(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={industry}
                                id="Industry"
                                select
                                label="Industry"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    setIndustry(event.target.value)
                                }
                                required
                                fullWidth
                            >
                                {industryMenu.map((ind, idx) => (
                                    <MenuItem key={idx} value={ind}>
                                        {ind}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={expectedVol}
                                id="volume"
                                select
                                label="Expected volume per month"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    setExpectedVol(event.target.value)
                                }
                                required
                                fullWidth
                            >
                                {volsMenu.map((option, idx) => (
                                    <MenuItem key={idx} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={phone}
                                id="phone"
                                label="Phone Number"
                                type="number"
                                required
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    setPhone(event.target.value)
                                }
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="Email me alerts, industry insights and updates."
                            />
                        </Grid>
                    </Grid>
                    <LoadingButton
                        loading={loading}
                        type="submit"
                        fullWidth
                        onClick={handleSubmit}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        CREATE FREE ACCOUNT
                    </LoadingButton>
                    {renderErrMsg()}
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link to="/login">Already have an account? Sign in</Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}
