import { useState } from 'react'
import { createRateSheet, uploadRateSheet } from '../../../Util/adminFirebase'
import RateSheetForm from '../adminForms/rateSheetForm'
import { GenericPopup } from './genericPopup'

export const RateSheetPopup = (props) => {
    const [selectedFile, setSelectedFile] = useState<File>()
    const [selectedFileName, setSelectedFileName] = useState('')
    const onSelect = (event) => {
        setSelectedFile(event.target.files[0])
        setSelectedFileName(event.target.name)
    }

    const onSave = () => {
        createRateSheet(props.customers[0], selectedFileName, selectedFile)
        uploadRateSheet(props.customers[0], selectedFileName, selectedFile)
        console.log(props.row.rate_sheet)
    }
    return (
        <GenericPopup
            title={'Rate Sheet Details'}
            open={props.open}
            onSave={onSave}
            onCancel={props.onCancel}
        >
            <RateSheetForm row={props.row} onChange={props.onEdit} onSelect={onSelect} />
        </GenericPopup>
    )
}
