import { Box, Checkbox, CssBaseline, FormControlLabel, Paper, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Link, useNavigate } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import { useState } from 'react'
import { login, loadUserData, useAuth, useAdminAuth } from '../Util/adminFirebase'
import { APP_HEADER_HEIGHT_PX } from '../Util/constants'
import { useAppContext } from '../Components/store'
import LoginPic from '../Assets/logonPhoto.jpg'
import LandingAppBar from '../Components/landingComponents/landingAppBar'

export default function LoginPage() {
    const [loading, setLoading] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [emailError, setEmailError] = useState<boolean>(false)
    const [passwordError, setPasswordError] = useState<boolean>(false)

    const appState = useAppContext()
    useAuth(appState)

    const navigate = useNavigate()
    // Export to util file
    const emailValid = (emailAddr: string) => {
        if (!emailAddr) {
            return false
        }
        return true
    }
    const handleSubmit = async () => {
        setEmailError(!emailValid(email))
        setPasswordError(!password)
        if (!emailValid(email) || !password) {
            return
        }
        setLoading(true)
        // try to login user
        let uuid = ''
        try {
            const { user } = await login(email, password)
            // TODO: add UUID to global context
            uuid = user.uid
            console.log('logged in: ', user)
        } catch (error) {
            const errorCode = error.code
            const errorMessage = error.message
            console.log(errorCode, errorMessage)
            setLoading(false)
            return
        }
        // load global user data/data for dashboard
        try {
            const docSnapshot = await loadUserData(uuid)
            if (docSnapshot.exists()) {
                const userData = docSnapshot.data()
                if (userData.is_verified || userData.detailsVerified) {
                    // TODO: route to user dashboard
                    navigate('/search')
                } else {
                    navigate('/verification')
                }
            } else {
                // doc.data() will be undefined in this case
                // TODO: Throw error and panic!
                console.log('No such document!')
            }
        } catch (error) {
            const errorCode = error.code
            const errorMessage = error.message
            console.log(errorCode, errorMessage, error)
            setLoading(false)
        }
    }

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <LandingAppBar />
            <Grid
                item
                xs={false}
                sm={4}
                md={8}
                sx={{
                    backgroundImage: `url(${LoginPic})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'left center',
                }}
            />
            <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        pt: `calc(${APP_HEADER_HEIGHT_PX + 4}px)`,
                    }}
                >
                    <Typography component="h1" variant="h5" color={'navy'}>
                        Sign in
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setEmail(event.target.value)
                                setEmailError(!event.target.value)
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setPassword(event.target.value)
                                setPasswordError(!event.target.value)
                            }}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <LoadingButton
                            loading={loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </LoadingButton>
                        <Grid container>
                            {/* <Grid item xs={4}>
                                <Link to="/">Forgot password?</Link>
                            </Grid> */}
                            <Grid item xs={4}>
                                <Link to="/register">{'Sign Up'}</Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}
