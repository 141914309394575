import { useState } from 'react'
import Paper from '@mui/material/Paper'
import {
    SearchState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
} from '@devexpress/dx-react-grid'
import {
    Grid,
    PagingPanel,
    Table,
    TableHeaderRow,
    Toolbar,
    SearchPanel,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
interface basicInvoiceInfo {
    balanceDue: string
    invoiceNum: string
    totalAmount: string
    bookingNo: string
}

interface invoiceTablePropsT {
    invoices: basicInvoiceInfo[]
}

export default function InvoiceTable(props: invoiceTablePropsT) {
    const columns = [
        { name: 'bookingNo', title: 'Booking #' },
        { name: 'invoiceNum', title: 'Invoice #' },
        { name: 'totalAmount', title: 'Amount' },
        { name: 'balanceDue', title: 'Balance' },
    ]
    const [searchValue, setSearchState] = useState('')
    const [pageSizes] = useState([5, 10, 15, 0])

    const TableHeaderComponent = (props) => (
        <VirtualTable.TableHead {...props} style={{ backgroundColor: 'aliceblue' }} />
    )
    const ContainerComponent = (props) => (
        <VirtualTable.Table {...props} style={{ height: 'min-content' }} />
    )
    const HeaderContent = (props) => (
        <TableHeaderRow.Row {...props} style={{ fontWeight: 'bold', fontSize: '2vh' }} />
    )

    return (
        <Paper sx={{ bgcolor: '#F5F5F5' }}>
            <Grid rows={props.invoices} columns={columns}>
                <PagingState defaultCurrentPage={0} defaultPageSize={5} />
                <IntegratedPaging />
                <SearchState value={searchValue} onValueChange={setSearchState} />
                <IntegratedFiltering />
                <VirtualTable
                    headComponent={TableHeaderComponent}
                    containerComponent={ContainerComponent}
                />
                <TableHeaderRow contentComponent={HeaderContent} />
                <Toolbar />
                <SearchPanel />
                <PagingPanel pageSizes={pageSizes} />
            </Grid>
        </Paper>
    )
}
