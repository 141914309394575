import { Grid } from '@mui/material'
import AdminDrawer from '../../Components/adminComponents/adminDrawer'
import TaskTabs from '../../Components/adminComponents/taskTabs'
import { APP_HEADER_HEIGHT_PX, APP_SIDE_DRAWER_WIDTH_PX } from '../../Util/constants'
import { getActiveBookings, getAttentionRequiredBookings, loadBookings } from '../../Util/adminFirebase'
import { useEffect, useState } from 'react'
import { LoadingWheel } from '../../Components/loadingWheel'

export default function TaskPage() {
    const [loading, setLoading] = useState(false)
    const [activeBookings, setActiveBookings] = useState({})
    const [attentionBookings, setAttentionBookings] = useState({})
    // load data
    // TODO: check for data in local storage, and refresh as needed
    // TODO: refactor above as a base component startup thing
    useEffect(() => {
        const loadAllData = async () => {
            const promises = []
            // TODO: use app state to verify admin
            setLoading(true)
            // load active bookings
            promises.push(
                getActiveBookings().then((bookingsInfo) => {
                    const bookings = Object.keys(bookingsInfo.data())
                    loadBookings(bookings).then((bookingsDocs) => {
                        const activeBks = {}
                        bookingsDocs.forEach((bkInfoRaw) => {
                            const bkInfo = bkInfoRaw.data()
                            activeBks[bkInfo.bk_id] = bkInfo
                        })
                        setActiveBookings(activeBks)
                    })
                }),
            )
            // load attention required bookings
            promises.push(
                getAttentionRequiredBookings().then((bookingsInfo) => {
                    const bookings = Object.keys(bookingsInfo.data())
                    loadBookings(bookings).then((bookingDocs) => {
                        const attentionBks = {}
                        bookingDocs.forEach((bkInfoRaw) => {
                            const bkInfo = bkInfoRaw.data()
                            attentionBks[bkInfo.bk_id] = bkInfo
                        })
                        setAttentionBookings(attentionBks)
                    })
                }),
            )
            await Promise.all(promises)
            setLoading(false)
        }
        loadAllData()
    }, [])
    return (
        <Grid container maxWidth="xl">
            <AdminDrawer />
            <Grid
                container
                sx={{
                    pt: `${APP_HEADER_HEIGHT_PX}px`,
                    pl: { sm: `${APP_SIDE_DRAWER_WIDTH_PX - 60}px`, xs: 10 },
                    pr: { sm: '10vw' },
                    justifyContent: 'center',
                }}
            >
                {loading ? (
                    <LoadingWheel />
                ) : (
                    <TaskTabs active={activeBookings} attention={attentionBookings} />
                )}
            </Grid>
        </Grid>
    )
}
