/* eslint-disable prettier/prettier */
import { createTheme } from '@mui/material/styles'
import { APP_HEADER_HEIGHT_PX, APP_SIDE_DRAWER_WIDTH_PX } from './Util/constants'

const globalTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    backgroundColor: '#1976d2',
                    '&:hover': {
                        backgroundColor: '#1976d2',
                    },
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                rounded: {
                    '&:hover': {
                        backgroundColor: '#f0f8ff',
                    },
                },
            },
        },
        MuiAccordionDetails: {
            defaultProps: {},
            styleOverrides: {
                root: {
                   
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                positionFixed: {
                   
                },
            },
        },
        MuiCheckbox: {},
        MuiFormControl: {
            defaultProps: {},
            styleOverrides: {
                root: {
                   
                },
            },
        },
        MuiMenu: {
            defaultProps: {},
            styleOverrides: {
                root: {
                   
                },
            },
        },
        MuiTable: {
            defaultProps: {},
            styleOverrides: {
                root: {
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                standard: {
                   
                },
            },
        },
        MuiAutocomplete: {
            defaultProps: {},
            styleOverrides: {
                root: {
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                   
                },
            },
        },
        MuiCircularProgress: {
            defaultProps: {},
            styleOverrides: {
                root: {
                },
            },
        },
        MuiToggleButton: {
            defaultProps: {},
            styleOverrides: {
                root: {
                },
            },
        },
        MuiToggleButtonGroup: {
            defaultProps: {},
            styleOverrides: {
                root: {
                   
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                expanded: {
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#1976d2',
        },
    },
    
    transitions: {},
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
})

export default globalTheme
