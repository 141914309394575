import { Box, Container, CssBaseline, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import LoadingButton from '@mui/lab/LoadingButton'
import React from 'react'
import LandingAppBar from '../../Components/landingComponents/landingAppBar'
import { APP_HEADER_HEIGHT_PX } from '../../Util/constants'
import Footer from '../../Components/footerComponents/footer'
import { useScrollToTop } from '../../Util/util'

export default function ContactUs() {
    useScrollToTop()
    const [message, setMessage] = React.useState('')
    const [companyName, setCompanyName] = React.useState('')
    const [customerName, setCustomerName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [phoneNumber, setPhoneNumber] = React.useState('')

    return (
        <>
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <LandingAppBar />
                <Box
                    sx={{
                        marginTop: `calc(${APP_HEADER_HEIGHT_PX + 4}px)`,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" color={'navy'}>
                        Contact Us
                    </Typography>
                    <Box
                        component="form"
                        noValidate //onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="customer-name-input"
                                    label="Full Name"
                                    name="customerName"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        setCustomerName(event.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    name="companyName"
                                    label="Company Name"
                                    type="string"
                                    id="companyName"
                                    autoComplete="company-name"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        setCompanyName(event.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email-input"
                                    label="Enter Your Email"
                                    type="email"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        setEmail(event.target.value)
                                    }
                                    name="emailAddress"
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="phone"
                                    label="Phone Number"
                                    type="string"
                                    id="phone"
                                    autoComplete="phone-number"
                                    onChange={(event) => setPhoneNumber(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="message"
                                    label="Message"
                                    type="string"
                                    multiline
                                    rows={10}
                                    id="message"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        setMessage(event.target.value)
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography>Phone Number: (+92) 320-947-2599</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    Email: <a href="mailto:info@transiport.com">info@transiport.com</a>
                                </Typography>
                            </Grid>
                        </Grid>

                        <LoadingButton
                            //loading={loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            //onClick={handleSubmit}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                </Box>
            </Container>
            <Footer />
        </>
    )
}
