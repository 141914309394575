import {
    Box,
    Button,
    CssBaseline,
    Grid,
    Stepper,
    Typography,
    StepConnector,
    Step,
    StepLabel,
} from '@mui/material'
import ShipPic from '../../Assets/shipSun.jpeg'
import LandingAppBar from '../../Components/landingComponents/landingAppBar'
import FreightPic from '../../Assets/freightImage.jpg'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined'
import AllInclusiveOutlinedIcon from '@mui/icons-material/AllInclusiveOutlined'
import Footer from '../../Components/footerComponents/footer'
import { APP_HEADER_HEIGHT_PX } from '../../Util/constants'
import { useEffect } from 'react'
import { useScrollToTop } from '../../Util/util'

export default function Forwarding() {
    const steps = ['Instant Quote', 'Online Booking', 'Order Management', 'Track Shipment']
    useScrollToTop()
    return (
        <Grid container component="main">
            <CssBaseline />
            <LandingAppBar />
            <Grid
                container
                sx={{
                    px: { xs: 2 },
                    height: {
                        xs: `calc(70vh - calc(${APP_HEADER_HEIGHT_PX}px)
                    )`,
                        sm: '100vh',
                    },
                    justifyContent: 'center',
                }}
            >
                <img
                    alt={'Freight'}
                    src={FreightPic}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        left: '50%',
                        top: '50%',
                        height: '100%',
                        objectFit: 'cover',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '-1',
                        filter: 'brightness(50%)',
                    }}
                />
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignSelf: { xs: 'flex-end', sm: 'center' },
                        color: 'white',
                        textAlign: { xs: 'left', sm: 'center' },
                    }}
                >
                    <Typography sx={{ fontSize: { xs: '4vh', sm: '8vh' } }}>
                        Predictable, Reliable Shipments
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '2vh', sm: '4vh' } }}>
                        Your Shipments in your control
                    </Typography>
                    <Button
                        variant="contained"
                        href="/register"
                        sx={{ my: { xs: 2 }, alignSelf: 'center' }}
                    >
                        Sign Up
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={false} sm={12}>
                <Box sx={{ height: '20%', display: { xs: 'none', sm: 'inline' } }}>
                    <Stepper
                        alternativeLabel
                        sx={{ mt: '5%', pb: '7%' }}
                        connector={
                            <StepConnector
                                sx={{
                                    margin: '0!important',
                                    top: 'calc(14.5% )!important',
                                    left: 'calc(-45% )!important',
                                    right: 'calc(55% )!important',
                                    borderTopColor: 'red!important',
                                }}
                            />
                        }
                        activeStep={-1}
                    >
                        {steps.map((label, idx) => (
                            <Step key={idx}>
                                <StepLabel
                                    StepIconProps={{
                                        sx: { m: '0!important', fontSize: 24, color: '#04012b' },
                                    }}
                                >
                                    <Typography
                                        sx={{ m: '0!important', mb: '5%', color: '#04012b' }}
                                        fontSize={24}
                                    >
                                        {label}
                                    </Typography>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
            </Grid>
            <Grid
                container
                sx={{
                    bgcolor: '#04012b',
                    color: 'white',
                    pt: { xs: '2vh' },
                    height: { xs: '100vh', sm: 'auto' },
                }}
            >
                <Grid item xs={12} md={4}>
                    <Box sx={{ display: 'stack', textAlign: 'center' }}>
                        <MonetizationOnOutlinedIcon sx={{ fontSize: 100, justifyContent: 'center' }} />
                        <Typography textAlign="center" fontWeight="bold" fontSize={24}>
                            Spot Rates
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box
                        sx={{
                            display: 'stack',
                            alignContent: 'center',
                            textAlign: 'center',
                        }}
                    >
                        <BeenhereOutlinedIcon sx={{ fontSize: 100 }} />
                        <Typography textAlign="center" fontWeight="bold" fontSize={24}>
                            Contract Rates
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{ display: 'stack', textAlign: 'center' }}>
                        <AllInclusiveOutlinedIcon sx={{ fontSize: 100 }} />
                        <Typography textAlign="center" fontWeight="bold" fontSize={24}>
                            Transparency
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid item sm={12}>
                <Grid
                    container
                    position="relative"
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        flexDirection: 'row',
                        bgcolor: 'white',
                        color: 'black',
                    }}
                >
                    <Grid item sm={6} alignSelf="center">
                        <Typography
                            fontFamily={['Roboto', 'sans-serif']}
                            fontSize={30}
                            textAlign="justify"
                            fontWeight={325}
                            marginX={2}

                        >
                            View and Choose Shipments that fit your needs
                        </Typography>
                    </Grid>

                    <Grid item sm={6} alignSelf="center">
                        <img
                            alt={'Freight'}
                            src={ShipPic}
                            height={'100%'}
                            width={'100%'}
                            style={{
                                position: 'static',
                                alignContent: 'left',
                                objectFit: 'cover',
                                filter: 'brightness(80%)',
                                //boxShadow: '15px -20px 45px #000640',
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Footer />

        </Grid>
    )
}
