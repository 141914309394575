import TruckBrokerForm from '../adminForms/truckBrokerForm'
import { GenericPopup } from './genericPopup'

export const TruckingPopup = (props) => {
    return (
        <GenericPopup
            title={'Broker Details'}
            open={props.open}
            onSave={props.onChange}
            onCancel={props.onCancel}
        >
            <TruckBrokerForm row={props.row} onChange={props.onEdit} />
        </GenericPopup>
    )
}
