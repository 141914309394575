import { useState } from 'react'
import { TRUCKING_RATES_COLUMNS } from '../adminConstants'
import { GenericTable } from './genericTable'
import { TruckRatesPopup } from '../adminPopups/truckRatesPopup'

export default function TruckRatesTable() {
    const getRowId = (row) => row.id

    const [rows] = useState([])

    return (
        <GenericTable
            columns={TRUCKING_RATES_COLUMNS}
            getRowId={getRowId}
            title={'TRUCK RATES'}
            popup={TruckRatesPopup}
            rows={rows}
        />
    )
}
