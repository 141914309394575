import { useState } from 'react'
import TruckingRates from './truckingRates'
import { TRUCK_TABLE_COLUMNS } from '../adminConstants'
import { GenericTable } from './genericTable'
import { TruckingPopup } from '../adminPopups/truckTablePopup'
import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
// eslint-disable-next-line
/* eslint-disable no-shadow */


export default function TruckTable(props) {
    const getRowId = (row) => row.broker_id

    const [rows] = useState([
        {
            broker_id: '12345',
            truck_broker_name: 'Zunair Khan',
            contact: '03218418411',
            origin: 'Karachi SAPT',
            destination: 'Lahore',
            trucking_price_usd: 150,
            truck_quote_date: '22/12/2022',
        },
    ])

    const RowDetail = ({ row }) => {
        return <TruckingRates />
    }

    return (
        <GenericTable
            columns={TRUCK_TABLE_COLUMNS}
            getRowId={getRowId}
            title={'TRUCK BROKERS'}
            popup={TruckingPopup}
            rows={rows}
            toggleCell={TableRowDetail.ToggleCell}
            toggleRow={RowDetail}
        />
    )
}
