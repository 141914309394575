import { AppBar, Box, Button, Toolbar } from '@mui/material'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import React, { ReactElement } from 'react'
import ProfileDropDown from '../Components/profileDropDownMenu'
import Logo from '../Assets/transiport_logo.jpg'

const appBarWidth = 90

//TODO Add Link to previous page
//TODO Add breadcrumbs

interface ButtonLinkProps {
    primary: ReactElement<any, any>
    to: string
}

function ButtonLink(props: ButtonLinkProps) {
    const { primary, to } = props

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(function Link(
                itemProps,
                ref,
            ) {
                return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />
            }),
        [to],
    )

    return <Button component={renderLink}>{primary}</Button>
}

export default function AccountAppBar() {
    return (
        <AppBar
            position="fixed"
            sx={{
                height: `${appBarWidth}px`,
                background: '#01264f',
                width: '100%',
            }}
        >
            <Toolbar>
                <Box
                    sx={{
                        mt: '2.5%',
                        ml: '2.5%',
                        mb: '2.5%',
                        height: '100%',
                        width: '10%',
                        display: 'flex',
                        direction: 'row',
                    }}
                >
                    <ButtonLink to="/" primary={<img src={Logo} alt="Logo" height={35} />} />
                </Box>
                <ProfileDropDown />
            </Toolbar>
        </AppBar>
    )
}
