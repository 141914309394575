import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useCurrentBookingContext } from './store'

//

export default function BookingInfo() {
    const [currentBookingContext] = useCurrentBookingContext()
    return (
        <TableContainer
            component={Paper}
            sx={{
                bgcolor: currentBookingContext.customs_only ? 'aliceblue' : '#F5F5F5',
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: '15px',
            }}
        >
            <Table
                sx={{ minWidth: 50 }}
                size={currentBookingContext.customs_only ? 'medium' : 'small'}
                aria-label="service table"
            >
                <TableBody>
                    <TableRow sx={{ '& td': { border: 0 } }}>
                        <TableCell variant="head" sx={{ color: 'gray' }}>
                            SERVICE
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>FCL</TableCell>
                    </TableRow>
                    <TableRow sx={{ '& td': { border: 0 } }}>
                        <TableCell variant="head" sx={{ color: 'gray' }}>
                            COMMODITY
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>General</TableCell>
                    </TableRow>
                    <TableRow sx={{ '& td': { border: 0 } }}>
                        <TableCell variant="head" sx={{ color: 'gray' }}>
                            CONTAINER
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>1 x 40 Ft Dry</TableCell>
                    </TableRow>
                    <TableRow sx={{ '& td': { border: 0 } }}>
                        <TableCell variant="head" sx={{ color: 'gray' }}>
                            {currentBookingContext.customs_only ? 'ETA' : 'INCOTERM'}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                            {currentBookingContext.customs_only
                                ? currentBookingContext.arrival_date
                                : 'FOB'}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
