import { FormGroup, TextField } from '@mui/material'
import MuiGrid from '@mui/material/Grid'

export default function TruckBrokerForm({ row, onChange }) {
    return (
        <MuiGrid container spacing={3}>
            <MuiGrid item xs={12}>
                <FormGroup>
                    <TextField
                        margin="normal"
                        name="truck_broker_name"
                        label="Name"
                        value={row.truck_broker_name || ''}
                        onChange={onChange}
                    />
                    <TextField
                        margin="normal"
                        name="contact"
                        label="Contact"
                        value={row.contact || ''}
                        onChange={onChange}
                    />
                </FormGroup>
            </MuiGrid>
        </MuiGrid>
    )
}
