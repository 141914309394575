import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { useCurrentBookingContext } from './store'

export default function OrderSummary() {
    const [currentBookingContext] = useCurrentBookingContext()
    return (
        <TableContainer
            component={Paper}
            elevation={3}
            sx={{
                bgcolor: currentBookingContext.customs_only ? 'aliceblue' : '#F5F5F5',
                color: 'navy',
                fontWeight: 'bold',
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: '15px',
                height: currentBookingContext.customs_only ? '30vh' : 'auto',
            }}
        >
            <Typography
                sx={{
                    fontWeight: 'bold',
                    pl: '2%',
                    fontSize: currentBookingContext.customs_only ? '4vh' : '2vh',
                }}
            >
                Final Quote
            </Typography>
            <Table
                size={currentBookingContext.customs_only ? 'medium' : 'small'}
                aria-label="service table"
            >
                <TableBody>
                    {!currentBookingContext.customs_only && (
                        <TableRow sx={{ '& td': { border: 0 } }}>
                            <TableCell variant="head" sx={{ color: 'gray' }}>
                                FREIGHT
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: 'navy' }}>
                                {currentBookingContext.total_price_usd.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}
                            </TableCell>
                        </TableRow>
                    )}
                    {(currentBookingContext.add_customs || currentBookingContext.customs_only) && (
                        <TableRow sx={{ '& td': { border: 0 } }}>
                            <TableCell variant="head" sx={{ color: 'gray' }}>
                                CUSTOMS
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: 'navy' }}>
                                {currentBookingContext.customs_price_usd.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}
                            </TableCell>
                        </TableRow>
                    )}

                    {currentBookingContext.add_trucking && (
                        <TableRow sx={{ '& td': { border: 0 } }}>
                            <TableCell variant="head" sx={{ color: 'gray' }}>
                                TRUCKING
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: 'navy' }}>
                                {currentBookingContext.trucking_price_usd.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'PKR',
                                })}
                            </TableCell>
                        </TableRow>
                    )}

                    {currentBookingContext.add_free_days && (
                        <TableRow sx={{ '& td': { border: 0 } }}>
                            <TableCell variant="head" sx={{ color: 'gray' }}>
                                {currentBookingContext.free_days} Days
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: 'navy' }}>
                                {currentBookingContext.free_days_price_usd.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}
                            </TableCell>
                        </TableRow>
                    )}

                    <TableRow sx={{ '& td': { border: 0 } }}>
                        <TableCell variant="head" sx={{ color: 'gray' }}>
                            TOTAL AMOUNT DUE
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: 'navy' }}>
                            {(
                                currentBookingContext.total_price_usd +
                                currentBookingContext.trucking_price_usd / 219.45 +
                                currentBookingContext.free_days_price_usd +
                                currentBookingContext.customs_price_usd
                            ).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
