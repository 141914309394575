import * as React from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material/'
import TaskTable from './adminTables/activeBkTable'

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export default function BookingsTabs(props) {
    const [value, setValue] = React.useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="task tabs">
                    <Tab label="Active" {...a11yProps(0)} />
                    <Tab label="Attention Required" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <TaskTable rows={props.active ? Object.values(props.active) : []} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TaskTable rows={props.attention ? Object.values(props.attention) : []} />
            </TabPanel>
        </Box>
    )
}
