import { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import TrackBox from '../Components/trackingBox'
import TrackTable from '../Components/trackingTable'
import Drawer from '../Components/permanentDrawer'
import { loadUserBookingIDs, useAuth } from '../Util/adminFirebase'
import { useAppContext } from '../Components/store'
import { Grid } from '@mui/material'
import { APP_HEADER_HEIGHT_PX, APP_SIDE_DRAWER_WIDTH_PX } from '../Util/constants'

const ALL_IDX = 0
const TRANSIT_IDX = 1
const PENDING_IDX = 2
const DELAYED_IDX = 3

//TODO import bookingsTable
export default function TrackContainer() {
    const [loading, setLoading] = useState<boolean>(true)
    const [allTrackedShipments, setAllTrackedShipments] = useState<string[]>([])
    const [activeShipments, setActiveShipments] = useState<string[]>([])
    const [delayedShipments, setDelayedShipments] = useState<string[]>([])
    const [pendingShipments, setPendingShipments] = useState<string[]>([])
    const [selectedTab, setSelectedTab] = useState<number>(ALL_IDX)

    const appState = useAppContext()
    useAuth(appState)

    useEffect(() => {
        const loadingUserBookings = async () => {
            setLoading(true)
            let uuid = ''
            if (appState) {
                uuid = appState[0].uuid
            }
            if (uuid) {
                const userBookingData = (await loadUserBookingIDs(uuid)).data()
                setActiveShipments(userBookingData.active_shipments)
                setDelayedShipments(userBookingData.delayed_shipments)
                setPendingShipments(userBookingData.pending_shipments)
                setAllTrackedShipments([
                    ...new Set([
                        ...userBookingData.active_shipments,
                        ...userBookingData.delayed_shipments,
                        ...userBookingData.pending_shipments,
                    ]),
                ])
            }
            setLoading(false)
        }
        loadingUserBookings()
    }, [appState])

    const getSelectedBookings = () => {
        switch (selectedTab) {
            case ALL_IDX:
                return allTrackedShipments
            case TRANSIT_IDX:
                return activeShipments
            case PENDING_IDX:
                return pendingShipments
            case DELAYED_IDX:
                return delayedShipments
            default:
                return []
        }
    }

    const renderTables = () => {
        if (loading) {
            return (
                <Box
                    sx={{
                        width: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        ml: '55vw',
                        mt: '28%',
                    }}
                >
                    <CircularProgress />
                    <Typography sx={{ ml: '-0.6vw', mt: '1%' }}>Loading</Typography>
                </Box>
            )
        } else {
            return (
                <Grid
                    container
                    position="relative"
                    sx={{
                        pl: { sm: `${APP_SIDE_DRAWER_WIDTH_PX - 100}px`, xs: 10 },
                        pt: `${APP_HEADER_HEIGHT_PX}px`,
                        display: 'block',
                        flexBasis: 'auto',
                    }}
                >
                    <Typography
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            color: 'navy',
                            fontSize: '4vh',
                        }}
                    >
                        Tracking
                    </Typography>
                    <Box sx={{ display: 'flex', mt: '2.5%', justifyContent: 'space-evenly' }}>
                        <TrackBox
                            onClick={setSelectedTab}
                            index={ALL_IDX}
                            value={allTrackedShipments.length}
                            name="ALL"
                        />
                        <TrackBox
                            onClick={setSelectedTab}
                            index={TRANSIT_IDX}
                            value={activeShipments.length}
                            name="IN TRANSIT"
                        />
                        <TrackBox
                            onClick={setSelectedTab}
                            index={PENDING_IDX}
                            value={pendingShipments.length}
                            name="PENDING"
                        />
                        <TrackBox
                            onClick={setSelectedTab}
                            index={DELAYED_IDX}
                            value={delayedShipments.length}
                            delayFivePlus={0}
                            name="DELAYED"
                        />
                    </Box>
                    <Divider sx={{ mt: '2.5%', mb: '2.5%' }} />
                    <TrackTable bookings={getSelectedBookings()} />
                </Grid>
            )
        }
    }
    return (
        <Grid container>
            <Drawer />
            {renderTables()}
        </Grid>
    )
}
