import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core'
import { Button, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import { Fragment } from 'react'

const AddButton = ({ onExecute }) => (
    <div style={{ textAlign: 'center' }}>
        <Button color="primary" onClick={onExecute} title="Create new row">
            New
        </Button>
    </div>
)

const EditButton = ({ onExecute }) => (
    <IconButton onClick={onExecute} title="Edit row" size="large">
        <EditIcon />
    </IconButton>
)

const DeleteButton = ({ onExecute }) => (
    <IconButton
        onClick={() => {
            // eslint-disable-next-line
        if (window.confirm('Are you sure you want to delete this row?')) {
                onExecute()
            }
        }}
        title="Delete row"
        size="large"
    >
        <DeleteIcon />
    </IconButton>
)

const CommitButton = ({ onExecute }) => (
    <IconButton onClick={onExecute} title="Save changes" size="large">
        <SaveIcon />
    </IconButton>
)

const CancelButton = ({ onExecute }) => (
    <IconButton color="secondary" onClick={onExecute} title="Cancel changes" size="large">
        <CancelIcon />
    </IconButton>
)

const commandComponents = {
    add: AddButton,
    edit: EditButton,
    delete: DeleteButton,
    commit: CommitButton,
    cancel: CancelButton,
}

export const Command = ({ id, onExecute }) => {
    const CommandButton = commandComponents[id]
    return <CommandButton onExecute={onExecute} />
}

export const EditingPopup = ({ popup: Popup, ...props }) => {
    return (
        <Plugin>
            <Template name="editingPopup">
                <TemplateConnector>
                    {(
                        { rows, getRowId, addedRows, editingRowIds, createRowChange, rowChanges },
                        {
                            changeRow,
                            changeAddedRow,
                            commitChangedRows,
                            commitAddedRows,
                            stopEditRows,
                            cancelAddedRows,
                            cancelChangedRows,
                        },
                    ) => {
                        const isNew = addedRows.length > 0
                        let editedRow
                        let rowId
                        if (isNew) {
                            rowId = 0
                            editedRow = addedRows[rowId]
                        } else if (!editingRowIds.length) {
                            // TODO: find a permanent fix
                            return Fragment
                        } else {
                            ;[rowId] = editingRowIds
                            const targetRow = rows.filter((row) => getRowId(row) === rowId)[0]
                            editedRow = { ...targetRow, ...rowChanges[rowId] }
                        }

                        const processValueChange = ({ target: { name, value } }) => {
                            const changeArgs = {
                                rowId,
                                change: createRowChange(editedRow, value, name),
                            }
                            if (isNew) {
                                changeAddedRow(changeArgs)
                            } else {
                                changeRow(changeArgs)
                            }
                        }
                        const rowIds = isNew ? [0] : editingRowIds

                        const applyChanges = () => {
                            if (isNew) {
                                commitAddedRows({ rowIds })
                            } else {
                                stopEditRows({ rowIds })
                                commitChangedRows({ rowIds })
                            }
                        }

                        const cancelChanges = () => {
                            if (isNew) {
                                cancelAddedRows({ rowIds })
                            } else {
                                stopEditRows({ rowIds })
                                cancelChangedRows({ rowIds })
                            }
                        }

                        const open = editingRowIds.length > 0 || isNew
                        return (
                            <Popup
                                newEntry={isNew}
                                onCancel={cancelChanges}
                                onEdit={processValueChange}
                                onSave={applyChanges}
                                open={open}
                                row={editedRow}
                                {...props}
                            />
                        )
                    }}
                </TemplateConnector>
            </Template>
            <Template name="root">
                <TemplatePlaceholder />
                <TemplatePlaceholder name="editingPopup" />
            </Template>
        </Plugin>
    )
}
