import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '../Components/permanentDrawer'
import { appBarWidth } from '../App'
import { drawerWidth } from '../App'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Checkbox, FormControlLabel, Stack } from '@mui/material'
/*Email
Company Name
NTN
Password
Preferences
 */
interface AccountDetails {
    email: string
    password: string
    name: string
    canEmail: boolean
}

export default function AccountSettings(AccountDetails: AccountDetails) {
    const [emailPref, setEmailPref] = React.useState(AccountDetails.canEmail)
    const [edit, setEdit] = React.useState<boolean>(true)
    const [newEmail, setNewEmail] = React.useState(AccountDetails.email)
    const [newPassword, setNewPassword] = React.useState(AccountDetails.password)
    const [newName, setNewName] = React.useState(AccountDetails.name)

    const handleEdit = () => {
        setEdit(false)
    }

    return (
        <Box sx={{ position: 'relative', display: 'flex', maxWidth: `100vw`, height: '100vh' }}>
            <Drawer />

            <Box
                position="absolute"
                sx={{
                    ml: `${drawerWidth}px`,
                    mt: `${appBarWidth}px`,
                    marginRight: '2.5%',
                    pl: '2.5%',
                    width: '83%',
                    display: 'block',
                    flexBasis: 'auto',
                }}
            >
                <Typography
                    sx={{
                        mt: 2,
                        fontFamily: 'calibri-light',
                        color: 'navy',
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}
                    variant="h4"
                >
                    Account Settings
                </Typography>
                <Box
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    component="form"
                >
                    <TextField
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setNewEmail(event.target.value)
                        }
                        disabled={edit}
                        variant="outlined"
                        sx={{ bgcolor: 'aliceblue', mr: '10%', mt: '2.5%', width: '70%' }}
                        value={newEmail}
                        id="email-input"
                        label="Email"
                        defaultValue={AccountDetails.email}
                    />

                    <TextField
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setNewName(event.target.value)
                        }
                        variant="outlined"
                        sx={{ bgcolor: 'aliceblue', mr: '10%', mt: '2.5%', width: '70%' }}
                        value={newName}
                        id="name-input"
                        label="Name"
                        disabled={edit}
                    />
                    <TextField
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setNewPassword(event.target.value)
                        }
                        variant="outlined"
                        sx={{ bgcolor: 'aliceblue', mr: '10%', mt: '2.5%', width: '70%' }}
                        value={newPassword}
                        id="password-input"
                        label="Password"
                        disabled={edit}
                    />

                    <FormControlLabel
                        label="I would like to receive emails from Transiport for notifications."
                        control={<Checkbox disabled={edit} checked={emailPref} />}
                    />

                    <Stack
                        direction="row"
                        spacing={4}
                        sx={{
                            height: '5%',
                            width: '20%',
                            mt: '2%',
                            ml: '28%',
                            mb: '5%',
                        }}
                    >
                        <Button variant="contained" onClick={handleEdit}>
                            <Typography sx={{ fontSize: 20 }}>Edit</Typography>
                        </Button>

                        <Button
                            variant="contained"

                            //TODO onClick={handleApply}
                        >
                            <Typography sx={{ fontSize: 20 }}>Apply</Typography>
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}
