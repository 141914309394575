import { useState } from 'react'
import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import CustomerBookings from './bookingsTable'
import CustomerFinancials from './customerFinancials'
import {
    adminLoadBookingsFinanceData,
    getUserFinanceData,
    loadBookings,
    loadUserBookingIDs,
} from '../../../Util/adminFirebase'
import { CustomerPopup } from '../adminPopups/customerPopup'
import { GenericTable } from './genericTable'
import {
    CUSTOMER_TABLE_COLUMNS,
    CUSTOMER_TABLE_COLUMN_EXTENSIONS,
    DATA_TYPE_BOOKINGS,
    DATA_TYPE_FINANCE,
} from '../adminConstants'
import { LoadingWheel } from '../../loadingWheel'
import { initializeCustomerData } from '../../../Util/adminUtil'

export default function CustomerTable(props) {
    // is data loading
    const [loadingCustomerData, setCustomerDataLoading] = useState<Map<string, boolean>>(new Map())
    // { uuid: { finance: [ {bk_1_fin}, {bk_n_fin} ], bookings: [ {bk_1_data}, {bk_n_data} ] } }
    const [allCustomersData, setAllCustomersData] = useState<Map<string, Map<string, Array<Object>>>>(new Map())

    const toggleCustomerDataLoading = (id: string, newState: boolean) => {
        // catch undefined values
        const updatedLoadingState = new Map(loadingCustomerData)
        updatedLoadingState[id] = newState
        setCustomerDataLoading(updatedLoadingState)
    }

    // TODO: clean
    const setCustomerData = (customerID: string, data: Array<Object>, isBookings) => {
        const dataType = isBookings ? DATA_TYPE_BOOKINGS : DATA_TYPE_FINANCE
        // catch undefined values
        const updateCustomersData = new Map(allCustomersData)
        updateCustomersData[customerID] = { ...updateCustomersData[customerID], [dataType]: data }
        setAllCustomersData(updateCustomersData)
    }

    // TODO: unsquash data here
    const loadUserBookingData = async (uuid, doc) => {
        console.log('Retrieved booking refs.')
        const allShipments = doc.data()
        const relevantShipments = new Set<string>(
            allShipments['active_shipments']
                .concat(allShipments['pending_shipments'])
                .concat(allShipments['completed_shipments']),
        )
        console.log('Loading bookings...')
        return loadBookings(Array.from(relevantShipments))
            .then((docs) => {
                const bookings = []
                docs.forEach((doc) => {
                    bookings.push(doc.data())
                })
                console.log('Successfully loaded bookings.')
                setCustomerData(uuid, bookings, true)
            })
            .catch((err) => console.log(err))
    }

    const loadUserFinanceData = (uuid, doc) => {
        const promises = []
        console.log('Loaded user finance metadata')
        const allMetadata = doc.data()
        const paymentDueBookings = allMetadata.payments_due ?? {}
        const completedPaymentsBookings = allMetadata.completed_payments ?? {}
        const dueBkIDs = Object.keys(paymentDueBookings)
        const completedBkIDs = Object.keys(completedPaymentsBookings)

        console.log('Loading user finance data...')
        promises.push(adminLoadBookingsFinanceData(dueBkIDs))
        promises.push(adminLoadBookingsFinanceData(completedBkIDs))

        // TODO: precarious, simplify
        // Data's structure is kind of convoluted. Here's a guide:
        // promises = [duePaymentsArrRaw, completedPaymentsArrRaw]
        // duePaymentsArrRaw = [bkDataArr_1, ...]
        // bkDataArr_1 = [userDataRaw, transiportDataRaw]
        // Once we have the data, we combine it as follows:
        // per booking, duePaymentsBk1: userDataRaw.data() + transiportDataRaw
        // per type of booking, dueBks: duePaymentsBk1 + ... + duePaymentsBkn
        // combinedFinanceData = dueBks + completedBks
        // where the final data is of format:
        // [ { bk_id: -, bl_no: -, ...tport_data, ...user_data }, ... ]
        return Promise.all(promises)
            .then((rawDataArr) => {
                const [duePaymentsArrRaw, completedPaymentsArrRaw] = rawDataArr
                // TODO: uncombine here too
                const combinedFinanceData = []
                duePaymentsArrRaw.forEach((bkDataArr, idx) => {
                    const bookingID = dueBkIDs[idx]
                    combinedFinanceData.push({
                        bk_id: bookingID,
                        bl_no: paymentDueBookings[bookingID] ?? '',
                        ...bkDataArr
                            .map((bkPaymentDataRaw) => bkPaymentDataRaw.data())
                            .reduce((obj, item) => ({ ...obj, ...item }), {}),
                    })
                })
                completedPaymentsArrRaw.forEach((bkDataArr, idx) => {
                    const bookingID = completedBkIDs[idx]
                    combinedFinanceData.push({
                        bk_id: bookingID,
                        bl_no: completedPaymentsBookings[bookingID] ?? '',
                        ...bkDataArr
                            .map((bkPaymentDataRaw) => bkPaymentDataRaw.data())
                            .reduce((obj, item) => ({ ...obj, ...item }), {}),
                    })
                })
                setCustomerData(uuid, combinedFinanceData, false)
            })
            .catch((error) => {
                console.log('Error loading customer finance data:', error)
            })
    }

    // Load individual customer data whenever customer row toggled
    const onCellToggle = async (onToggle, uuid, expanded) => {
        // TODO: make finance & bookings load independently
        let promises = []
        const currExpandedState = expanded
        // NB: we expand the row while the data is loading and render a loading wheel
        onToggle()
        if (!currExpandedState && !loadingCustomerData[uuid] && !allCustomersData[uuid]) {
            try {
                toggleCustomerDataLoading(uuid, true)
                // load bookings data for user
                promises.push(loadUserBookingIDs(uuid))
                // load user finance data
                promises.push(getUserFinanceData(uuid))
                const [userBks, userFinanceMetaData] = await Promise.all(promises)
                promises = []
                // send request to load user data
                promises.push(loadUserBookingData(uuid, userBks))
                promises.push(loadUserFinanceData(uuid, userFinanceMetaData))
                await Promise.all(promises)
                toggleCustomerDataLoading(uuid, false)
            } catch (error) {
                console.log('Error loading user data:', error)
            }
        }
    }

    const ToggleCell = ({ expanded, onToggle, row, ...restProps }) => (
        <TableRowDetail.ToggleCell
            {...restProps}
            expanded={expanded}
            row={row}
            onToggle={() => onCellToggle(onToggle, row.uuid, expanded)}
        />
    )

    const renderCustomerBookings = (customerInfo) => {
        const [name, uuid] = customerInfo
        if (loadingCustomerData[uuid]) {
            return <LoadingWheel />
        } else {
            let bookings = allCustomersData[uuid] ? allCustomersData[uuid][DATA_TYPE_BOOKINGS] : []
            bookings = bookings ?? []
            return <CustomerBookings rows={bookings} customers={{ [name]: uuid }} />
        }
    }

    const renderCustomerFinancials = (customerInfo) => {
        const [, uuid] = customerInfo
        if (loadingCustomerData[uuid]) {
            return <LoadingWheel />
        } else {
            const bkToBLMap = {}
            let financials = allCustomersData[uuid] ? allCustomersData[uuid][DATA_TYPE_FINANCE] : []
            financials = financials ?? []
            financials.forEach((data) => (bkToBLMap[data.bk_id] = data.bl_no))
            return <CustomerFinancials customerID={uuid} rows={financials} />
        }
    }

    const getRowId = (row) => row.uuid

    const RowDetail = ({ row }) => {
        // TODO: typecheck, build interface
        const customerInfo = [row.name, row.uuid]
        return (
            <div>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Bookings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{renderCustomerBookings(customerInfo)}</AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Financials</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{renderCustomerFinancials(customerInfo)}</AccordionDetails>
                </Accordion>
            </div>
        )
    }
    return (
        <GenericTable
            columns={CUSTOMER_TABLE_COLUMNS}
            columnExtensions={CUSTOMER_TABLE_COLUMN_EXTENSIONS}
            getRowId={getRowId}
            title={'CUSTOMERS'}
            popup={CustomerPopup}
            rows={props.rows}
            defaultRow={initializeCustomerData()}
            toggleCell={ToggleCell}
            toggleRow={RowDetail}
        />
    )
}
