import { LoadingButton } from '@mui/lab'
import {
    Container,
    Box,
    Typography,
    Grid,
    TextField,
    MenuItem,
    FormControlLabel,
    Checkbox,
} from '@mui/material'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LandingAppBar from '../../Components/landingComponents/landingAppBar'
import { APP_HEADER_HEIGHT_PX } from '../../Util/constants'

export default function AdminLogin() {
    const [loading, setLoading] = useState<boolean>(false)
    const [user, setUser] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const navigate = useNavigate()
    const handleSubmit = () => {
        navigate('/administration')
    }
    return (
        <Container component="main" maxWidth="xs">
            <LandingAppBar />
            <Box
                sx={{
                    paddingTop: `calc(${APP_HEADER_HEIGHT_PX + 10}px)`,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5" color="navy">
                    ADMINISTRATION
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="user-name"
                                name="userName"
                                required
                                fullWidth
                                id="userName"
                                label="User Name"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    setUser(event.target.value)
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    setPassword(event.target.value)
                                }
                            />
                        </Grid>
                    </Grid>
                    <LoadingButton
                        loading={loading}
                        type="submit"
                        fullWidth
                        onClick={() => navigate('/adminLogin')}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        LOGIN
                    </LoadingButton>
                </Box>
            </Box>
        </Container>
    )
}
