interface Context {
    [allowRead: string]: boolean
    [allowWrite: string]: boolean
    [userDataPrefix: string]: string
    [userDataSuffix: string]: string
}

export interface Contexts {
    [contextName: string]: Context
}

export interface CurrentBookingInfoT {
    uuid: string
    bk_id: string
    creation_date: string
    invoice_num: string
    origin: string
    dest: string
    depart_date: string
    arrival_date: string
    shipping_line: string
    vessel_num: string
    vessel_name: string
    is_delayed: boolean
    delayed_by: string
    transit_time: string
    booking_type: string
    is_transshipment: boolean
    stops: string[]
    eta_stops: string[]
    etd_stops: string[]
    documents: string[]
    seller_details: object
    consignee_details: object
    total_price_usd: number
    balance_due: number
    freight_paid: boolean
    payment_date: string
    add_customs: boolean
    customs_price_usd: number
    add_trucking: boolean
    trucking_price_usd: number
    current_stop: string
    is_pending?: boolean
    free_days: number
    add_free_days: boolean
    free_days_price_usd: number
    customs_only: boolean
    cargo_type: string
    num_containers: string
    container_type: string
    incoterm: string
}

export interface generalBreakdownT {
    totalShipments: number
    totalTransshipments: number
    totalBookings: number
}

interface carrierInfoT {
    delayedShipments: number
    numShipments: number
}

export interface allCarriersInfoT {
    COSCO: carrierInfoT
    Evergreen: carrierInfoT
    HMM: carrierInfoT
    Hapag_LLoyd: carrierInfoT
    MSC: carrierInfoT
    Maersk: carrierInfoT
    ONE: carrierInfoT
    OOCL: carrierInfoT
    TS_LINES: carrierInfoT
    CMA_CGM: carrierInfoT
}

export enum months {
    JANUARY = '01',
    FEBRUARY = '02',
    MARCH = '03',
    APRIL = '04',
    MAY = '05',
    JUNE = '06',
    JULY = '07',
    AUGUST = '08',
    SEPTEMBER = '09',
    OCTOBER = '10',
    NOVEMBER = '11',
    DECEMBER = '12',
}

interface monthlyInfoT {
    numShipments: number
    amountPaid: number
}

export interface allMonthInfoT {
    [months.NOVEMBER]: monthlyInfoT
    [months.DECEMBER]: monthlyInfoT
    [months.JANUARY]: monthlyInfoT
    [months.FEBRUARY]: monthlyInfoT
    [months.MARCH]: monthlyInfoT
}

export interface oceanPortT {
    country: string
    name: string
    province: string
    unlocs: string
    type: string
}
