import { LocalizationProvider, DesktopDatePicker, DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {
    Autocomplete,
    Button,
    Divider,
    FormGroup,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import {
    BOOKING_TYPES,
    CONTAINERS,
    INCOTERMS,
    NO,
    PORTS,
    STAGES,
    TRADES,
    YES_NO,
    YES,
} from '../../../Util/constants'
import { useState } from 'react'
import MuiGrid from '@mui/material/Grid'
import * as adminConstants from '../adminConstants'
import { calculateBalanceDue, calculateTotalPrice } from '../../../Util/adminUtil'
import { Upload } from '@mui/icons-material'
//import DynamicForm from './dynamicForm'

// Admin portal on login: pull all customers info, and store it in browser cache
// ^ideas for change testing: pub/sub firestore, store hash and if hash changes redownload customers
// shipment stage should be a n-tuple: (stage, num_days_at_stage,...) (maybe prev, next, etc)
// idea: for each shipment, store only the possible stages associated with it
// active shipment additional things: days at stage, cutoff date, special instructions

/*
    // Form labels and categories
    Form group 1 (Basic booking info): [bk_id, bl_no, customer, agent, bl_upload]
    Form group 2 (Basic booking status): [current_status, is_active, is_delayed, delay_days]
    Form group 3 (Basic booking details): [origin, dest, etd, eta, inco, trade, type, shipping line,
        transshipment, add_trucking, add_customs]
    Form group 4 (Basic product details): [container_type, num_containers, product_details, hs_code]
    Form group 5 (Finance): [customer_price, balance_due, paid_amount, our_cost, total_margin,
        freight_margin, customs_margin, trucking_margin, invoice_upload]
    Form group 6 (Misc): [other_notes, our_notes]
*/

// <Popup formData={} type={booking} onSave={} onCancel={} />
// default props

const groupHeaderStyle = {
    color: 'navy',
    fontFamily: 'calibri',
    fontWeight: 'bold',
    fontSize: 20,
}

export const AmountInput = (props) => {
    // TODO: something cleaner here
    const onChange = (event) => {
        props.onChange({ target: { name: props.name, value: event.target.value } })
    }
    return (
        <TextField
            disabled={props.disabled}
            fullWidth
            InputProps={{
                startAdornment:
                    props.type === 'dollar' ? <InputAdornment position="start">$</InputAdornment> : null,
                endAdornment:
                    props.type === 'percent' ? <InputAdornment position="start">%</InputAdornment> : null,
            }}
            label={props.label}
            onChange={onChange}
            type="number"
            value={props.value}
        />
    )
}

// Displays Yes/No but stores boolean values
export const YesNoMenu = (props) => {
    const mapYesNoToBool = (val) => {
        switch (val) {
            case YES:
                return true
            case NO:
                return false
            default:
                return false
        }
    }
    const onChange = (event) => {
        event.target.value = mapYesNoToBool(event.target.value)
        props.onChange(event)
    }
    return (
        <FormControl sx={{ width: '100%' }}>
            <InputLabel>{props.label}</InputLabel>
            <Select
                label={props.label}
                name={props.name}
                value={props.value ? YES : NO}
                fullWidth
                required
                onChange={onChange}
            >
                {YES_NO.map((option, idx) => (
                    <MenuItem key={idx} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export const SelectForm = (props) => {
    return (
        <FormControl sx={{ width: '100%' }}>
            <InputLabel>{props.label}</InputLabel>
            <Select
                //defaultValue={props.value ? props.value : ''}
                fullWidth
                label={props.label}
                name={props.name}
                onChange={props.onChange}
                required
                value={props.value ? props.value : ''}
            >
                {props.options.map((option, idx) => (
                    <MenuItem key={idx} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export const PortsAutoComplete = (props) => {
    const { label, name, value } = props
    const getLabel = (option) => {
        if (option.unlocs && option.type == 'port') {
            const label = `${option.unlocs} (${option.name}, ${option.country})`
            return label
        }
        return ''
    }
    // TODO: something fishy going on here, look closer
    const onChange = (_, selectedVal) => {
        const loc = selectedVal ? selectedVal.unlocs : null
        props.onChange({ target: { name: name, value: loc } })
    }
    const genInputField = (params) => <TextField {...params} label={label} fullWidth />
    return (
        <Autocomplete
            autoHighlight
            getOptionLabel={getLabel}
            options={PORTS}
            onChange={onChange}
            renderInput={genInputField}
            value={value.unlocs}
        />
    )
}

export const FormGroupHeader = (props) => {
    return (
        <MuiGrid item xs={12}>
            <Divider textAlign="center">
                <Typography variant="subtitle1" sx={groupHeaderStyle} display="block">
                    {props.label}
                </Typography>
            </Divider>
        </MuiGrid>
    )
}

export const TportDatePicker = (props) => {
    const { onChange: parentOnChange, label, name } = props
    const maxDate = new Date(Date.now() + 1000 /*sec*/ * 60 /*min*/ * 60 /*hour*/ * 24 /*day*/ * 30)
    const onChange = (date) => {
        parentOnChange({
            target: { name: name, value: date.toISOString().split('T')[0] },
        })
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
                <DatePicker
                    label={label}
                    maxDate={maxDate}
                    inputFormat="dd/MM/yyyy"
                    onChange={onChange}
                    renderInput={(params) => <TextField {...params} />}
                    value={props.value ? props.value : null}
                />
            </Stack>
        </LocalizationProvider>
    )
}

// props: bk_id, bl_no, customers_list, onChange, agent, bl_info
export const BookingOverview = ({ customers = [], ...props }) => {
    const [selectedCustomer, setSelectedCustomer] = useState('')
    // NB: We are displaying customer name in the select form
    // but we are storing customer uuid in the row/form data
    const onCustomerSelect = (event) => {
        const customerName = event.target.value
        setSelectedCustomer(customerName)
        event.target.value = customers[customerName]
        props.onChange(event)
    }
    return (
        <MuiGrid container spacing={3}>
            <FormGroupHeader label={'Booking Overview'} />
            <MuiGrid item xs={6}>
                <TextField
                    fullWidth
                    name={adminConstants.BOOKING_ID}
                    label="Booking #"
                    value={props.row[adminConstants.BOOKING_ID]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <TextField
                    fullWidth
                    name={adminConstants.BL_NO}
                    label="B/L #"
                    value={props.row[adminConstants.BL_NO]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <TextField
                    fullWidth
                    name={adminConstants.AGENT}
                    label="Agent"
                    value={props.row[adminConstants.AGENT]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <SelectForm
                    label={'Customers'}
                    name={adminConstants.CUSTOMER_ID}
                    value={selectedCustomer}
                    onChange={onCustomerSelect}
                    options={Object.keys(customers)}
                />
            </MuiGrid>
            <MuiGrid item xs={12}>
                <Button fullWidth variant="contained" component="label">
                    Upload B/L
                    <input
                        type="file"
                        name={adminConstants.BL_FILE}
                        onChange={(e) => props.setBLFile(e.target.files[0])}
                        hidden
                    />
                </Button>
            </MuiGrid>
        </MuiGrid>
    )
}

// props: stages, current_stage, is_active, is_delayed, delay_days, onChange
export const BookingStatus = ({ stages = Array.from(STAGES.keys()), ...props }) => {
    return (
        <MuiGrid container spacing={3}>
            <FormGroupHeader label={'Booking Status'} />
            <MuiGrid item xs={6}>
                <SelectForm
                    label={'Shipment Stage'}
                    name={adminConstants.CURRENT_STATUS}
                    onChange={props.onChange}
                    options={stages}
                    value={props.row[adminConstants.CURRENT_STATUS]}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <YesNoMenu
                    label={'Active'}
                    name={adminConstants.IS_ACTIVE}
                    value={props.row[adminConstants.IS_ACTIVE]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <YesNoMenu
                    label={'Delayed'}
                    name={adminConstants.IS_DELAYED}
                    value={props.row[adminConstants.IS_DELAYED]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <TextField
                    fullWidth
                    name={adminConstants.DELAYED_BY}
                    label="Delay Days"
                    value={props.row[adminConstants.DELAYED_BY]}
                    onChange={props.onChange}
                />
            </MuiGrid>
        </MuiGrid>
    )
}

// props: onChange, origin, dest, etd, eta, trucking, customs, inco
//        trade, shipping line, transshipment, booking type
export const BookingDetails = (props) => {
    return (
        <MuiGrid container spacing={3}>
            <FormGroupHeader label={'Booking Details'} />

            <MuiGrid item xs={6}>
                <PortsAutoComplete
                    value={props.row[adminConstants.ORIGIN]}
                    onChange={props.onChange}
                    label="Origin"
                    name={adminConstants.ORIGIN}
                />
            </MuiGrid>

            <MuiGrid item xs={6}>
                <PortsAutoComplete
                    value={props.row[adminConstants.DESTINATION]}
                    onChange={props.onChange}
                    label="Destination"
                    name={adminConstants.DESTINATION}
                />
            </MuiGrid>

            <MuiGrid item xs={6}>
                <TportDatePicker
                    name={adminConstants.ETD}
                    label={'ETD'}
                    value={props.row[adminConstants.ETD]}
                    onChange={props.onChange}
                />
            </MuiGrid>

            <MuiGrid item xs={6}>
                <TportDatePicker
                    name={adminConstants.ETA}
                    label={'ETA'}
                    value={props.row[adminConstants.ETA]}
                    onChange={props.onChange}
                />
            </MuiGrid>

            <MuiGrid item xs={6}>
                <SelectForm
                    label={'Incoterm'}
                    name={adminConstants.INCOTERM}
                    onChange={props.onChange}
                    options={INCOTERMS}
                    value={props.row[adminConstants.INCOTERM]}
                />
            </MuiGrid>

            <MuiGrid item xs={6}>
                <SelectForm
                    label={'Trade'}
                    name={adminConstants.TRADE}
                    onChange={props.onChange}
                    options={TRADES}
                    value={props.row[adminConstants.TRADE]}
                />
            </MuiGrid>

            <MuiGrid item xs={6}>
                <SelectForm
                    label={'Booking Type'}
                    name={adminConstants.BOOKING_TYPE}
                    onChange={props.onChange}
                    options={BOOKING_TYPES}
                    value={props.row[adminConstants.BOOKING_TYPE]}
                />
            </MuiGrid>

            <MuiGrid item xs={6}>
                <TextField
                    fullWidth
                    name={adminConstants.LINE}
                    label="Shipping Line"
                    value={props.row[adminConstants.LINE]}
                    onChange={props.onChange}
                />
            </MuiGrid>

            <MuiGrid item xs={4}>
                <YesNoMenu
                    label={'Trans-shipment'}
                    name={adminConstants.IS_TRANSSHIPMENT}
                    value={props.row[adminConstants.IS_TRANSSHIPMENT]}
                    onChange={props.onChange}
                />
            </MuiGrid>

            <MuiGrid item xs={4}>
                <YesNoMenu
                    label={'Customs'}
                    name={adminConstants.ADD_CUSTOMS}
                    value={props.row[adminConstants.ADD_CUSTOMS]}
                    onChange={props.onChange}
                />
            </MuiGrid>

            <MuiGrid item xs={4}>
                <YesNoMenu
                    label={'Trucking'}
                    name={adminConstants.ADD_TRUCKING}
                    value={props.row[adminConstants.ADD_TRUCKING]}
                    onChange={props.onChange}
                />
            </MuiGrid>
        </MuiGrid>
    )
}

// props: onChange, container_type, num_containers, product_details, hs_code
export const ProductDetails = (props) => {
    return (
        <MuiGrid container spacing={3}>
            <FormGroupHeader label={'Container Details'} />

            <MuiGrid item xs={6}>
                <SelectForm
                    label={'Container Type'}
                    name={adminConstants.CONTAINER_TYPE}
                    value={props.row[adminConstants.CONTAINER_TYPE]}
                    onChange={props.onChange}
                    options={CONTAINERS}
                />
            </MuiGrid>

            <MuiGrid item xs={6}>
                <TextField
                    fullWidth
                    name={adminConstants.CONTAINERS_QUANTITY}
                    label="# Containers"
                    value={props.row[adminConstants.CONTAINERS_QUANTITY]}
                    onChange={props.onChange}
                />
            </MuiGrid>

            <MuiGrid item xs={6}>
                <TextField
                    fullWidth
                    name={adminConstants.HS_CODE}
                    label="HS Code"
                    value={props.row[adminConstants.HS_CODE]}
                    onChange={props.onChange}
                />
            </MuiGrid>

            <MuiGrid item xs={6}>
                <TextField
                    fullWidth
                    name={adminConstants.PROD_DESC}
                    label="Product Description"
                    value={props.row[adminConstants.PROD_DESC]}
                    onChange={props.onChange}
                />
            </MuiGrid>
        </MuiGrid>
    )
}

// props: onChange, customer_price, balance_due, paid_amount, our_cost, total_margin,
//        freight_margin, customs_margin, trucking_margin, invoice_upload

export const BookingFinance = (props) => {
    return (
        <MuiGrid container spacing={3}>
            <FormGroupHeader label={'Booking Financials'} />
            <MuiGrid item xs={6}>
                <TextField
                    fullWidth
                    name={adminConstants.INVOICE_ID}
                    label="Invoice No."
                    value={props.row[adminConstants.INVOICE_ID]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <AmountInput
                    disabled={true}
                    name={adminConstants.TOTAL_PRICE_USD}
                    label="Total Amount"
                    value={calculateTotalPrice(props.row)}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <AmountInput
                    name={adminConstants.PAID_AMOUNT}
                    label="Amount Paid"
                    value={props.row[adminConstants.PAID_AMOUNT]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <AmountInput
                    disabled={true}
                    name={adminConstants.BALANCE_DUE}
                    label="Balance Due"
                    value={calculateBalanceDue(props.row)}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <AmountInput
                    fullWidth
                    name={adminConstants.FREIGHT_COST}
                    label="Freight Cost"
                    value={props.row[adminConstants.FREIGHT_COST]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <AmountInput
                    fullWidth
                    name={adminConstants.CUSTOMS_COST}
                    label="Customs Cost"
                    value={props.row[adminConstants.CUSTOMS_COST]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <AmountInput
                    fullWidth
                    name={adminConstants.TRUCKING_COST}
                    label="Trucking Cost"
                    value={props.row[adminConstants.TRUCKING_COST]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <AmountInput
                    fullWidth
                    name={adminConstants.TOTAL_MARGIN}
                    label="Total Margin"
                    value={props.row[adminConstants.TOTAL_MARGIN]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <AmountInput
                    fullWidth
                    name={adminConstants.FREIGHT_MARGIN}
                    label="Freight Margin"
                    value={props.row[adminConstants.FREIGHT_MARGIN]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <AmountInput
                    fullWidth
                    name={adminConstants.CUSTOMS_MARGIN}
                    label="Customs Margin"
                    value={props.row[adminConstants.CUSTOMS_MARGIN]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <AmountInput
                    fullWidth
                    name={adminConstants.TRUCKING_MARGIN}
                    label="Trucking Margin"
                    value={props.row[adminConstants.TRUCKING_MARGIN]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <AmountInput
                    disabled={true}
                    fullWidth
                    name={adminConstants.TOTAL_MISC_CHARGES}
                    label="Total Misc Charges"
                    value={
                        props.row[adminConstants.TOTAL_MISC_CHARGES_COST] +
                        props.row[adminConstants.TOTAL_MISC_CHARGES_MARGIN]
                    }
                    onChange={props.onChange}
                />
                <TextField
                    fullWidth
                    name={adminConstants.MISC_CHARGES_BREAKDOWN}
                    label="Misc Charges Breakdown (Customer facing)"
                    value={props.row[adminConstants.MISC_CHARGES_BREAKDOWN]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <AmountInput
                    fullWidth
                    name={adminConstants.TOTAL_MISC_CHARGES_COST}
                    label="Misc Charges Cost"
                    value={props.row[adminConstants.TOTAL_MISC_CHARGES_COST]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={6}>
                <AmountInput
                    fullWidth
                    name={adminConstants.TOTAL_MISC_CHARGES_MARGIN}
                    label="Misc Margin Total"
                    value={props.row[adminConstants.TOTAL_MISC_CHARGES_MARGIN]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={12}>
                <TextField
                    fullWidth
                    name={adminConstants.MISC_CHARGES_COST_MARGIN_BREAKDOWN}
                    label="Misc Charges Breakdown (item_desc, cost, margin) (Transiport private)"
                    value={props.row[adminConstants.MISC_CHARGES_COST_MARGIN_BREAKDOWN]}
                    onChange={props.onChange}
                />
            </MuiGrid>
            <MuiGrid item xs={12}>
                <Button fullWidth variant="contained" component="label">
                    Upload Invoice
                    <input
                        type="file"
                        name={adminConstants.INVOICE_FILE}
                        onChange={(e) => props.setInvoiceFile(e.target.files[0])}
                        hidden
                    />
                </Button>
            </MuiGrid>
        </MuiGrid>
    )
}

// props: onChange, booking_notes
export const BookingMisc = (props) => {
    return (
        <FormGroup>
            <TextField
                margin="normal"
                name={adminConstants.NOTES}
                label="Notes"
                multiline
                minRows={2}
                maxRows={4}
                value={props.row[adminConstants.NOTES]}
                onChange={props.onChange}
            />
        </FormGroup>
    )
}
