import TruckRatesForm from '../adminForms/truckRatesForm'
import { GenericPopup } from './genericPopup'

export const TruckRatesPopup = (props) => {
    return (
        <GenericPopup
            title={'Truck Rates'}
            open={props.open}
            onSave={props.onChange}
            onCancel={props.onCancel}
        >
            <TruckRatesForm row={props.row} onChange={props.onEdit} />
        </GenericPopup>
    )
}
