// Import the functions you need from the SDKs you need
import { getApp, initializeApp } from 'firebase/app'
import {
    getDownloadURL,
    getMetadata,
    getStorage,
    list as listFiles,
    ref,
    uploadBytes,
} from 'firebase/storage'
import {
    arrayRemove,
    arrayUnion,
    collection,
    deleteField,
    doc,
    getFirestore,
    getDoc,
    getDocs,
    setDoc,
    updateDoc,
    writeBatch,
} from 'firebase/firestore'
import {
    createUserWithEmailAndPassword,
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
} from 'firebase/auth'
import { useAppContext, UserBasicInfoStateObjT } from '../Components/store'
import * as adminUtil from './adminUtil'
import * as adminConstants from '../Components/adminComponents/adminConstants'
import { useEffect } from 'react'

// TODO: properly split file into: common, admin, user
export const ADMIN_UUID = 'I4XIhd4SDtgOLgvLH22zdGAqyGF3'

export const FIREBASE_PROD = 'firebase_prod'
export const FIREBASE_DEV = 'firebase_dev'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseProdConfig = {
    apiKey: 'AIzaSyB5nod5gW9kpi4RLdMceHveEdwzAcr6ltg',
    authDomain: 'transiport.firebaseapp.com',
    projectId: 'transiport',
    storageBucket: 'transiport.appspot.com',
    messagingSenderId: '871647840957',
    appId: '1:871647840957:web:ea92e4ee1dfc614b1545d4',
    measurementId: 'G-1V4410TQ86',
}

const firebaseDevConfig = {
    apiKey: 'AIzaSyAYDHW6cOFf-1kvL0t67Bk_pv3hFx-UGdM',
    authDomain: 'transiport-dev.firebaseapp.com',
    projectId: 'transiport-dev',
    storageBucket: 'transiport-dev.appspot.com',
    messagingSenderId: '390840322382',
    appId: '1:390840322382:web:f581642d0d3d4d0b80361f',
}

let app
let auth
let database
let storage

// Toggle between prod and dev envs
// NB: since app already initialized, we need to tearndown/replace hence
// using app(...) instead of initializeApp
export const toggleFirebaseConfig = (mode) => {
    switch (mode) {
        case FIREBASE_PROD:
            try {
                app = getApp(FIREBASE_PROD)
            } catch (err) {
                console.log('firebase prod app not initialized, creating instance...')
                app = initializeApp(firebaseProdConfig, FIREBASE_PROD)
            }
            break
        case FIREBASE_DEV:
            try {
                app = getApp(FIREBASE_DEV)
            } catch (err) {
                console.log('firebase dev app not initialized, creating instance...')
                app = initializeApp(firebaseDevConfig, FIREBASE_DEV)
            }
            break
        default:
            console.log(`Unknown config mode specified: ${mode}`)
            return
    }
    console.log(`Toggled to firebase config: ${mode}`)
    // analytics = getAnalytics(app)
    auth = getAuth(app)
    database = getFirestore(app)
    storage = getStorage(app)
}

// Initialize Firebase in prod mode (default)
// TODO: since we are only using the for admin and customers rn, this is fine
// but when we get more developers on board, should have more robust isolation
// between prod and dev envs
console.log('Initializing firebase prod app...')
toggleFirebaseConfig(FIREBASE_PROD)

// TODO: fix up
export const useAuth = (context: UserBasicInfoStateObjT) => {
    useEffect(() => {
        console.log('User state changed')
        // don't need to read old state value
        const [oldAppState, setAppState] = context
        const unsub = onAuthStateChanged(auth, async (user) => {
            // NB: order matters here
            if (user && user.uid != oldAppState.uuid) {
                setAppState({ ...oldAppState, uuid: user.uid, loading: false })
            }
        })
        return unsub
    }, [context])
}

export const useAdminAuth = (context: UserBasicInfoStateObjT) => {
    //console.log(context)
}

// export const signupPartner = (email: string, password: string) => {
//     return createUserWithEmailAndPassword(auth, email, password)
// }

export const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
}

export const signupCustomer = (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password)
}

export const signout = () => {
    return signOut(auth)
}

export const getCurrentUser = () => {
    return auth.currentUser
}

// TODO: we're loading all the data, create CF to use list() REST API
export const loadCustomers = async () => {
    const snapshot = await getDocs(collection(database, 'users'))
    return snapshot.docs
}

export const loadUserData = (uuid: string) => {
    const collectionPath = 'users'
    const docRef = doc(database, collectionPath, uuid)
    return getDoc(docRef)
}

export const loadUserBookingIDs = (uuid: string) => {
    const collectionPath = `users/${uuid}/detailedData`
    const docPath = 'bookings'
    const docRef = doc(database, collectionPath, docPath)
    return getDoc(docRef)
}

export const getUserFinanceData = (uuid: string) => {
    const collectionPath = `users/${uuid}/detailedData`
    const docPath = 'finance'
    const docRef = doc(database, collectionPath, docPath)
    return getDoc(docRef)
}

export const getUserAnalyticsData = (uuid: string) => {
    const collectionPath = `users/${uuid}/detailedData`
    const docPath = 'analytics'
    const docRef = doc(database, collectionPath, docPath)
    return getDoc(docRef)
}

export const loadBookings = (bookings: string[]) => {
    const promises = []
    bookings.forEach((booking) => {
        if (!booking) {
            return
        }
        const collectionPath = 'bookings'
        const docRef = doc(database, collectionPath, booking)
        promises.push(getDoc(docRef))
    })
    return Promise.all(promises)
}

export const getActiveBookings = () => {
    const collectionPath = 'bookings'
    const docRef = doc(database, collectionPath, 'active')
    return getDoc(docRef)
}

// TODO: add pagination
export const getAllBookings = async () => {
    const snapshot = await getDocs(collection(database, 'bookings'))
    return snapshot.docs
}

export const getAttentionRequiredBookings = () => {
    const collectionPath = 'bookings'
    const docRef = doc(database, collectionPath, 'require_attention')
    return getDoc(docRef)
}

// TODO: refactor common elements of new and update bookings
// TODO: only make updates to fields that have been updated in general but esp. here
/*
    When we create or updating an admin booking, we need to batch multiple document
    updates/writes:
        1. create a new booking document in /bookings
        2. update booking metadata info:
            /bookings/{requires_attention, etc}
        3. create booking finance information in:
            /bookings/{bookingID}/finance
        4. update customer information:
            /customers/{customerID}/detailedData/bookings
            /customers/{customerID}/detailedData/finance
*/
export const adminCreateOrUpdateBooking = (bookingNum: string, data: adminUtil.formattedBookingDataT) => {
    // TODO: validation here? Definitely need more than this though.
    if (!data.bookingInfo.uuid) {
        return Promise.reject('Please supply a customer for the booking.')
    }
    // Get a new write batch
    const batch = writeBatch(database)

    // update booking info
    let collectionPath = 'bookings'
    let docRef = doc(database, collectionPath, bookingNum)
    batch.set(docRef, data.bookingInfo, { merge: true })

    // update booking finance data
    collectionPath = `bookings/${bookingNum}/finance/`
    // finance user data
    docRef = doc(database, collectionPath, 'user_data')
    batch.set(docRef, data.bookingUserFinance, { merge: true })
    // finance transiport data
    docRef = doc(database, collectionPath, 'transiport_data')
    batch.set(docRef, data.bookingTransiportFinance, { merge: true })

    // update user booking finance data
    collectionPath = `users/${data.bookingInfo.uuid}/detailedData`
    docRef = doc(database, collectionPath, 'finance')
    const totalPrice: number = adminUtil.calculateTotalPrice(data.bookingUserFinance)
    const balanceDue: number = adminUtil.calculateBalanceDue(data.bookingUserFinance)
    if (balanceDue) {
        batch.set(docRef, { due_payments: { [bookingNum]: balanceDue } }, { merge: true })
    } else {
        batch.set(docRef, { completed_payments: { [bookingNum]: totalPrice } }, { merge: true })
    }

    // update bookings cache/metadata
    if (!data.bookingInfo.uuid || !data.bookingInfo.agent) {
        docRef = doc(database, 'bookings', 'require_attention')
        batch.set(docRef, { [bookingNum]: data.bookingInfo.uuid }, { merge: true })
    }

    const currentStatus = data.bookingInfo.current_status
    if (adminUtil.isShipmentPending(currentStatus)) {
        // pending shipments
        docRef = doc(database, 'bookings', 'pending')
        batch.set(docRef, { [bookingNum]: data.bookingInfo.uuid }, { merge: true })
        // update user data record
        collectionPath = `users/${data.bookingInfo.uuid}/detailedData`
        docRef = doc(database, collectionPath, 'bookings')
        batch.set(
            docRef,
            {
                pending_shipments: arrayUnion(bookingNum),
            },
            { merge: true },
        )
    } else if (adminUtil.isShipmentCompleted(currentStatus)) {
        // completed shipments
        docRef = doc(database, 'bookings', 'active')
        batch.set(docRef, { [bookingNum]: deleteField() }, { merge: true })
        docRef = doc(database, 'bookings', 'completed')
        batch.set(docRef, { [bookingNum]: data.bookingInfo.uuid }, { merge: true })
        // update user data record
        collectionPath = `users/${data.bookingInfo.uuid}/detailedData`
        docRef = doc(database, collectionPath, 'bookings')
        batch.set(
            docRef,
            {
                pending_shipments: arrayRemove(bookingNum),
                active_shipments: arrayRemove(bookingNum),
                completed_shipments: arrayUnion(bookingNum),
            },
            { merge: true },
        )
    } else {
        // active shipments
        docRef = doc(database, 'bookings', 'pending')
        batch.set(docRef, { [bookingNum]: deleteField() }, { merge: true })
        docRef = doc(database, 'bookings', 'active')
        batch.set(docRef, { [bookingNum]: data.bookingInfo.uuid }, { merge: true })
        collectionPath = `users/${data.bookingInfo.uuid}/detailedData`
        docRef = doc(database, collectionPath, 'bookings')
        batch.set(
            docRef,
            {
                pending_shipments: arrayRemove(bookingNum),
                active_shipments: arrayUnion(bookingNum),
            },
            { merge: true },
        )
    }
    return batch.commit()
}

export const updateCustomerInfo = (
    uuid: string,
    publicData: adminUtil.customerBasicProfileT,
    transiportData: adminUtil.customerTransiportDataT,
) => {
    const batch = writeBatch(database)
    // update user profile data
    let docRef = doc(database, 'users', uuid)
    batch.set(docRef, publicData, { merge: true })
    // update transiport data
    docRef = doc(database, `users/${uuid}/detailedData`, 'transiport_data')
    batch.set(docRef, transiportData, { merge: true })
    return batch.commit()
}

export const setupNewCustomerProfile = (uuid: string, customerProfile: adminUtil.customerProfileT) => {
    if (uuid) {
        const batch = writeBatch(database)
        // create up user data documents
        const collectionPath = `users/${uuid}/detailedData`
        // bookings
        let docRef = doc(database, collectionPath, 'bookings')
        batch.set(docRef, customerProfile.bookingsData)
        // finance
        docRef = doc(database, collectionPath, 'finance')
        batch.set(docRef, customerProfile.financeData)
        // analytics
        docRef = doc(database, collectionPath, 'analytics')
        batch.set(docRef, customerProfile.analyticsData)
        // transiport private data
        docRef = doc(database, collectionPath, 'transiport_data')
        batch.set(docRef, customerProfile.transiportData)
        return batch.commit()
    } else {
        return Promise.reject('No uuid given.')
    }
}

export const loadTransiportUserData = (uuid) => {
    // update transiport data
    const docRef = doc(database, `users/${uuid}/detailedData`, 'transiport_data')
    return getDoc(docRef)
}

export const adminLoadBookingsFinanceData = (bookings) => {
    const promises = []
    bookings.forEach((bookingID) => {
        const childPromises = []
        const collectionPath = `bookings/${bookingID}/finance/`
        // public data
        let docPath = 'user_data'
        let docRef = doc(database, collectionPath, docPath)
        childPromises.push(getDoc(docRef))
        // transiport data
        docPath = 'transiport_data'
        docRef = doc(database, collectionPath, docPath)
        childPromises.push(getDoc(docRef))
        promises.push(Promise.all(childPromises))
    })
    return Promise.all(promises)
}

export const adminSaveBookingFinanceData = (bookingID, userData, transiportData) => {
    const promises = []
    const collectionPath = `bookings/${bookingID}/finance/`
    // public data
    let docPath = 'user_data'
    let docRef = doc(database, collectionPath, docPath)
    promises.push(setDoc(docRef, userData, { merge: true }))
    // transiport data
    docPath = 'transiport_data'
    docRef = doc(database, collectionPath, docPath)
    promises.push(setDoc(docRef, transiportData, { merge: true }))
    return Promise.all(promises)
}

////////////////////CLEAN////////////////////////
export const getUserDocMetaData = (
    uuid: string,
    docName: string,
    bookingNum: string | undefined = undefined,
) => {
    const docLoc = `${uuid}/${bookingNum ? bookingNum + '/' : ''}${docName}`
    const docRef = ref(storage, docLoc)
    return getMetadata(docRef)
}

export const uploadBookingDocument = (
    uuid: string,
    bookingNum: string,
    docType: string,
    file: Blob | Uint8Array | ArrayBuffer,
) => {
    const docLoc = `${uuid}/${bookingNum + '/'}${docType}`
    const docRef = ref(storage, docLoc)
    return uploadBytes(docRef, file)
}

export const listAllDocsUserBookings = (uuid: string, bookings: string[]) => {
    const promises = []
    bookings.forEach((booking) => {
        const docLoc = `${uuid}/${booking}`
        const docRef = ref(storage, docLoc)
        promises.push(listFiles(docRef))
    })
    return Promise.all(promises)
}

export const getMetadataForDocs = (docs: string[]) => {
    const promises = []
    docs.forEach((doc) => {
        const docRef = ref(storage, doc)
        promises.push(getMetadata(docRef))
    })
    return Promise.all(promises)
}

export const getDocDownloadURL = (docPath: string) => {
    return getDownloadURL(ref(storage, docPath))
}

// TODO: needs to be fleshed out more (DEMO version right now)
export const searchVoyages = (origin: string) => {
    const collectionPath = 'voyages'
    const docPath = origin
    const docRef = doc(database, collectionPath, docPath)
    return getDoc(docRef)
}

export const createNewBooking = (uuid: string, bookingNum: string, data) => {
    const promises = []
    // creating new booking
    let collectionPath = 'bookings'
    let docRef = doc(database, collectionPath, bookingNum)
    promises.push(setDoc(docRef, data))
    // update user data record
    collectionPath = `users/${uuid}/detailedData`
    const docPath = 'bookings'
    docRef = doc(database, collectionPath, docPath)
    promises.push(
        updateDoc(docRef, {
            pending_shipments: arrayUnion(bookingNum),
        }),
    )
    return Promise.all(promises)
}
////////////////////////////////////////////////

export const loadAllPartners = async () => {
    const snapshot = await getDocs(collection(database, 'partners'))
    return snapshot.docs
}

// data: {...port_pair -> product_type -> rate}
export const createRateSheet = async (partnerID, rateSheetID, file) => {
    // parse rate sheet
    const rates = await adminUtil.parseRateSheet(file)
    const promises = []
    const voyageIDs = []
    let docRef
    if (rates) {
        rates.forEach(rate => {
            // repr as a voyages
            const voyageID = adminUtil.genVoyageID(partnerID, rateSheetID, rate)
            const voyageData = adminUtil.parseVoyageFromRate(rate)
            voyageIDs.push(voyageID)
            // store in voyages under: voyages->id
            docRef = doc(database, 'voyages', voyageID)
            promises.push(setDoc(docRef, voyageData))
            // store in voyages under: voyages->port_pair->etd->product->id
            const sanitizedContainer = rate.container.toLowerCase().replaceAll(' ', '_')
            docRef = doc(
                database,
                `voyages/${rate.pol}_${rate.pod}/${rate.etd.replaceAll(/\/|\s/g, '_')}/${sanitizedContainer}`,
            )
            promises.push(setDoc(docRef, { [voyageID]: new Date() }, { merge: true }))
        })
    }
    // store ref in partners under: partners->partner_id->rate_sheets->rate_sheet_id->active[id]
    docRef = doc(database, `partners/${partnerID}/rate_sheets/${rateSheetID}`)
    promises.push(setDoc(docRef, { active: arrayUnion(...voyageIDs) }, { merge: true }))
    // store ref in partners under: partners->partner_id->rate_sheets->rate_sheet_id->active[id]
    docRef = doc(database, `partners/${partnerID}`)
    promises.push(setDoc(docRef, { active_sheets: arrayUnion(rateSheetID) }, { merge: true }))
    // TODO: repr in partners (think about reducing structural overlap with voyages)
    // TODO: repr in rates (and simplify voyages)
    // TODO: extrapolate non-freight fees for rateSheet rates
    return Promise.all(promises)
}

export const uploadRateSheet = (partnerID, rateSheetID, rateSheet) => {
    const docLoc = `${partnerID}/${rateSheetID}`
    const docRef = ref(storage, docLoc)
    return uploadBytes(docRef, rateSheet)
}

export const getRateSheetURLs = (partnerID, rateSheets) => {
    const promises = []
    let docPath
    rateSheets.forEach((rateSheetID) => {
        docPath = `${partnerID}/${rateSheetID}`
        promises.push(getDownloadURL(ref(storage, docPath)))
    })
    return Promise.all(promises)
}

// Also creates a new one if doesn't exist
// TODO: for now we are using name as ID but should change
export const updatePartner = (data) => {
    const partnerBasicProfile = {
        name: data.partner_name,
        country: data.country,
        active_sheets: arrayUnion(...[]),
    }
    const docRef = doc(database, `partners/${data.partner_name}`)
    return setDoc(docRef, partnerBasicProfile, { merge: true })
}
